import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { DatePipe } from "@angular/common";
import { ResponseDto } from 'src/app/dto/Response-dto';
const my = new Date();
import { ElementRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-lr-rate-calculator',
    templateUrl: './lr-rate-calculator.component.html',
    styleUrls: ['./lr-rate-calculator.component.css']
})
export class LrRateCalculatorComponent implements OnInit {

    destinationDataList: LRDto[];
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    controlSource = new FormControl();
    controlDestination = new FormControl();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    selectedFromStation: any;
    selectedToStation: any;
    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                : this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;

    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                : this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;
    pageId = "lrRtCal";

    // source
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { source: string }) => x.source;

    /* For Destination Drop Down */
    destinationOptions: LRDto[];
    lrDtoToStation: LRDto = new LRDto();
    destinationDtoForAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { mainstation: string }) => x.mainstation;

    /* Commodity Drop Down */
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    enteredSourceForSave: any;
    partyMasterDtoForConsignor: PartyMasterDto = new PartyMasterDto();
    enteredConsignorGstNo: any;
    showSpinnerForAction: boolean = false;
    consignorMasterDataList: any;
    consignorDetails: any;
    partyMasterDtoForConsignee: PartyMasterDto = new PartyMasterDto();
    enteredConsigneeGstNo: any;
    consigneeDetailsDataList: any;
    consigneeDetails: any;
    viewPartyFields: boolean = false;
    rateMasterDtoConsignor: RateMasterDto = new RateMasterDto();
    selectedPartyCommodity: any;
    enteredSource: any;
    selectedDestination: any;
    rateMasterDtoConsignorResp: any;
    consignorRateId = 0;
    consignorIdRateId = 0;
    consigneeIdRateId = 0;
    consignorRateSrc: any;
    consignorRateFor: any;
    consignorRateConsignor: any;
    consignorRateDest: any;
    consignorRateCommodity: any;
    consignorRateType: any;
    consignorRatePerUnit: any;
    consignorRatePerUnitRateOnActWgt: any;
    consignorRateBasicRate: any;
    consignorRateHamali: any;
    consignorRateGcChg: any;
    consignorRateAoc: any;
    consignorRateOthers: any;
    consignorRateDDChg: any;
    consignorRateConsignee: any;
    consignorRateWEF: any;
    consignorId = 0;
    consigneeId = 0;
    enteredBookingDate: any;
    consignorRateFlowSet: boolean = false;
    consigneeRateDtlsRet: any;
    consigneeRateSrc: any;
    consigneeRateConsignee: any;
    consigneeRateDest: any;
    consigneeRateCommodity: any;
    consigneeRateUnit: any;
    consigneeRatePerUnit: any;
    consigneeRateActWgt: any;
    consigneeRateBasicChg: any;
    consigneeRateHamali: any;
    consigneeRateGcChg: any;
    consigneeRateAoc: any;
    consigneeRateOthers: any;
    consigneeRateDDChg: any;
    consigneeRateRiskChg: any;
    consigneeRateFlowSet: boolean = false;
    isConsigneeRateBkg: boolean = false;
    consigneeRateEffectiveToDate: any;
    consigneeRateConsignor: any;
    consigneeRateColumn2: any;
    consigneeRateFov: any;
    consigneeUnit: any;
    rateDtoForConsigneeRateDetails: RateMasterDto = new RateMasterDto();
    consignorRateFlow: boolean = false;
    tdyBookingDate: any;
    enteredActualWeight: any;
    enteredChargeWeight: any;
    totArticles: any;
    destMemoSizeExceedRights: boolean = false;
    commodityKglimit: any;
    isSizeCommodityExceed: boolean = false;
    enteredChargedWeight: any;
    rateMasterDtoGet: RateMasterDto = new RateMasterDto();
    selectedAgentName: any;
    commodityKgLimitData: LRDto = new LRDto();
    baseFreight: any;
    partyBaseFreight: any;
    partyHamali: any;
    partyGcCharge: any;
    partyAocCharge: any;
    partyOtherCharge: any;
    consignorRateFov: any;
    hamali: any;
    gcCharge: any;
    lcCharge: any;
    bcCharge: any;
    aocCharge: any;
    totalAmt = 0.0;
    others: any;
    ddAmt: any;
    lrDtoPerKgGet: LRDto = new LRDto();
    lrtDtoPerKgRateReturn: LRDto = new LRDto();
    isCommodityBlockedBkg: Boolean = false;
    dtoBlockedcmdty: RateMasterDto = new RateMasterDto();
    dtoBlockedCmdtyRtn: ResponseDto = new ResponseDto();
    isPerKgLimitTaken: boolean = false;
    partyFrieghtDetails: boolean = false;
    maxActWgt: any;
    @ViewChild("chargedWeight") chargedWeightField: ElementRef;
    maxChgWgt: any;
    multiArtBlurEvent: boolean = true;
    totalAmount: number = 0.0;
    @ViewChild("actualWeight") actualWeightField: ElementRef;

    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef,
        private consignorService: ConsignorService, private datePipe: DatePipe) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.consignorRateFlow = this.userDataDtoReturnSession.isConsignorRateFlow;
            this.getBranchList();
            this.getAgentDetailList();
            this.getCommodityMasterDetailsList();
            this.selectTodayBooking();

            if (this.userDataDtoReturnSession.destMemoSizeExceeded) {
                this.destMemoSizeExceedRights = true;
            }
        }
    }

    ngOnInit() {
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
    }

    selectTodayBooking() {
        this.tdyBookingDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    getBranchList() {
        this.destinationDataList = []
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.mode = "activeDeliveryOfc";
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch).subscribe(
            (response) => {
                if (response) {
                    this.destinationTA = [];
                    if (response.length > 0) {
                        this.destinationDataList = response;
                        for (let i = 0; i < this.destinationDataList.length; i++) {
                            this.destinationTA.push(this.destinationDataList[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the the destination details", "info");
            },
            () => console.log('done');
    }

    getUserValuesForAgentDetailsList() {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = "ALL";
    }

    getAgentDetailList() {
        $("#" + this.pageId + "agentName").val('');
        this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList();
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsSet = response;
                    // this.agentDtoParam = new AgentDetailsDto();
                    // this.agentDtoParam.source = "ALL";
                    // this.agentNameTA.push(this.agentDtoParam);
                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getCommodityListRead() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "ALL";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Master Details", "warning");
            },
            () => console.log('done');
    }

    rateTypes(rateType: string) {
        console.log(rateType);
        if (rateType === 'commodity') {
            this.viewPartyFields = false;
        } else if (rateType === 'party') {
            this.viewPartyFields = true;
        }
    }

    getConsignorDetailsOnEnter(e) {
        if (e.keyCode == 13) {
            this.getConsignorDetails();
        }
    }

    getConsignorDetails() {
        this.partyMasterDtoForConsignor = new PartyMasterDto();
        this.partyMasterDtoForConsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.enteredConsignorGstNo = $("#" + this.pageId + "consignorGSTNumber").val();
        this.partyMasterDtoForConsignor.mode = "GstSearch";
        this.partyMasterDtoForConsignor.gstNoConsignor = this.enteredConsignorGstNo;
        this.showSpinnerForAction = true;
        this.consignorService.getConsignorDetailsMaster(this.partyMasterDtoForConsignor).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                this.consignorMasterDataList = [];
                if (response.length > 0) {
                    this.consignorMasterDataList = response;
                    this.consignorDetails = this.consignorMasterDataList[0];
                    this.consignorId = this.consignorDetails.consignorId;
                    $("#" + this.pageId + "consignorName").val(this.consignorDetails.consignorName);
                    $("#" + this.pageId + "consignorAddress").val(this.consignorDetails.address);
                    $("#" + this.pageId + "consignorMobNo").val(this.consignorDetails.mobileNumber);
                } else {
                    swal("Alert", "No Details Found", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Consignor Details", "warning");
            },
            () => console.log('done');
    }

    getConsigneeDetailsOnEnter(e) {
        if (e.keyCode == 13) {
            this.getConsigneeDetails();
        }
    }

    getConsigneeDetails() {
        this.partyMasterDtoForConsignee = new PartyMasterDto();
        this.enteredConsigneeGstNo = $("#" + this.pageId + "consigneeGSTNumber").val();
        this.partyMasterDtoForConsignee.mode = 'gstSearch';
        this.partyMasterDtoForConsignee.gstNoConsignee = this.enteredConsigneeGstNo;
        this.partyMasterDtoForConsignee.office = this.userDataDtoReturnSession.mainStation;
        this.showSpinnerForAction = true;
        console.log(this.partyMasterDtoForConsignee);
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForConsignee).subscribe(
            (response) => {
                console.log(response);
                this.consigneeDetailsDataList = [];
                if (response.length > 0) {
                    this.consigneeDetailsDataList = response;
                    this.consigneeDetails = this.consigneeDetailsDataList[0];
                    this.consigneeId = this.consigneeDetails.consigneeId;
                    $("#" + this.pageId + "consigneeName").val(this.consigneeDetails.consigneeName);
                    $("#" + this.pageId + "consigneeAddress").val(this.consigneeDetails.address);
                    $("#" + this.pageId + "consigneeMobNo").val(this.consigneeDetails.mobileNumber);
                } else {
                    swal("Alert", "No details found!", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Consignee Details", "warning");
            },
            () => console.log('done');
    }

    getConsignorRateDetailsUserValues() {
        this.rateMasterDtoConsignor = new RateMasterDto();
        this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
        this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
        this.enteredSource = $("#" + this.pageId + "agentName").val();
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();

        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedPartyCommodity = $("#" + this.pageId + "commodity").val();
        let enterBookingDate = null;
        enterBookingDate = $("#" + this.pageId + "bkgDate").val();
        this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");

        this.rateMasterDtoConsignor.totalActWgt = this.enteredActualWeight;
        this.rateMasterDtoConsignor.totalChgWgt = this.enteredChargeWeight;
        this.rateMasterDtoConsignor.source = this.enteredSource;
        this.rateMasterDtoConsignor.totalArticles = this.totArticles;
        this.rateMasterDtoConsignor.station = this.selectedDestination;
        this.rateMasterDtoConsignor.commodityName = this.selectedPartyCommodity;
        this.rateMasterDtoConsignor.bookingDate = this.enteredBookingDate;
        this.rateMasterDtoConsignor.consignorId = this.consignorId;
        this.rateMasterDtoConsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoConsignor.consigneeId = (this.consigneeId == null ? 0 : this.consigneeId);
        this.rateMasterDtoConsignor.consigneeIdTemp = (this.consigneeId == null ? 0 : this.consigneeId);
        console.log("Consingnor");
        console.log(this.rateMasterDtoConsignor);
    }

    getConsignorRateDetails() {
        this.resetConsignorRateDetails();
        this.getConsignorRateDetailsUserValues();
        this.masterReadService.calculateLRRateBasedOnConsignorRateService(this.rateMasterDtoConsignor).subscribe(
            (response) => {

                this.rateMasterDtoConsignorResp = response;
                console.log("Consignor Rate");
                console.log(this.rateMasterDtoConsignorResp);
                if (this.rateMasterDtoConsignorResp.id != null && this.rateMasterDtoConsignorResp.id > 0) {
                    console.log(this.consignorRateFlowSet);
                    console.log(this.consigneeRateFlowSet);
                    this.consignorRateFlowSet = true;
                    this.consigneeRateFlowSet = false;
                    this.partyFrieghtDetails = true;

                    this.consignorRateId = this.rateMasterDtoConsignorResp.id;
                    this.consignorRateSrc = this.rateMasterDtoConsignorResp.source;
                    this.consignorRateFor = this.rateMasterDtoConsignorResp.rateFor;
                    this.consignorRateConsignor = this.rateMasterDtoConsignorResp.consignorName;
                    this.consignorRateDest = this.rateMasterDtoConsignorResp.destination;
                    this.consignorRateCommodity = this.rateMasterDtoConsignorResp.commodityName;
                    this.consignorRateType = this.rateMasterDtoConsignorResp.ratePerUnit;
                    this.consignorRatePerUnit = this.rateMasterDtoConsignorResp.prevPerUnit;
                    this.consignorRatePerUnitRateOnActWgt = this.rateMasterDtoConsignorResp.rateOnActWgt;
                    this.consignorRateBasicRate = this.rateMasterDtoConsignorResp.basicCharge;
                    this.consignorRateHamali = this.rateMasterDtoConsignorResp.hamali;
                    this.consignorRateGcChg = this.rateMasterDtoConsignorResp.gcCharge;
                    this.consignorRateAoc = this.rateMasterDtoConsignorResp.aocChargeAmt;
                    this.consignorRateOthers = this.rateMasterDtoConsignorResp.otherChgAmt;
                    this.consignorRateDDChg = this.rateMasterDtoConsignorResp.ddChg;
                    this.consignorRateWEF = this.rateMasterDtoConsignorResp.effectiveFromDateStg;
                    this.consignorRateConsignee = this.rateMasterDtoConsignorResp.consigneeName;

                    //CHG-V-LR:07
                    this.consignorIdRateId = this.rateMasterDtoConsignorResp.consignorId;

                    console.log('rate : ' + this.rateMasterDtoConsignorResp);
                    // $("#" + this.pageId + "baseFreight").val(this.rateMasterDtoConsignorResp.lorryFreight);
                    this.partyBaseFreight = this.rateMasterDtoConsignorResp.lorryFreight;
                    //CHG-V-LR:01
                    if (this.rateMasterDtoConsignorResp.ratePerUnit == 'Fixed' && this.destMemoSizeExceedRights) {
                        this.getCommodityKgLimitDetails(this.rateMasterDtoConsignorResp.basicCharge);
                    } else {
                        //CHG-V-LR:23
                        this.callPerkgRateDetails();
                    }
                    //CHG-V-LR:41
                    this.callCommodityBlockedDetails();
                    // if (this.userDataDtoReturnSession.lrEntryPerKgLimit != null && this.userDataDtoReturnSession.lrEntryPerKgLimit > 0) {
                    //     this.calcBaseFreightWithCommonCharges();
                    // }

                    this.partyHamali = this.rateMasterDtoConsignorResp.hamaliDD;
                    this.partyGcCharge = this.rateMasterDtoConsignorResp.gcCharge;
                    this.partyAocCharge = this.rateMasterDtoConsignorResp.aocChargeAmt;
                    this.partyOtherCharge = this.rateMasterDtoConsignorResp.otherChgAmt;

                    // for dd chg @02062022
                    // this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
                    // if (this.selectedDoorDelivery != null && this.selectedDoorDelivery == 'Yes') {
                    //     $("#" + this.pageId + "ddAmt").val(this.rateMasterDtoConsignorResp.ddChargeAmt);
                    //     this.ddAmtRateChg = this.rateMasterDtoConsignorResp.ddChargeAmt;
                    // } else {
                    //     $("#" + this.pageId + "ddAmt").val('');
                    //     this.ddAmtRateChg = 0;
                    // }
                    //CHG-V-LR:21
                    this.consignorRateFov = this.rateMasterDtoConsignorResp.riskChargeAmt;


                    // //CHG-V-LR:22
                    // if (this.userDataDtoReturnSession.blockFreightRateInLrEntrry != null && this.userDataDtoReturnSession.blockFreightRateInLrEntrry) {
                    //     // this.rateFrieghtFieldsReadOnly();
                    //     this.isFrieghtFieldsReadOnly = true;
                    // } else {
                    //     this.isFrieghtFieldsReadOnly = false;
                    // }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');

    }

    resetConsignorRateDetails() {
        this.consignorRateId = 0;
        this.consignorIdRateId = 0;
        this.consignorRateSrc = null;
        this.consignorRateFor = null;
        this.consignorRateConsignor = null;
        this.consignorRateDest = null;
        this.consignorRateCommodity = null;
        this.consignorRateType = null;
        this.consignorRatePerUnit = null;
        this.consignorRatePerUnitRateOnActWgt = null;
        this.consignorRateBasicRate = null;
        this.consignorRateHamali = null;
        this.consignorRateGcChg = null;
        this.consignorRateAoc = null;
        this.consignorRateOthers = null;
        this.consignorRateDDChg = null;
        this.consignorRateWEF = null;
        this.consignorRateConsignee = null;
        // this.partyBaseFreight = null;
        // this.partyHamali = null;
        // this.partyGcCharge = null;
        // this.partyAocCharge = null;
        // this.partyOtherCharge = null;
        // this.totalAmount = null;
    }

    getConsigneeRateDetails() {
        // this.resetConsigneeRateDetails();
        // let enterBookingDate = null;
        // this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
        // this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
        // this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        // this.selectedPartyCommodity = $("#" + this.pageId + "commodity").val();
        // enterBookingDate = this.datePipe.transform(new Date());
        // this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");

        // this.selectedDestination = $("#" + this.pageId + "destination").val();

        // this.enteredSource = $("#" + this.pageId + "agentName").val();

        // this.rateDtoForConsigneeRateDetails = new RateMasterDto();
        // this.rateDtoForConsigneeRateDetails.rateType = "Admin";
        // this.rateDtoForConsigneeRateDetails.source = this.enteredSource;
        // this.rateDtoForConsigneeRateDetails.consigneeId = this.consigneeId;
        // this.rateDtoForConsigneeRateDetails.city = this.selectedDestination;
        // this.rateDtoForConsigneeRateDetails.commodityName = this.selectedPartyCommodity;
        // this.rateDtoForConsigneeRateDetails.bookingDate = this.enteredBookingDate;
        // this.rateDtoForConsigneeRateDetails.companyId = this.userDataDtoReturnSession.companyId;
        // this.rateDtoForConsigneeRateDetails.totalActWgt = this.enteredActualWeight;
        // this.rateDtoForConsigneeRateDetails.totalChgWgt = this.enteredChargeWeight;
        // this.rateDtoForConsigneeRateDetails.totalArticles = this.totArticles;
        // this.rateDtoForConsigneeRateDetails.commodityName = this.selectedPartyCommodity;

        // console.log("Consingnee");
        // console.log(this.rateDtoForConsigneeRateDetails);

        // this.masterReadService.calculateLRRateBasedOnConsigneeRateService(this.rateDtoForConsigneeRateDetails).subscribe(
        //     (response) => {
        //         console.log('getConsigneeRateDetails');
        //         console.log(response);
        //         if (response.length > 0) {
        //             this.consigneeIdRateId = response[0].id;
        //             //CHG-V-LR:58
        //             this.consigneeRateDtlsRet = response[0];
        //             console.log(this.consigneeRateDtlsRet);
        //             this.isConsigneeRateBkg = this.consigneeRateDtlsRet.rateAtSrcCheck;
        //             this.consigneeRateEffectiveToDate = this.consigneeRateDtlsRet.effectiveToDate;
        //             this.consigneeUnit = this.consigneeRateDtlsRet.unit;
        //             console.log(this.consigneeRateDtlsRet.column2);
        //             if (this.consigneeRateDtlsRet.column2 != null && this.consigneeRateDtlsRet.column2 == 'onLr') {
        //                 this.consigneeRateColumn2 = 'On LR'
        //             } else if (this.consigneeRateDtlsRet.column2 != null && this.consigneeRateDtlsRet.column2 == 'onTotal') {
        //                 this.consigneeRateColumn2 = 'On Total'
        //             } else if (this.consigneeRateDtlsRet.column2 != null && this.consigneeRateDtlsRet.column2 == 'onBaseFrgt') {
        //                 this.consigneeRateColumn2 = 'On Base Freight'
        //             }
        //             console.log(this.consignorRateFlowSet);
        //             console.log(this.consigneeRateFlowSet);
        //             if (this.isConsigneeRateBkg && this.consigneeRateEffectiveToDate == null) {
        //                 if (this.consigneeUnit != null && this.consigneeUnit != undefined && this.consigneeUnit != ''
        //                     && this.consigneeUnit == 'Rate') {
        //                     this.consignorRateFlowSet = false;
        //                     this.consigneeRateFlowSet = true;
        //                     this.partyFrieghtDetails = true;
        //                     console.log(this.consignorRateFlowSet);
        //                     console.log(this.consigneeRateFlowSet);

        //                     this.consigneeRateSrc = this.consigneeRateDtlsRet.source;
        //                     this.consigneeRateConsignee = this.consigneeRateDtlsRet.consigneeName;
        //                     this.consigneeRateDest = this.consigneeRateDtlsRet.destination;
        //                     this.consigneeRateCommodity = this.consigneeRateDtlsRet.commodityName;
        //                     this.consigneeRateUnit = this.consigneeRateDtlsRet.unit;
        //                     if (this.consigneeRateDtlsRet.unit != null && this.consigneeRateDtlsRet.unit == 'Rate') {
        //                         this.consigneeRatePerUnit = this.consigneeRateDtlsRet.perUnit;
        //                     } else if (this.consigneeRateDtlsRet.unit != null && this.consigneeRateDtlsRet.unit == 'Discount') {
        //                         this.consigneeRatePerUnit = this.consigneeRateColumn2;
        //                     }
        //                     this.consigneeRateActWgt = this.consigneeRateDtlsRet.rateOnActWgt;
        //                     this.consigneeRateBasicChg = this.consigneeRateDtlsRet.rate;
        //                     this.consigneeRateHamali = this.consigneeRateDtlsRet.srcHamaliChg;
        //                     this.consigneeRateGcChg = this.consigneeRateDtlsRet.gcCharge;
        //                     this.consigneeRateAoc = (this.consigneeRateDtlsRet.aoc ? 'True' : 'False');
        //                     this.consigneeRateOthers = (this.consigneeRateDtlsRet.others ? 'True' : 'False');
        //                     this.consigneeRateDDChg = (this.consigneeRateDtlsRet.dd ? 'True' : 'False');
        //                     this.consigneeRateConsignor = 'ALL';
        //                     this.consigneeRateRiskChg = (this.consigneeRateDtlsRet.riskCharge ? 'True' : 'False');

        //                     this.partyBaseFreight = this.consigneeRateDtlsRet.lorryFreight;
        //                     //CHG-V-LR:01
        //                     if (this.consigneeRateDtlsRet.perUnit == 'Article' && this.destMemoSizeExceedRights) {
        //                         this.getCommodityKgLimitDetails(this.consigneeRateDtlsRet.rate);
        //                     } else {
        //                         //CHG-V-LR:23
        //                         this.callPerkgRateDetails();
        //                     }
        //                     //CHG-V-LR:41
        //                     this.callCommodityBlockedDetails();

        //                     this.partyHamali = this.consigneeRateDtlsRet.srcHamaliChg;
        //                     this.partyGcCharge = this.consigneeRateDtlsRet.gcCharge;

        //                     if (this.consigneeRateDtlsRet.aoc != null && this.consigneeRateDtlsRet.aoc) {
        //                         this.partyAocCharge = 0;
        //                     } else {
        //                         console.log(this.rateMasterDtoConsignorResp.aocChargeAmt);
        //                         this.partyAocCharge = this.rateMasterDtoConsignorResp.aocChargeAmt;
        //                     }
        //                     if (this.consigneeRateDtlsRet.others != null && this.consigneeRateDtlsRet.others) {
        //                         this.partyOtherCharge = 0;
        //                     } else {
        //                         console.log(this.rateMasterDtoConsignorResp.otherChgAmt);
        //                         this.partyOtherCharge = this.rateMasterDtoConsignorResp.otherChgAmt;
        //                     }

        //                     // for dd chg @02062022
        //                     //   this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
        //                     //   if (this.selectedDoorDelivery != null && this.selectedDoorDelivery == 'Yes') {
        //                     //     if (this.consigneeRateDtlsRet.dd != null && this.consigneeRateDtlsRet.dd) {
        //                     //       $("#" + this.pageId + "ddAmt").val(0);
        //                     //     } else {
        //                     //       $("#" + this.pageId + "ddAmt").val(this.rateMasterDtoConsignorResp.ddChargeAmt);
        //                     //     }
        //                     //     this.ddAmtRateChg = this.rateMasterDtoConsignorResp.ddChargeAmt;
        //                     //   } else {
        //                     //     $("#" + this.pageId + "ddAmt").val('');
        //                     //     this.ddAmtRateChg = 0;
        //                     //   }
        //                     // CHG-V-LR:21
        //                     this.consigneeRateFov = this.consigneeRateDtlsRet.riskCharge;

        //                     //CHG-V-LR:22
        //                     //   if (this.userDataDtoReturnSession.blockFreightRateInLrEntrry != null && this.userDataDtoReturnSession.blockFreightRateInLrEntrry) {
        //                     //     this.isFrieghtFieldsReadOnly = true;
        //                     //   } else {
        //                     //     this.isFrieghtFieldsReadOnly = false;
        //                     //   }
        //                 }
        //             }
        //         }
        //         this.changeDetectorRef.detectChanges();
        //     })
    }

    resetConsigneeRateDetails() {
        this.consigneeIdRateId = 0;
        this.consigneeRateSrc = null;
        this.consigneeRateConsignee = null;
        this.consigneeRateDest = null;
        this.consigneeRateCommodity = null;
        this.consigneeRateUnit = null;
        this.consigneeRatePerUnit = null;
        this.consigneeRateActWgt = null;
        this.consigneeRateBasicChg = null;
        this.consigneeRateHamali = null;
        this.consigneeRateGcChg = null;
        this.consigneeRateAoc = null;
        this.consigneeRateOthers = null;
        this.consigneeRateDDChg = null;
        this.consigneeRateRiskChg = null;
        this.consigneeRateFlowSet = false;
        this.isConsigneeRateBkg = false;
        this.consigneeRateEffectiveToDate = null;
        this.consigneeRateConsignor = '';
        this.consigneeRateColumn2 = null;
        this.consigneeRateFov = null;
        this.consigneeUnit = null;
        // this.partyBaseFreight = null;
        // this.partyHamali = null;
        // this.partyGcCharge = null;
        // this.partyAocCharge = null;
        // this.partyOtherCharge = null;
        // this.totalAmount = null;
    }

    clearDetails() {
        this.consignorRateFlowSet = false;
        this.consignorRateId = 0;
        this.consignorIdRateId = 0;
        this.consignorRateSrc = null;
        this.consignorRateFor = null;
        this.consignorRateConsignor = null;
        this.consignorRateDest = null;
        this.consignorRateCommodity = null;
        this.consignorRateType = null;
        this.consignorRatePerUnit = null;
        this.consignorRatePerUnitRateOnActWgt = null;
        this.consignorRateBasicRate = null;
        this.consignorRateHamali = null;
        this.consignorRateGcChg = null;
        this.consignorRateAoc = null;
        this.consignorRateOthers = null;
        this.consignorRateDDChg = null;
        this.consignorRateWEF = null;
        this.consignorRateConsignee = null;
        this.consigneeIdRateId = 0;
        this.consigneeRateSrc = null;
        this.consigneeRateConsignee = null;
        this.consigneeRateDest = null;
        this.consigneeRateCommodity = null;
        this.consigneeRateUnit = null;
        this.consigneeRatePerUnit = null;
        this.consigneeRateActWgt = null;
        this.consigneeRateBasicChg = null;
        this.consigneeRateHamali = null;
        this.consigneeRateGcChg = null;
        this.consigneeRateAoc = null;
        this.consigneeRateOthers = null;
        this.consigneeRateDDChg = null;
        this.consigneeRateRiskChg = null;
        this.consigneeRateFlowSet = false;
        this.isConsigneeRateBkg = false;
        this.consigneeRateEffectiveToDate = null;
        this.consigneeRateConsignor = '';
        this.consigneeRateColumn2 = null;
        this.consigneeRateFov = null;
        this.consigneeUnit = null;
        this.consigneeRateFlowSet = false;
        this.isPerKgLimitTaken = false;
        this.partyFrieghtDetails = false;
        this.partyBaseFreight = null;
        this.partyHamali = null;
        this.partyGcCharge = null;
        this.partyAocCharge = null;
        this.partyOtherCharge = null;
        this.maxActWgt = '';
        this.maxChgWgt = '';
        this.totalAmount = null;
        this.viewPartyFields = false;
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "rateTypeId").val('commodity');
        $("#" + this.pageId + "commodity").val('');
        $("#" + this.pageId + "noOfArticles").val('');
        $("#" + this.pageId + "actualWeight").val('');
        $("#" + this.pageId + "chargedWeight").val('');
        $("#" + this.pageId + "consignorGSTNumber").val('');
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "consignorMobNo").val('');
        $("#" + this.pageId + "consignorAddress").val('');
        $("#" + this.pageId + "consigneeGSTNumber").val('');
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "consigneeMobNo").val('');
        $("#" + this.pageId + "consigneeAddress").val('');
    }

    getPartyRateDetails() {
        if (this.consignorRateFlow) {
            this.getConsignorRateDetails();
        }
        setTimeout(() => {
            if (this.consigneeId > 0) {
                this.getConsigneeRateDetails();
            }
        }, 1000);

        setTimeout(() => {
            this.sum();
        }, 1500);
    }

    getCommodityKgLimitDetails(basicRate) {
        this.commodityKglimit = 0;
        this.isSizeCommodityExceed = false;
        this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
        this.rateMasterDtoGet = new RateMasterDto();
        this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.rateMasterDtoGet.destination = this.selectedDestination;
        this.rateMasterDtoGet.commodityName = this.selectedPartyCommodity;

        // if (this.viewByAutoLr == true || this.lrCheck == true) {
        //   this.rateMasterDtoGet.source = this.userDataDtoReturnSession.mainStation;
        // } else {
        //   this.rateMasterDtoGet.source = this.selectedAgentName;
        // }
        this.rateMasterDtoGet.source = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoGet.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getCommoditySizeExceedDetailsForLR(this.rateMasterDtoGet).subscribe(
            (response) => {
                console.log(response);
                var chgWgtPerArt: any;
                this.commodityKgLimitData = response;
                console.log("this.commodityKgLimitData" + this.commodityKgLimitData);
                chgWgtPerArt = this.enteredChargedWeight / this.totArticles;
                console.log("chgWgtPerArt" + chgWgtPerArt);
                if (this.commodityKgLimitData != null) {
                    this.commodityKglimit = this.commodityKgLimitData.commodityKgLimit;
                    console.log(" this.commodityKglimit" + this.commodityKglimit);
                    //Chg_V-Lr:30
                    if (this.commodityKglimit != null && this.commodityKglimit) {
                        if (parseInt(chgWgtPerArt) > parseInt(this.commodityKglimit)) {
                            // swal({
                            //   title: "Alert",
                            //   text: "Charged Weight is Greater then commodity Limit...Please Check the Charged Weight",
                            //   icon: "warning",
                            //   closeOnClickOutside: false,
                            //   closeOnEsc: false,
                            // });
                            this.baseFreight = this.partyBaseFreight;

                            console.log("this.baseFreight" + this.baseFreight);
                            console.log("this.commodityKglimit" + this.commodityKglimit);
                            console.log("this.enteredChargedWeight" + this.enteredChargedWeight);
                            // var calcRate = ((this.baseFreight / this.commodityKglimit) * this.enteredChargedWeight);
                            var calcRate = ((this.baseFreight / this.commodityKglimit) * chgWgtPerArt);

                            console.log("calcRate" + calcRate);
                            this.baseFreight = calcRate;
                            this.partyBaseFreight = calcRate.toFixed(0);
                            setTimeout(() => {
                                this.sum();
                            }, 1000);
                            this.isSizeCommodityExceed = true;
                        } else {
                            this.isSizeCommodityExceed = false;
                            //Chg_V-Lr:33
                            this.callPerkgRateDetails();
                        }
                    } else {
                        //Chg_V-Lr:33
                        this.callPerkgRateDetails();
                    }

                } else {
                    //Chg_V-Lr:33
                    this.callPerkgRateDetails();
                }

                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the Commodity KG Limit Details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    sum() {
        console.log(this.partyBaseFreight);
        console.log(this.partyHamali);
        console.log(this.partyGcCharge);
        console.log(this.partyAocCharge);
        console.log(this.partyOtherCharge);
        this.totalAmt = 0.0;
        this.totalAmount = 0.0;

        this.baseFreight = this.partyBaseFreight;
        this.hamali = this.partyHamali;
        this.gcCharge = this.partyGcCharge;
        // this.lcCharge = $("#" + this.pageId + "lcCharge").val();
        // this.bcCharge = $("#" + this.pageId + "bcCharge").val();
        this.aocCharge = this.partyAocCharge;
        this.others = this.partyOtherCharge;
        // this.ddAmt = $("#" + this.pageId + "ddAmt").val();
        // this.enteredRiskChg = $("#" + this.pageId + "fov").val();
        // this.entereCODCharge = this.setCodCharge;
        //        console.log( this.baseFreight );
        this.totalAmt = (+(this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight)
            + +(this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali)
            + +(this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge)
            // + +(this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge)
            // + +(this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge)
            + +(this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge)
            + +(this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others)
            // + +(this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt)
            // + +(this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg)
            // + +(this.entereCODCharge == null ? 0.0 : this.entereCODCharge == '' ? 0.0 : this.entereCODCharge)
        );

        // this.totalAmount = this.totalAmt == null ? 0.0 : this.totalAmt;
        // setTimeout(() => {
            this.totalAmount = this.totalAmt == null ? 0.0 : this.totalAmt;
        // }, 1000);
        this.changeDetectorRef.detectChanges();
        console.log(this.totalAmt);
        console.log(this.totalAmount);
    }

    callPerkgRateDetails() {
        this.showSpinnerForAction = true;
        this.enteredSource = $("#" + this.pageId + "agentName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.lrDtoPerKgGet = new LRDto();
        this.lrDtoPerKgGet.source = this.enteredSource;
        this.lrDtoPerKgGet.destination = this.selectedDestination;
        this.lrDtoPerKgGet.companyId = this.userDataDtoReturnSession.companyId;

        this.masterReadService.getPerKgRateDetailsForLR(this.lrDtoPerKgGet).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoPerKgRateReturn = response;
                if ((this.lrtDtoPerKgRateReturn != null) &&
                    (this.lrtDtoPerKgRateReturn.hirePerKg != null && this.lrtDtoPerKgRateReturn.hirePerKg > 0)) {
                    this.calcBaseFreightWithPerKGRateChg(this.lrtDtoPerKgRateReturn.hirePerKg);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details from Per KG Charge", "warning");
            }, () => console.log('done');

    }

    callCommodityBlockedDetails() {
        this.showSpinnerForAction = true;
        this.isCommodityBlockedBkg = false;
        this.dtoBlockedcmdty = new RateMasterDto();

        //CHG-V-LR:44
        // if (this.commodityType == 'rateCommodity') {
        //     this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
        // } `
        // else if (this.commodityType == 'actualCommodity') {
        //     this.selectedPartyCommodity = $("#" + this.pageId + "mainCommodity").val();
        // }
        this.selectedPartyCommodity = $("#" + this.pageId + "commodity").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.enteredSource = $("#" + this.pageId + "agentName").val();
        this.dtoBlockedcmdty.source = this.enteredSource;
        this.dtoBlockedcmdty.destination = this.selectedDestination;
        this.dtoBlockedcmdty.commodityName = this.selectedPartyCommodity;
        this.dtoBlockedcmdty.companyId = this.userDataDtoReturnSession.companyId;
        console.log(" this.dtoBlockedcmdty" + this.dtoBlockedcmdty);
        console.log(this.dtoBlockedcmdty);
        this.masterReadService.getBlockedCommodityDetails(this.dtoBlockedcmdty).subscribe(
            (response) => {
                this.dtoBlockedCmdtyRtn = new ResponseDto();
                this.showSpinnerForAction = false;
                this.dtoBlockedCmdtyRtn = response;
                console.log(" this.dtoBlockedCmdtyRtn" + this.dtoBlockedCmdtyRtn);
                this.isCommodityBlockedBkg = this.dtoBlockedCmdtyRtn.blockedCommodity;
                if (this.isCommodityBlockedBkg) {
                    swal("Not Allowed", this.selectedPartyCommodity + " commodity is blocked for booking, please contact admin!", "warning");
                    //$("#" + this.pageId + "partyCommodity").val('');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details!", "warning");
            }, () => console.log('done');

    }

    calcBaseFreightWithPerKGRateChg(perKgRate) {
        var lrEntryCharges = 0.0, perKGRateChg = 0.0, baseFreight = 0.0, actualWgt = 0.0, baseFreightReturn = 0.0;
        baseFreight = parseFloat(this.partyBaseFreight) || 0.0;
        console.log("baseFreight" + baseFreight);
        actualWgt = parseFloat($("#" + this.pageId + "actualWeight").val().toString()) || 0.0;
        console.log("actualWgt" + actualWgt);
        if (baseFreight > 0 && actualWgt > 0) {
            lrEntryCharges = baseFreight / actualWgt;
            console.log("lrEntryCharges" + lrEntryCharges);
            perKGRateChg = perKgRate == null ? 0.0 : perKgRate;
            console.log("perKGRateChg" + perKGRateChg);
            if (lrEntryCharges > perKGRateChg) {
                baseFreightReturn = actualWgt * perKGRateChg;
                console.log("baseFreightReturn" + baseFreightReturn);
                this.partyBaseFreight = baseFreightReturn.toFixed(0);
                // console.log("Base field" + $("#" + this.pageId + "baseFreight").val());
                setTimeout(() => {
                    this.sum();
                }, 1000);
                //Chg_V-Lr:32
                this.isPerKgLimitTaken = true;
            }
        }
    }

    actualWeightBlur(e) {
        //CHG-V-LR:01
        if (this.consignorRateFlow) {
            this.getConsignorRateDetails();
        }
        // this.validateFOVRate();
        setTimeout(() => {
            this.sum();
        }, 1500);

        //CHG-V-LR:58
        setTimeout(() => {
            if (this.consigneeId > 0) {
                this.getConsigneeRateDetails();
            }
        }, 1000);
    }

    //For actual Weight
    actualWeightEvent(e) {
        if (e.keyCode == 13) {
            this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
            this.maxActWgt = this.userDataDtoReturnSession.maxActWgt;
            //            console.log( this.userDataDtoReturnSession.maxActWgt );
            if (this.enteredActualWeight > this.maxActWgt) {
                //                message is Pending
                swal({
                    title: "Alert",
                    text: "Actual Weight is Maximum...Please Check the Actual Weight",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    this.chargedWeightField.nativeElement.focus();
                });

            } else {
                this.chargedWeightField.nativeElement.focus();
            }
            //CHG-V-LR:01
            if (this.consignorRateFlow) {
                this.getConsignorRateDetails();
            }
            // this.validateFOVRate();
            setTimeout(() => {
                this.sum();
            }, 1500);

            //CHG-V-LR:58
            setTimeout(() => {
                if (this.consigneeId > 0) {
                    this.getConsigneeRateDetails();
                }
            }, 1000);
        }
    }

    chargedWeightBlur(content7) {
        console.log('chargedWeightBlur');
        console.log(this.multiArtBlurEvent);
        if (this.multiArtBlurEvent) {
            this.chargedWgtValidation(content7);
        }
    }

    chargedWgtValidation(content7) {
        this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
        this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
        this.maxChgWgt = this.userDataDtoReturnSession.maxChgWgt;
        if (parseInt(this.enteredChargedWeight) < parseInt(this.enteredActualWeight)) {
            swal({
                title: "Alert",
                text: "Charged Weight Should Be Equal Or Greater Than Actual Weight",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#" + this.pageId + "chargedWeight").val('');
                this.chargedWeightField.nativeElement.focus();
            });

        }

        else if (parseInt(this.enteredChargedWeight) > parseInt(this.maxChgWgt)) {

            swal({
                title: "Alert",
                text: "Charged Weight is Maximum...Please Check the Charged Weight",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                // this.allowMultiArtPopUp(content7);
            });
        } else {
            // if (this.chargedWeight == this.actualWeight) {
            //     this.goodsValueField.nativeElement.focus();
            //     this.multiArtTableDataList = [];
            //     this.clearMulArt();
            // } else {
            //     this.allowMultiArtPopUp(content7);
            // }
        }
        //CHG-V-LR:01
        if (this.consignorRateFlow) {
            this.getConsignorRateDetails();
        }
        // this.validateFOVRate();
        setTimeout(() => {
            this.sum();
        }, 1500);

        //CHG-V-LR:58
        setTimeout(() => {
            if (this.consigneeId > 0) {
                this.getConsigneeRateDetails();
            }
        }, 1000);
    }

    fieldFocusArticles(e, artilesScanPopupOpen) {
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        if (e.keyCode == 13) {
            if ((this.totArticles != null) && (this.totArticles != '') && (parseInt(this.totArticles) > 1000)) {
                swal({
                    title: "Validation",
                    text: "Number of Articles is: " + this.totArticles + ". Is it correct???",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    //                    dangerMode: true,
                    //            buttons: ["Cancel", true],
                    buttons: ["No", "Yes"],
                }).then((articleNext) => {
                    //   if (articleNext) {
                    //     if (this.lrScanAllow == true) {
                    //       this.lrArtScanEvent(artilesScanPopupOpen);
                    //     } else {
                    //       this.packNatureField.nativeElement.focus();
                    //     }
                    //   } else {
                    this.actualWeightField.nativeElement.focus();
                    //   }
                });
            } else {
                // if (this.lrScanAllow == true) {
                //   this.lrArtScanEvent(artilesScanPopupOpen);
                // } else {
                  this.actualWeightField.nativeElement.focus();
                // }
            }
            //CHG-V-LR:01
            if (this.consignorRateFlow) {
                this.getConsignorRateDetails();
            }
            //   this.validateFOVRate();
            setTimeout(() => {
                this.sum();
            }, 1500);

            //CHG-V-LR:58
            setTimeout(() => {
                if (this.consigneeId > 0) {
                    this.getConsigneeRateDetails();
                }
            }, 1000);
        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    partyCommodityFocus(e) {
        if (e.keyCode == 13) {
            //this.validateMainCommoditySelection();
            //if (this.isMainCommodityValid) {
            //            console.log( 'a' );
            this.selectedPartyCommodity = $("#" + this.pageId + "commodity").val();
            //        console.log(this.selectedPartyCommodity);
            // if (this.selectedPartyCommodity != null && this.selectedPartyCommodity == "Add New") {
            //     this.showGoodsType = true;
            //     this.goodsTypeCommdityOptions = '';
            //     this.goodsTypeTA = [];
            //     this.statusOfCommodity = "forGlobalCommodityListBng";
            //     this.getBangaloreAllCommodityConsignee();
            //     //this.goodsTypeField.nativeElement.focus();
            //     $("#" + this.pageId + "goodsType").focus();
            // } else {

            //   this.showGoodsType = false;

            //this.subCommodityField.nativeElement.focus();
            // $("#lrefcmainCommodity").focus();
            // }
            //}
            //CHG-V-LR:41
            //   this.commodityType = 'rateCommodity';
            this.callCommodityBlockedDetails();
            //CHG-V-LR:01
            if (this.consignorRateFlow) {
                this.getConsignorRateDetails();
            }
            //   this.validateFOVRate();
            setTimeout(() => {
                this.sum();
            }, 1500);
            //   this.getCFTExemptionForCommoditiesDetails();
            //CHG-V-LR:55
            // if (this.selectedConsigneeId > 0) {
            //   this.getConsigneeRateDetails();
            // }
            setTimeout(() => {
                if (this.consigneeId > 0) {
                    this.getConsigneeRateDetails();
                }
            }, 1000);
        }
    }

    clickListnerForRateCommodity(e: NgbTypeaheadSelectItemEvent) {
        //alert('ok');
        // this.commodityType = 'rateCommodity';
        this.callCommodityBlockedDetails();
    }

}
