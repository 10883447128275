<div class="row" *ngIf="isLoggedIn" id="{{pageId}}clEWB">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row ">
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>E-Way Bill No* </label>
                                                    <input #ewayBillNo type="text" id="{{pageId}}ewayBillNo" class="form-control" placeholder="Enter EWay Bill No" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                            <hr style="width: 80%; border-top: none; margin: 3px;">
                                        </div>
                                        <div class="col-md-5" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10" (click)="validateForEWayBillNo();" id="{{pageId}}searchBtn">Search</button>
                                            <!-- <button type="submit" class="btn btn-dark" (click)="clearField();" id="{{pageId}}clearBtn">Clear</button> -->
                                            <hr style="width: 80%; border-top: none; margin: 3px;">
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                    <label>Cancel Reason* </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                        </span>
                                                    </div>
                                                    <input #cancelReason id="{{pageId}}cancelReason" name="cancelReason" type="text" class="form-control" placeholder="Select Reason" [ngbTypeahead]="cancelReasonSearchTA" [(ngModel)]="modelCancelReason" (keyup)="fieldFocus($event, cancelEWB)" (focus)="focusCancelReasonTA$.next($any($event).target.value)"
                                                        #instanceCancelReason="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10" (click)="validateEWBCancel();" id="{{pageId}}cancelEWB">Cancel EWB</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="search-container">
                                        <div class="pdf-viewer" *ngIf="pdfUrl">
                                            <iframe [src]="pdfUrl" width="100%" height="600px"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>