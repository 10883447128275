<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}truckPerFormanceRptId">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Performance Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">

                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
															<option selected value="hireslipNumber">Hireslip
																Number</option>
															<option value="destination">Destination</option>
															<option value="truckNumber">Truck Number</option>
															<option value="supplierName">Supplier Name</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByHireslipNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Hireslip Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}hireslipNumberId" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByDestination" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Select Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}selectSource" name="selectSource" #selectSource (change)="selectSourceMode(selectSource.value)">
															<option value="mainSource" selected>Main Source</option>
															<option value="individualSource">Individual
																Source</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Depart From</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}departFrom" name="departFroms" ngbDatepicker #departFroms="ngbDatepicker">
                                                        <div class="input-group-append" (click)="departFroms.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Depart To</label> <input id="{{pageId}}departTo" class="form-control" placeholder="yyyy-mm-dd" name="departTos" ngbDatepicker #departTos="ngbDatepicker">
                                                        <div class="input-group-append" (click)="departTos.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="selectSourceMain" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Sources</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                    </div>
                                                </div>
                                            </div>


                                            <div *ngIf="selectSourceIndividual" class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchByTruckNo" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Truck Number <span
																class="mandatoryField_Style"> * </span>
															</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-truck"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}truckNumber" type="text" class="form-control" (selectItem)="clickListnerForTruckNumber($event)" [(ngModel)]="modelTruckNumber" [ngbTypeahead]="searchTruckNumber" [resultFormatter]="formatterTruckNumber" [inputFormatter]="formatterTruckNumber"
                                                                (focus)="focusTruckNumber$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchBySupplierName" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Supplier Name <span
																class="mandatoryField_Style"> * </span>
															</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}supplierName" type="text" class="form-control" (selectItem)="clickListnerForSupplier($event)" [(ngModel)]="modelSupplierName" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName"
                                                                (focus)="focusSupplierNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                        </div>
                        <br>
                        <div class="col-md-12">

                            <div class="box-body">
                                <table datatable id="{{pageId}}truckPerformanceTimeCalcTableid" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckPerfTimeCal" [dtTrigger]="dtTriggerTruckPerfTimeCal">
                                    <thead>
                                        <tr>
                                            <td>From</td>
                                            <td>To</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let truckPerfData of truckPerfTimeCalcDataList ">
                                            <td>{{truckPerfData.fromDays}}</td>
                                            <td>{{truckPerfData.toDays}}</td>
                                            <td>{{truckPerfData.commSlaps}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <button type="submit" class="dt-button" style="margin-left: 42%;" (click)="customPrintTruckPerRpt()" id="{{pageId}}printAllBtn">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                        <div class="box-body">
                            <table datatable id="{{pageId}}truckPerformanceRptTableid" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckPerf" [dtTrigger]="dtTriggerTruckPerf">

                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Truck Number</th>
                                        <th>Truck Type</th>
                                        <th>Gur Wt</th>
                                        <th>Kanta Wt</th>
                                        <th>Destination</th>
                                        <th>Hireslip Number</th>
                                        <th>Supplier Name</th>
                                        <th>Departure Date</th>
                                        <th>Departure Time</th>
                                        <th>Arrival Date</th>
                                        <th>Arrival Time</th>
                                        <th>Hours</th>
                                        <th>Enam</th>
                                        <th>Enam-H</th>
                                        <th>Tracking Id</th>
                                        <th>Mobile Carrier</th>
                                        <th>Tracking Status</th>
                                        <th>Is Under Geofence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let truckPerfData of truckPerfDataList ">
                                        <td>{{ truckPerfData.vehiCompName }}</td>
                                        <td>{{ truckPerfData.vehicleNumber }}</td>
                                        <td>{{ truckPerfData.truckTypeCommon }}</td>
                                        <td>{{ truckPerfData.gurWeight }}</td>
                                        <td>{{ truckPerfData.kantaWeight }}</td>
                                        <td>{{ truckPerfData.toStation }}</td>
                                        <td>{{ truckPerfData.hireslipnumber }}</td>
                                        <td>{{ truckPerfData.brokerName }}</td>
                                        <td>{{ truckPerfData.departuredateStr }}</td>
                                        <td>{{ truckPerfData.departureTime }}</td>
                                        <td>{{ truckPerfData.arrivaldateStr }}</td>
                                        <td>{{ truckPerfData.arrivalTime }}</td>
                                        <td>{{ truckPerfData.hours }}</td>
                                        <td>{{ truckPerfData.driverrewardingAmt }}</td>
                                        <td>{{ truckPerfData.driverrewardingHAmt }}</td>

                                        <td>{{ truckPerfData.trackingFTTripId }}</td>
                                        <td>{{ truckPerfData.trackingFTCarrier }}</td>
                                        <td>{{ truckPerfData.trackingFTConsentStatus }}</td>
                                        <td *ngIf="truckPerfData.trackingFTStatus == false" style='font-size: 13px; color: blue; font-weight: bold;'>
                                            {{ truckPerfData.trackingFTStatus == true ? 'Yes' : 'No'}}</td>
                                        <td *ngIf="truckPerfData.trackingFTStatus == true" style='font-size: 13px; color: green; font-weight: bold;'>
                                            {{ truckPerfData.trackingFTStatus == true ? 'Yes' : 'No'}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>