<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}generateNewEWBForm">
    <!-- <div class="col-lg-6 col-md-6 vl"> -->
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row ">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Transaction Type * </label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                    </span>
                                </div>
                                <select class="custom-select col-12" id="{{pageId}}transactionType" name="transactionType" #transactionType (keyup)="fieldFocus($event, billNo)">
                                    <option value="Regular" selected>Regular</option>
                                    <option value="Bill To - Ship To">Bill To - Ship To</option>
                                    <option value="Bill From - Dispatch From">Bill From - Dispatch From</option>
                                    <option value="Combination of 2 and 3">Combination of 2 and 3</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Doc No*</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                    </span>
                                </div>
                                <input #billNo id="{{pageId}}billNo" (keyup)="fieldFocus($event, billDate)" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Doc Date*</label>
                                <div class="input-group-append" (click)="billDates.toggle()">
                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                    </span>
                                </div>

                                <input #billDate class="form-control" name="billDate" id="{{pageId}}billDate" [(ngModel)]="tdyBillDate" ngbDatepicker (keydown.arrowdown)="billDates.toggle();" (click)="billDates.toggle()" #billDates="ngbDatepicker" (keyup)="fieldFocus($event, vehicleNo)">

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Vehicle No*</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                    </span>
                                </div>
                                <input #vehicleNo id="{{pageId}}vehicleNo" (keyup)="fieldFocus($event, consignorGstNumber)" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-6 v1 p-t-10">
                        <div class="col-sm-12 col-md-12">
                            <h4 style="font-size: 16px;">Consignor Details</h4>
                            &nbsp;
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignor Gst No *</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                        </span>
                                    </div>
                                    <input #consignorGstNumber type="text" id="{{pageId}}consignorGstNumber" (keyup)="validateForGstNo('consignor',$event);" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Trade Name</label>

                                                <span style="font-size: 12px;">{{ consignorTradeName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Legal Name</label>

                                                <span style="font-size: 12px;">{{ consignorLegalName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Address</label>

                                                <span style="font-size: 12px;">{{ consignorAddress1 }},{{
                                                    consignorAddress2
                                                    }}{{
                                                    consignorPincode ? ', ' + consignorPincode : '' }}</span>
                                                <!-- <label style="font-size: 14px;" *ngIf="consignorShowStateCode">State Code :
                                        </label>
                                        <span style="font-size: 12px;" *ngIf="consignorShowStateCode">{{
                                            consignorStateCode }}</span> -->
                                                <!-- <h6 style="font-size: 12px;" *ngIf="consignorShowStateCode">State Code : {{
                                            consignorStateCode }} -->
                                                <!-- </h6> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;" *ngIf="consignorShowStateCode">State
                                                    Code
                                                </label>

                                                <span style="font-size: 12px;" *ngIf="consignorShowStateCode">{{
                                                    consignorStateCode }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 v1">
                        <div class="col-sm-12 col-md-12">
                            <h4 style="font-size: 16px;">Consignee Details</h4>
                            &nbsp;
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignee Gst No *</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}consigneeGstNumber" (keyup)="validateForGstNo('consignee',$event);" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Trade Name</label>

                                                <span style="font-size: 12px;">{{ consigneeTradeName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Legal Name</label>

                                                <span style="font-size: 12px;">{{ consigneeLegalName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;">Address</label>

                                                <span style="font-size: 12px;">{{ consigneeAddress1 }},{{
                                                    consigneeAddress2
                                                    }}{{
                                                    consigneePincode ? ', ' + consigneePincode : '' }}</span>
                                                <!-- <label style="font-size: 14px;" *ngIf="consigneeShowStateCode">State Code :
                                        </label> -->
                                                <!-- <span style="font-size: 12px;" *ngIf="consigneeShowStateCode">{{
                                            consigneeStateCode }}</span> -->
                                                <!-- <h6 style="font-size: 12px;" *ngIf="consigneeShowStateCode">State Code : {{ consigneeStateCode }}
                                        </h6> -->

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-size: 14px;" *ngIf="consigneeShowStateCode">State
                                                    Code
                                                </label>

                                                <span style="font-size: 12px;" *ngIf="consigneeShowStateCode">{{
                                                    consigneeStateCode }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <hr>&nbsp;
    <div class="col-md-12">
        <h6 class="card-title">Consignment Details</h6>&nbsp;
        <div class="row">
            <div class="col-md-1">
                <label style="white-space: nowrap;">Cmdty Name</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                            [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." (keyup)="fieldFocus($event, subCommodity)" />
                    </div>
                </div>
            </div>

            <div class="col-md-1">
                <label style="white-space: nowrap;">Cmdty Desc</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #subCommodity id="{{pageId}}subCommodity" (keyup)="fieldFocus($event, hsnCode)" type="text" class="form-control">
                    </div>
                </div>
            </div>

            <div class="col-md-1">
                <label style="white-space: nowrap;">HSN Code*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #hsnCode id="{{pageId}}hsnCode" (keyup)="fieldFocus($event, units)" type="text" class="form-control">
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Reason*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                        </span>
                    </div>
                    <input #reason id="{{pageId}}reason" (keyup)="fieldFocus($event, billDate)" type="text" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div> -->
            <div class="col-md-1">
                <label style="white-space: nowrap;">Unit</label>
                <div class="form-group">
                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                        <input #units id="{{pageId}}units" name="units" type="text" class="form-control" placeholder="Select Unit" [ngbTypeahead]="unitsSearchTA" (keyup)="fieldFocus($event, noOfArticles)" (focus)="focusUnitsTA$.next($any($event).target.value)" (click)="clickTA(instanceUnits)"
                            #instanceUnits="ngbTypeahead" />
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <label style="white-space: nowrap;">Quantity</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #noOfArticles id="{{pageId}}noOfArticles" type="number" (keyup)="fieldFocus($event, goodsValue)" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <label style="white-space: nowrap;">Goods Val(Tax)*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #goodsValue id="{{pageId}}goodsValue" (keyup)="fieldFocus($event,cgstPerc)" type="number" class="form-control">
                    </div>
                </div>
            </div>
            <!-- <h6 class="card-title">GST Details</h6> -->
            <div class="col-md-1">
                <label style="white-space: nowrap;">CGST*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #cgstPerc type="number" id="{{pageId}}cgstPerc" class="form-control" [(ngModel)]="getCgstPerc" (keyup)="fieldFocus($event, sgstPerc)">
                        <!-- &nbsp;  -->
                        <!-- <input type="number" id="{{pageId}}cgstAmt"
                            class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getCgstAmt" disabled> -->
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <label style="white-space: nowrap;">SGST*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #sgstPerc type="number" id="{{pageId}}sgstPerc" class="form-control" [(ngModel)]="getSgstPerc" (keyup)="fieldFocus($event, igstPerc)">
                        <!-- &nbsp; <input type="number" id="{{pageId}}sgstAmt"
                            class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getSgstAmt" disabled> -->
                    </div>
                </div>
            </div>


            <div class="col-md-1">
                <label style="white-space: nowrap;">IGST*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #igstPerc type="number" id="{{pageId}}igstPerc" class="form-control" [(ngModel)]="getIgstPerc" (keyup)="fieldFocus($event, cessPerc)">
                        <!-- &nbsp; <input type="number" id="{{pageId}}igstAmt"
                            class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getIgstAmt" disabled> -->
                    </div>
                </div>
            </div>

            <div class="col-md-1">
                <label style="white-space: nowrap;">CESS Advol*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #cessPerc type="number" id="{{pageId}}cessPerc" class="form-control" [(ngModel)]="getCessPerc" (keyup)="fieldFocus($event, nonAdvolCessPerc)">
                        <!-- &nbsp;
                        <input type="number" id="{{pageId}}cessAmt" class="form-control"
                            aria-describedby="basic-addon11" [(ngModel)]="getCessAmt" disabled> -->
                    </div>
                </div>
            </div>

            <div class="col-md-1">
                <label style="white-space: nowrap;">CESS Non Advol*</label>
                <div class="form-group">
                    <div class="input-group">
                        <input #nonAdvolCessPerc type="number" id="{{pageId}}nonAdvolCessPerc" class="form-control" [(ngModel)]="getNonAdvolCessPerc"
                        (blur)="grandSum()">
                        <!-- &nbsp;
                        <input type="number" id="{{pageId}}nonAdvolCessAmt" class="form-control"
                            aria-describedby="basic-addon11" [(ngModel)]="getNonAdvolCessAmt" disabled> -->
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn class="btn btn-icon-only yellow" (click)="validateItemDetails();"> <i class="fa fa-plus"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>&nbsp;
    <div class="col-md-12">
        <div class="box-body">
            <table datatable id="{{pageId}}generateNewEwayBillTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsGenerateNewEwayBill" [dtTrigger]="dtTriggerGenerateNewEwayBill">
                <thead>
                    <tr>
                        <th>Commodity Name</th>
                        <th>Commodity Desc</th>
                        <th>HSN</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Goods Val</th>
                        <th>CGST Amt</th>
                        <th>SGST Amt</th>
                        <th>IGST Amt</th>
                        <th>CESS Adol Amt</th>
                        <th>CESS Non Adol Amt</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ewayBillData of ewayBillLists; let i = index">
                        <td>{{ ewayBillData.commodityName }}</td>
                        <td>{{ ewayBillData.commodityDesc }}</td>
                        <td>{{ ewayBillData.hsnCode }}</td>
                        <td>{{ ewayBillData.units }}</td>
                        <td>{{ ewayBillData.noOfArticles }}</td>
                        <td>{{ ewayBillData.goodsValue }}</td>
                        <td>{{ ewayBillData.cgstAmt }}</td>
                        <td>{{ ewayBillData.sgstAmt }}</td>
                        <td>{{ ewayBillData.igstAmt }}</td>
                        <td>{{ ewayBillData.cessAmt }}</td>
                        <td>{{ ewayBillData.nonCessAmt }}</td>
                        <td>
                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDelete(i);">
                                <i title="Remove" class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>&nbsp;
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>Total Tax Amt*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalTaxAmt id="{{pageId}}totalTaxAmt" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>CGST Amt*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalCgstAmt id="{{pageId}}totalCgstAmt" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>SGST Amt*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalSgstAmt id="{{pageId}}totalSgstAmt" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>IGST Amt*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalIgstAmt id="{{pageId}}totalIgstAmt" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>CESS Advol*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalCessAdvol id="{{pageId}}totalCessAdvol" (keyup)="fieldFocus($event,reason)" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>CESS Non Advol*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalNonCessAdvol id="{{pageId}}totalNonCessAdvol" (keyup)="fieldFocus($event,reason)" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>Other Amt</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #otherInvAmount id="{{pageId}}otherInvAmount" (keyup)="fieldFocus($event,reason)" type="number" class="form-control" (blur)="calculateTotal()">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="input-group">
                        <label>Total Inv Amt*</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <input #totalInvAmt id="{{pageId}}totalInvAmt" type="number" class="form-control" aria-describedby="basic-addon11" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>&nbsp;
    <div class="col-md-12 d-flex justify-content-center">
        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}resetBtn" (click)="clearfields();">Reset</button>
        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}genEwayBillBtn" #nextBtn (click)="validateForGenerateEway()">Generate EWB</button>
    </div>
</div>