<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showCashmemoOnlinePaymentDetailsRpt">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control"
                                                        id="{{pageId}}fromDate" placeholder="yyyy-mm-dd"
                                                        name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate"
                                                        class="form-control" placeholder="yyyy-mm-dd" name="toDate"
                                                        ngbDatepicker #toDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Collection Man</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #collectionMan id="{{pageId}}collectionMan"
                                                        name="collectionMan" type="text" class="form-control"
                                                        placeholder="Select Collection Man"
                                                        [(ngModel)]="modelCollectionMan"
                                                        [ngbTypeahead]="collectionManSearchTA"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [resultFormatter]="formatterCollectionMan"
                                                        [inputFormatter]="formatterCollectionMan"
                                                        (focus)="focusCollectionManTA$.next($any($event).target.value)"
                                                        [ngModelOptions]="{standalone: true}" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                            (click)="setCashmemoOnlinePaymentDtls();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                            (click)="clearDetails();">Clear</button>
                    </div>
                </div>

                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}cashmemoOnlinePaymentDetailsTableId"
                                                    class="table table-striped table-bordered row-border hover"
                                                    [dtOptions]="dtOptionsCashmemoOnlinePaymentDetails"
                                                    [dtTrigger]="dtTriggerCashmemoOnlinePaymentDetails">
                                                    <thead>
                                                        <tr>
                                                            <th [hidden]=true>Transaction Id</th>
                                                            <th>Memo No</th>
                                                            <th>Memo Dt(A)</th>
                                                            <th>Assigned Dt(B)</th>
                                                            <th>Collection Dt(C)</th>
                                                            <th>Diff(C-A)</th>
                                                            <th>Memo Amt</th>
                                                            <th>NEFT Amt</th>
                                                            <th>UPI Amt</th>
                                                            <th>Card Amt</th>
                                                            <th>Ref No</th>
                                                            <th>LR No</th>
                                                            <th>Party Name</th>
                                                            <th>Collection Man</th>
                                                            <th>Art</th>
                                                            <th>Invoice Date</th>
                                                            <th>To Pay</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let cashmemoOnlinePaymentData of cashmemoOnlinePaymentDetailLists ">
                                                            <td [hidden]=true>{{ cashmemoOnlinePaymentData.paymentTrxId
                                                                }}</td>
                                                            <!-- <td><button
                                                                    style="padding: 1px 4px; background-color: #ffffff00;"
                                                                    class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
                                                                    (click)="userUpdateDetails(cashmemoOnlinePaymentData)">
                                                                    <i title="Remove" class="fas fa-check-circle"></i>
                                                                </button></td> -->
                                                            <td>{{ cashmemoOnlinePaymentData.memoNumber }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.memoDateStr }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.assignedDateStr }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.completedOnStr }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.days }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.amount }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.neftAmt ==null ? 0.0 :
                                                                cashmemoOnlinePaymentData.neftAmt }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.upiAmt ==null ? 0.0 :
                                                                cashmemoOnlinePaymentData.upiAmt}}
                                                            </td>
                                                            <td>{{ cashmemoOnlinePaymentData.cardAmt ==null ? 0.0 :
                                                                cashmemoOnlinePaymentData.cardAmt}}
                                                            </td>

                                                            <td>{{ cashmemoOnlinePaymentData.chqNumber }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.lrNumber }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.consigneeName }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.collectionMan }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.article }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.sameDate }}</td>
                                                            <td>{{ cashmemoOnlinePaymentData.topay }}</td>

                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>