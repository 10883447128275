import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { serverUrl } from "src/environments/environment";
import { HireSlipDto } from '../dto/HireSlip-dto';
import { UserDataDto } from '../dto/UserData-dto';
import { AboutUsDto } from 'src/app/dto/AboutUs-dto';
import { LatestNewsDto } from 'src/app/dto/LatestNews-dto';
import { OurBranchesDto } from 'src/app/dto/OurBranches-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { FortNightStmtDto } from '../dto/FortNightStmt-dto';
import { AgentDetailsDto } from '../dto/AgentDetails-dto';
import { ResponseDto } from '../dto/Response-dto';
import { RequestDto } from '../dto/Request-dto';

@Injectable({
    providedIn: 'root'
})
export class MasterService {
    //for local
    //    private _baseUrl = "http://localhost:8080/srd/master/v1";
    //    private _baseUrlLogin = "http://localhost:8080/srd/login/v1";
    //for QA
    //    private _baseUrl = "http://54.210.51.40:8080/srd/master/v1";
    //    private _baseUrlLogin = "http://54.210.51.40:8080/srd/login/v1";
    //for live 
    //    private _baseUrl = "http://3.222.201.242:8080/srd/master/v1";
    //    private _baseUrlLogin = "http://3.222.201.242:8080/srd/login/v1";

    private _baseUrl = serverUrl.webMaster;
    private _baseUrlLogin = serverUrl.webLogin;

    constructor(
        private http: HttpClient,
    ) {
    }

    loginAuthenticationService(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrlLogin}/getLoginUserDetails`, userDataDto);
    }

    createConsignee(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/createconsignee`, partyMasterDto);
    }

    getSupplierMasterData(): Observable<SupplierModel[]> {
        return this.http.get<SupplierModel[]>('./assets/data/supplier-master.json');
    }
    getSummaryData(): Observable<any[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');

    }

    createSupplier(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createsupplier`, truckDataDto);
    }

    createDriver(driverDetailsDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createdriver`, driverDetailsDto);
    }

    checkPackName(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/checkpackname`, lrDto);
    }

    createNatureOfPack(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createnatureofpack`, lrDto);
    }

    createVehicle(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createvehicledetails`, truckDataDto);
    }

    createTruck(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createtruckdetails`, truckDataDto);
    }

    createUser(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createuserdetails`, userDataDto);
    }

    createEditCompany(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createeditcompany`, userDataDto);
    }

    createDebitNote(fortNightStmtDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createdebitnote`, fortNightStmtDto);
    }

    createCommonCharges(rateMasterDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createcommoncharges`, rateMasterDto);
    }

    createExpensesHead(hireSlipDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createexpenseshead`, hireSlipDto);
    }

    createCommodity(rateMasterDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createcommodity`, rateMasterDto);
    }

    createConsignmentClaim(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createconsignmentclaim`, lrDto);
    }

    createTimeSettings(hireSlipDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createtimesettings`, hireSlipDto);
    }
    createLorryHireDeductionDetails(hireSlipDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createlorryhiredeductiondetails`, hireSlipDto);
    }

    createBankMaster(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createbankmaster`, userDataDto);
    }

    createBankMasterPdcDetails(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createbankmasterpdcdetails`, lrDto);
    }

    chequeClearenceDetails(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/chequeclearencedetails`, userDataDto);
    }

    deleteSupplier(supplierDetailsDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletesupplier`, supplierDetailsDto);
    }

    deleteDriver(driverDetailsDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletedriver`, driverDetailsDto);
    }

    deleteNatureOfPack(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletenatureofpack`, lrDto);
    }

    deleteTruck(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletetruck`, truckDataDto);
    }

    deleteVehicle(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletevehicle`, truckDataDto);
    }

    deleteUser(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deleteuser`, userDataDto);
    }

    deleteLorryHireDeduction(hireSlipDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletelorryhirededuction`, hireSlipDto);
    }

    deleteCommodity(rateMasterDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletecommodity`, rateMasterDto);
    }


    deleteTime(hireSlipDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletetime`, hireSlipDto);
    }

    deleteBankMaster(userDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deletebankmaster`, userDataDto);
    }

    updateCommodity(rateMasterDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/updatecommodity`, rateMasterDto);
    }

    checkAgentTempoNumber(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/checkagenttemponumber`, truckDataDto);
    }

    createAgentTempo(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/createagenttempodetails`, truckDataDto);
    }
    getAgentTempoDetails(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getAgentTempoDetails`, truckDataDto);
    }

    deleteAgentTempoMaster(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/deleteAgentTempoMaster`, truckDataDto);
    }

    checkTruckExist(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/checkTruckExist`, truckDataDto);
    }

    getLocalGodowns(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLocalGodownsApi`, lrDto);
    }

    setPanTruckMasterDtls(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/setPanTruckMasterDtlsApi`, hireSlipDto);
    }
    addLocalGodowns(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addLocalGodownsApi`, lrDto);
    }

    //added on 23-02-2021
    setTruckOwnerDeclDetails(truckDataDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/setTruckOwnerDeclDetails`, truckDataDto);
    }

    //imran
    getAdminStationList(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrlLogin}/getAdminStationListApi`, userDataDto);
    }

    getExpensesVendorDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getExpensesVendorDetailsApi`, lrDto);
    }

    getExpensesItemDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getExpensesItemDetailsApi`, lrDto);
    }

    addExpensesDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addExpensesDetailsApi`, lrDto);
    }

    getExpensesDetailsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getExpensesDetailsReportApi`, lrDto);
    }

    updateExpensesPaymentDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateExpensesPaymentDetailsApi`, lrDto);
    }

    getAboutUsDetails(aboutUsDto: Object): Observable<AboutUsDto[]> {
        return this.http.post<AboutUsDto[]>(`${this._baseUrl}/getAboutUsDetailsApi`, aboutUsDto);
    }

    addAboutUsDetails(aboutUsDto: Object): Observable<AboutUsDto> {
        return this.http.post<AboutUsDto>(`${this._baseUrl}/addAboutUsDetailsApi`, aboutUsDto);
    }

    getLatestNewsDetails(latestNewsDto: Object): Observable<LatestNewsDto[]> {
        return this.http.post<LatestNewsDto[]>(`${this._baseUrl}/getLatestNewsDetailsApi`, latestNewsDto);
    }

    addLatestNewsDetails(latestNewsDto: Object): Observable<LatestNewsDto> {
        return this.http.post<LatestNewsDto>(`${this._baseUrl}/addLatestNewsDetailsApi`, latestNewsDto);
    }

    deleteLatestNewsDetails(latestNewsDto: Object): Observable<LatestNewsDto> {
        return this.http.post<LatestNewsDto>(`${this._baseUrl}/deleteLatestNewsDetailsApi`, latestNewsDto);
    }

    getOurBranchDetails(ourBranchesDto: Object): Observable<OurBranchesDto[]> {
        return this.http.post<OurBranchesDto[]>(`${this._baseUrl}/getOurBranchDetailsApi`, ourBranchesDto);
    }

    addOurBranchDetails(ourBranchesDto: Object): Observable<OurBranchesDto> {
        return this.http.post<OurBranchesDto>(`${this._baseUrl}/addOurBranchDetailsApi`, ourBranchesDto);
    }

    deleteOurBranchDetails(ourBranchesDto: Object): Observable<OurBranchesDto> {
        return this.http.post<OurBranchesDto>(`${this._baseUrl}/deleteOurBranchDetailsApi`, ourBranchesDto);
    }

    getPoint2PointSCDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPoint2PointSCDetailsApi`, lrDto);
    }

    setPoint2PointSCDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/setPoint2PointSCDetailsApi`, lrDto);
    }

    deletePoint2PointSCDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deletePoint2PointSCDetailsApi`, lrDto);
    }

    getFOVRatesForCommodities(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getFOVRatesForCommoditiesApi`, rateMasterDto);
    }

    getFOVRatesForConsignor(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getFOVRatesForConsignorApi`, rateMasterDto);
    }

    addUpdateFOVExemption(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/addUpdateFOVExemptionApi`, rateMasterDto);
    }

    deleteFOVExemption(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteFOVExemptionApi`, rateMasterDto);
    }

    addTripETADetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/addTripETADetailsApi`, hireSlipDto);
    }

    deleteTripETADetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/deleteTripETADetailsApi`, hireSlipDto);
    }

    getTripETADetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTripETADetailsApi`, hireSlipDto);
    }

    salesforceErpToCrm(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/salesforceErpToCrmApi`, lrDto);
    }

    getDebitNoteDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDebitNoteDetailsApi`, fortNightStmtDto);
    }

    addDebitNoteDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/addDebitNoteDetailsApi`, fortNightStmtDto);
    }
    setDestAgentBalAmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/setDestAgentBalAmtApi`, fortNightStmtDto);
    }

    updateDestStmtBalAmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/updateDestStmtBalAmtApi`, fortNightStmtDto);
    }

    changePassword(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrlLogin}/editProfileApi`, userDataDto);
    }

    getFixedByDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getFixedByOfConsigneeRateMasterApi`, lrDto);
    }

    getAgentNamesGrouping(agentDetailsDto: Object): Observable<AgentDetailsDto[]> {
        return this.http.post<AgentDetailsDto[]>(`${this._baseUrl}/getAgentNamesGroupingLevelApi`, agentDetailsDto);
    }

    getAgentsDetails(agentDetailsDto: Object): Observable<AgentDetailsDto[]> {
        return this.http.post<AgentDetailsDto[]>(`${this._baseUrl}/getAgentsDetailsApi`, agentDetailsDto);
    }

    setupCflSlabsDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/setupCflSlabsDetailsApi`, lrDto);
    }

    getCFTSlabOfSepcificStation(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getCFTSlabOfSepcificStationApi`, lrDto);
    }

    getCFTSlabDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCFTSlabDetailsApi`, lrDto);
    }

    setupStaffDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/setupStaffDetailsApi`, lrDto);
    }

    getStaffDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStaffDetailsApi`, lrDto);
    }

    setupInchargeDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/setupInchargeDetailsApi`, lrDto);
    }

    getInchargeDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInchargeDetailsApi`, lrDto);
    }

    setupLoaderDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/setupLoaderDetailsApi`, lrDto);
    }

    getLoaderDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLoaderDetailsApi`, lrDto);
    }

    addUpdateCftExemptionDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/addUpdateCftExemptionDetailsApi`, rateMasterDto);
    }

    getCftExemptionDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCftExemptionDetailsApi`, rateMasterDto);
    }

    deleteCftExemptionDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteCftExemptionDetailsApi`, rateMasterDto);
    }

    getTruckBankDetails(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckBankDetailsApi`, truckDataDto);
    }

    getActiveCFTSlabDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getActiveCFTSlabDetailsApi`, lrDto);
    }

    getActiveCFTSlabDetailsSpecificParty(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getActiveCFTSlabDetailsSpecificPartyApi`, lrDto);
    }

    saveSpecificConsigneeDetails(partyMasterDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/saveSpecificConsigneeDetailsApi`, partyMasterDto);
    }

    deleteSpecificConsigneeDetails(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/deleteSpecificConsigneeDetailsApi`, partyMasterDto);
    }

    saveTdsDetailsEntryDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveTdsDetailsEntryDetailsApi`, lrDto);
    }

    checkCertNo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/checkCertNoApi`, lrDto);
    }

    saveAreaDetail(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveAreaDetailApi`, lrDto);
    }

    updateStockNotification(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/updateStockNotificationApi`, userDataDto);
    }

    getStockNotificationRecentStopDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getStockNotificationRecentStopDetailsApi`, userDataDto);
    }

    getStockNotificationStopDaysDetails(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/getStockNotificationStopDaysDetailsApi`, userDataDto);
    }

    validateStockArrivalNotificationSend(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/validateStockArrivalNotificationSendApi`, userDataDto);
    }

    lrDeleteMasterPwdSetup(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/lrDeleteMasterPwdSetupApi`, requestDto);
    }

    validateLRDeleteMasterPwd(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateLRDeleteMasterPwdApi`, requestDto);
    }

    getPartyWiseCFTDetailsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPartyWiseCFTDetailsServiceApi`, lrDto);
    }

    saveLrBkgDateCustomChangeDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/saveLrBkgDateCustomChangeDetailsApi`, lrDto);
    }

    getLrBkgDateCustomChangeDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrBkgDateCustomChangeDetailsApi`, lrDto);
    }

    saveLrBkgDateCustomChangeForHolidayDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/saveLrBkgDateCustomChangeForHolidayDetailsApi`, lrDto);
    }

    getLrBkgDateCustomChangeForHolidayDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrBkgDateCustomChangeForHolidayDetailsApi`, lrDto);
    }

    updateFtAutoReachDetails(requestDto: Object): Observable<RequestDto> {
        return this.http.post<RequestDto>(`${this._baseUrl}/updateFtAutoReachDetailsApi`, requestDto);
    }

    updateConsigneePerfMonitor(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/updateConsigneePerfMonitorApi`, partyMasterDto);
    }

    updateOnCommonCharges(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateOnCommonChargesApi`, rateMasterDto);
    }
    savePerKgRateSetupDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/savePerKgRateSetupDetailsApi`, rateMasterDto);
    }

    getPerKgRateSetupDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getPerKgRateSetupDetailsApi`, rateMasterDto);
    }

    deletePerKgRateSetupDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deletePerKgRateSetupDetailsApi`, rateMasterDto);
    }

    saveCommodityKgLimitDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/saveCommodityKgLimitDetailsApi`, rateMasterDto);
    }

    getCommodityKgLimitDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommodityKgLimitDetailsApi`, rateMasterDto);
    }

    deleteCommodityKgLimitDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteCommodityKgLimitDetailsApi`, rateMasterDto);
    }

    checkDuplicateEntryForCommodityKgLimitDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/checkDuplicateEntryForCommodityKgLimitDetailsApi`, rateMasterDto);
    }

    saveCommodityBookingBlockDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/saveCommodityBookingBlockDetailsApi`, rateMasterDto);
    }

    getCommodityBookingBlockDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommodityBookingBlockDetailsApi`, rateMasterDto);
    }

    enableBookingBlockForCommodity(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/enableBookingBlockForCommodityApi`, rateMasterDto);
    }

    getTwoPointDeliveryLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTwoPointDeliveryLrDetailsApi`, lrDto);
    }

    getLrDetailsForTwoPointDelivery(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrDetailsForTwoPointDeliveryApi`, lrDto);
    }

    saveAreaDetailsWithHamaliCharges(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveAreaDetailsWithHamaliChargesApi`, lrDto);
    }

    getSourceAndDestinationDetails(agentDetailsDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSourceAndDestinationDetailsApi`, agentDetailsDto);
    }
}