export class CashMemoDto {
    commodityName: string;
    percentage: number;
    fromDate: Date;
    toDate: Date;
    id: number;
    consigneeName: string;

    memoNumber: string;
    gcNoteNumber: string;
    inRupees: string;
    consignor: string;
    consignee: string;
    invoiceNumber: string;
    collectionMan: string;
    area: string;
    godown: string;
    column1: string;
    column2: string;
    column3: string;
    lrNumber: string;
    status: string;
    memoType: string;
    mode: string;
    station: string;
    financialYear: string;
    source: string;
    destination: string;
    enteredBy: string;

    amount: number;
    lorryFreight: number;
    localCharge: number;
    hamaligd: number;
    receiptCharges: number;
    serviceCharges: number;
    less: number;
    totalRupees: number;
    serviceTax: number;
    grandTotal: number;
    paid: number;
    column4: number;
    column5: number;
    weight: number;
    hamaliDD: number;
    demurrage: number;
    discount: number;
    serTaxAmt: number;
    subTotal: number;
    gcChg: number;

    bookingDate: Date;
    invoiceDate: Date;
    enteredDate: Date;

    capacity: number;
    actual: number;
    bulkyAndSemi: number;
    remMemos: number;
    fromdate: string;
    todate: string;
    deliveryType: string;
    deliveryMode: string;
    memoDate: Date;
    memoDateStr: string;
    size: number;
    cashMemoName: string;
    mainStation: string;
    stationCode: string;
    topay: number;
    numberofmemos: number;
    articles: number;
    actualWeight: number;
    chargedWeight: number;
    date: string;
    state: string;
    dateInDate: Date;
    agentName: string;
    capacityGCIn: number;
    amountGCIn: number;
    collManGCIn: string;
    enterDate: string;
    office: string;
    sameDate: string;
    tillDateMode: string;
    memoBack: number;
    memoBackAmt: number;
    consignorId: number;
    consigneeId: number;
    reportMode: string;
    article: number;
    memoCheck: boolean;
    //   List<String> list;
    cashAmt: number;
    chequeAmt: number;
    pdcAmt: number;
    //   List<Integer> listOfConsigneId;
    assignedDate: Date;
    unloadingHamali: number;
    withLetterHead: boolean;
    days: number;
    remarks: string;
    returnOn: Date;
    rsendOn: Date;
    lrStatus: string;
    goodsReturnStatus: string;
    neftAmt: number;
    statementNo: string;
    gstNumber: string;
    companyId: string;
    tpStatus: string;
    tds: number;
    claim: number;
    chqNumber: string;
    lessDesc: string[];
    completedOn: Date;
    completedOnStr: string;
    amtToBeCollected: number;
    delhiLess: number;
    mumbaiLess: number;
    gujaratLess: number;
    punjabLess: number;
    totalLess: number;
    totalChqAmt: number;
    totalNeftAmt: number;
    totalUpiAmt: number;
    totalCashAmt: number;
    balanceAmt: number;

    totalMemos: number;
    totalArticles: number;

    pendingMemoTotal: number;
    assignedMemoTotal: number;
    toPartyMemoTotal: number;

    pendingMemos: number;
    assignedMemos: number;
    toPartyMemos: number;

    nfof2000Count: number;
    nfof1000Count: number;
    nfof500Count: number;
    nfof200Count: number;
    nfof100Count: number;
    nfof50Count: number;
    nfof20Count: number;
    nfof10Count: number;
    nfof5Count: number;
    nfof2Count: number;
    nfof1Count: number;

    nfOF2000: number;
    nfOF1000: number;
    nfOF500: number;
    nfOF200: number;
    nfOF100: number;
    nfOF50: number;
    nfOF20: number;
    nfOF10: number;
    nfOF5: number;
    nfOF2: number;
    nfOF1: number;
    nfOFTotalDenomination: number;

    imgURLStmCpy: string;
    imgUploadedStmCpy: boolean;
    tillDate: string;

    hamaliValidation: string;
    hamaliDate: Date;

    BillingMemos: number;
    BillingTotal: number;
    transToCollMemos: number;
    transToCollTotal: number;
    transToCollFrmMadMemos: number;
    transToCollFrmMadTotal: number;
    transToCollFrmBinnyMemos: number;
    transToCollFrmBinnyTotal: number;
    collManStockMemos: number;
    collManStockTotal: number;
    srdStockMemos: number;
    srdStockTotal: number;

    imgURLStmCpy2: string;
    imgUploadedStmCpy2;
    imgURLStmCpy3: string;
    imgUploadedStmCpy3;

    cmstockDate: Date;
    neftRefNo: string;
    lessType: string;
    contactNumber: string;

    stmtDate: Date;

    lessDescription: string;
    lessAmt: number;
    bankName: string;
    //consigneeWiseMemo: string[];
    consigneeWiseMemo: CashMemoDto[];
    list: string[];
    list2: string[];
    listLessDetails: string[];
    listNEFTDetails: string[];
    listUpiDetails: string[];
    listChqDetails: string[];
    consigneeWiseMode: string;
    consigneeWiseBalanceDtails: string[];
    userName: string;
    stationType: string;
    columnsCount: number;
    columnName: string;
    columnWidth: number;
    columnValue1: string;
    columnValue2: string;
    columnValue3: string;
    columnValue4: string;
    columnValue5: string;
    columnValue6: string;
    columnValue7: string;
    columnValue8: string;
    columnValue9: string;
    columnValue10: string;
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    printHeadingName: string;
    printHeadingValue: string;
    memoTimePeriod: number;
    specCollectionFlow: boolean;
    tripsheetHamali: number;
    lrDDAmt: number;
    showAllLRDDLrs: boolean;
    lcChg: number;
    bcChg: number;
    others: number;
    topartyName: string;
    tpStmtDate: Date;
    tpStmtDateStr: string;
    upiAmt: number;
    tpStmtNo: string;
    tpStmtFromDate: string;
    tpStmtToDate: string;
    toPartyName: string;
    toPartyAddress: string;
    toPartyGst: string;
    address: string;
    srcLess: number;
    cmAtMemoGeneration: string;
    deliverygodown: string;
    collectiontype: string;
    contactPerson: string;
    leftToPartyEnable: string;
    daysDiff: number;
    tripId: number;
    leftToPartyBlockEnabled: boolean;
    destLeftToPartyAckSubmitPeriod: number;
    tpStmtAckDate: Date;
    tpStmtAckDateStr: string;
    totalDebitAcAmt: number;
    totalUPIAmt: number;
    listDebitAccDetails: string[];
    listUPIDetails: string[];
    paymenttrxId:string;
}