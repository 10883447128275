import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { DatePipe } from '@angular/common';
import { IssueBalanceComponent } from 'src/app/report/lr-report/issue-balance/issue-balance.component';
import { LrIssueReportComponent } from 'src/app/report/lr-report/lr-issue-report/lr-issue-report.component';
import { ChallanIssueComponent } from 'src/app/report/lr-report/challan-issue/challan-issue.component';
import { ShortAndExtraComponent } from 'src/app/report/lr-report/short-and-extra/short-and-extra.component';
import { LrDispatchBookingReportComponent } from 'src/app/report/lr-report/lr-dispatch-booking-report/lr-dispatch-booking-report.component';
import { AutomatedSearchComponent } from 'src/app/report/lr-report/automated-search/automated-search.component';
import { ShortAndExtraConflictComponent } from 'src/app/report/lr-report/short-and-extra-conflict/short-and-extra-conflict.component';
import { LrHistoryReportComponent } from 'src/app/report/lr-report/lr-history-report/lr-history-report.component';
import { LorryHireStatementComponent } from 'src/app/report/lr-report/lorry-hire-statement/lorry-hire-statement.component';
import { DelayedESugamComponent } from 'src/app/report/lr-report/delayed-e-sugam/delayed-e-sugam.component';
import { LorryHireBalanceComponent } from 'src/app/report/lr-report/lorry-hire-balance/lorry-hire-balance.component';
import { LrPerformanceReportComponent } from 'src/app/report/lr-report/lr-performance-report/lr-performance-report.component';
import { LrRptComponent } from 'src/app/report/lr-report/lr-rpt/lr-rpt.component';
import { GcNotReceivedReportComponent } from 'src/app/report/lr-report/gc-not-received-report/gc-not-received-report.component';
import { MaterialModule } from "src/app/material.module";
import { LorryHireBalanceReportPrintComponent } from 'src/app/report/lr-report/lorry-hire-balance-rpt-print/lorry-hire-balance-rpt-print.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { EntryModule } from 'src/app/lr/entry/entry.module';
import { EWayBillDateValidationExtensionRptComponent } from 'src/app/report/lr-report/ewaybilldate-validation-extension-rpt/ewaybilldate-validation-extension-rpt.component';
import { LRIssueDetailsBalanceRptComponent } from 'src/app/report/lr-report/lr-issue-details-balance-rpt/lr-issue-details-balance-rpt.component';
import { LrShortAndExtraConsolidateRptComponent } from './lr-short-and-extra-consolidate-rpt/lr-short-and-extra-consolidate-rpt.component';
import { LrDwsWeightReportComponent } from './lr-dws-wgt-rpt/lr-dws-wgt-rpt.component';
import { EnquiryModule } from 'src/app/lr/enquiry/enquiry.module';
import { SrcLrRatecheckRptComponent } from './src-lr-ratecheck-rpt/src-lr-ratecheck-rpt.component';
import { LrShortAndExtraConsolidateRptV2Component } from './lr-short-and-extra-consolidate-rptV2/lr-short-and-extra-consolidate-rptV2.component';
import { EstimateEnquiryReportComponent } from './estimate-enquiry-report/estimate-enquiry-report.component';
@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(LrReportRoutes),
        FormsModule,
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, CashmemoReportModule,
        EntryModule, EnquiryModule],
    declarations: [
        IssueBalanceComponent,
        LrIssueReportComponent,
        ChallanIssueComponent,
        ShortAndExtraComponent,
        LrDispatchBookingReportComponent,
        AutomatedSearchComponent,
        ShortAndExtraConflictComponent,
        LrHistoryReportComponent,
        LorryHireStatementComponent,
        DelayedESugamComponent,
        LorryHireBalanceComponent,
        LrPerformanceReportComponent,
        LrRptComponent,
        GcNotReceivedReportComponent,
        LorryHireBalanceReportPrintComponent,
        EWayBillDateValidationExtensionRptComponent,
        LRIssueDetailsBalanceRptComponent,
        LrShortAndExtraConsolidateRptComponent,
        LrDwsWeightReportComponent,
        SrcLrRatecheckRptComponent,
        LrShortAndExtraConsolidateRptV2Component,
        EstimateEnquiryReportComponent],
    entryComponents: [LRIssueDetailsBalanceRptComponent],
    providers: [
        DatePipe
    ],
    exports: [GcNotReceivedReportComponent, LorryHireBalanceReportPrintComponent]
})
export class LrReportModule { }