import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-cancel-ewb',
    templateUrl: './cancel-ewb.component.html',
    styleUrls: ['./cancel-ewb.component.css']
})

export class CancelEWBComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "clEWB";
    isLoggedIn = true;
    requestDto: RequestDto = new RequestDto();
    enteredEwayBillNo: any;
    showSpinnerForAction = false;
    pdfUrl: SafeResourceUrl | null = null;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    cancelReasonOptions = [
        { id: 1, reason: '1-Duplicate' },
        { id: 2, reason: '2-Order Cancelled' },
        { id: 3, reason: '3-Data Entry Mistake' },
        { id: 4, reason: '4-Others' }
    ];

    public modelCancelReason: any;
    @ViewChild('instanceCancelReason') instanceCancelReason: NgbTypeahead;
    cancelReasonTA: Array<any> = [];
    focusCancelReasonTA$ = new Subject<string>();

    cancelReasonSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCancelReasonTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.cancelReasonTA
                : this.cancelReasonTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    };

    constructor(private router: Router, private sanitizer: DomSanitizer,
        private ewaybillService: EwaybillService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.setCancelReasonDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    validateForEWayBillNo() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined || this.enteredEwayBillNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter E-Way Bill Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.getEwayBillNoDetails();
        }
    }

    getEwayBillNoDetails() {
        this.requestDto = new RequestDto();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log(this.enteredEwayBillNo);
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    this.pdfUrl = '';
                    this.openPdf(this.enteredEwayBillNo, response.token1, 4);

                } else {
                    swal("Failed", "Failed to generate the token for View Eway Bill Details, please contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to generate the token for View Eway bill Details, please contact Admin!", "error");
            }, () => console.log('done');
    }

    openPdf(ewayBillNo: string, token: string, type: number) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": type };

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, token).subscribe({
            next: (data) => {
                if (!data || data.size === 0) {
                    swal("Warning", "E-Way Bill not found. Please check the number and try again.", "Not Found");
                    return;
                } else {
                    const blob = new Blob([data], { type: 'application/pdf' });
                    const objectUrl = URL.createObjectURL(blob);

                    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            error: (err) => {
                this.showSpinnerForAction = false;
                console.error('Error opening PDF:', err);
                swal("Error", "Failed to open the PDF. Please try again.", "error");
            }
        });
    }

    clearField() {
        $("#" + this.pageId + "ewayBillNo").val('');
        this.pdfUrl = '';
        this.modelCancelReason = null;
    }

    validateEWBCancel() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined ||
            this.enteredEwayBillNo == '' || this.modelCancelReason == null ||
            this.modelCancelReason == '' || this.modelCancelReason == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please fill all the fields to proceed!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            swal({
                title: "Confirm Cancel",
                text: "Sure U Want to Cancel the EWB?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.confirmEWBCancel();
                }
            });
        }
    }

    confirmEWBCancel() {
        this.requestDto = new RequestDto();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log("modelCancelReason>>>" + this.modelCancelReason);
        console.log($("#" + this.pageId + "cancelReason").val());
        this.requestDto.param1 = this.enteredEwayBillNo;
        this.requestDto.param2 = this.modelCancelReason.split("-")[0];
        this.requestDto.param4 = this.modelCancelReason.split("-")[1];
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.cancelEWBService(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Success", "Eway Bill cancelled successfully", "success");
                    this.clearField();
                } else {
                    swal("Failed", response.message, "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to Cancel Eway Bill, please contact Admin!", "error");
            }, () => console.log('done');
    }


    setCancelReasonDetails() {
        for (let i = 0; i < this.cancelReasonOptions.length; i++) {
            this.cancelReasonTA.push(this.cancelReasonOptions[i].reason);
        }
    }


}
