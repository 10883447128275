import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { ReportService } from "src/app/dataService/report-service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Subject, Subscription, merge, Observable } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { QueryList } from "@angular/core";
import { ViewChildren } from "@angular/core";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from "src/app/dataService/memo-report";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-cashmemo-details",
  templateUrl: "./cashmemo-details.component.html",
  styleUrls: ["./cashmemo-details.component.css"],
})
export class CashmemoDetailsComponent implements OnInit {
  gettingDataFrmServiceFrCashmemoDetailTable: any;
  gettingDataFrmServiceFrConsolidatedMemoRptTable: any;
  gettingDataFrmServiceFrConsolidatedMemoRptSrcTable: any;
  gettingDataFrmServiceFrAllTransactionTable: any;

  cashmemoDetailDataList: any;
  consolidatedMemoRptDataList: any;
  consolidatedMemoRptSrcDataList: any;
  allTransactionDataList: any;
  defaultCashmemoRptDataList: any;

  onDestroyUnsubscribtionCashmemoDetail: Subscription;
  onDestroyUnsubscribtionConsolidatedMemoRpt: Subscription;
  onDestroyUnsubscribtionConsolidatedMemoRptSrc: Subscription;
  onDestroyUnsubscribtionAllTransaction: Subscription;
  onDestroyUnsubscribtionDefaultCashmemoRpt: Subscription;
  pageId = "chsdc";
  //summaryTable:any;
  //for datepicker starts
  model: NgbDateStruct;
  model2;
  //for datepicker ends

  loadingIndicator = true;

  //for datePicker starts
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  closeResult: string;
  //for datepicker ends

  //@ViewChildren(DataTableDirective)
  //dtElements: QueryList<DataTableDirective>;

  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  //@ViewChild(DialogComponent) dialogComponent: DialogComponent;
  an;

  viewModel: any;
  //supList: SupplierModel[] = [];
  //

  dtTriggerConsolidateMemoRpt: Subject<any> = new Subject();
  dtTriggerConsolidatedMemoSourceRpt: Subject<any> = new Subject();
  dtTriggerConsolidatedMemoMainSourceWiseRpt: Subject<any> = new Subject();
  dtTriggerAllTransaction: Subject<any> = new Subject();
  dtTriggerDefaultCashMemoDetailsRpt: Subject<any> = new Subject();

  //

  // @ViewChild('dataTable') table: ElementRef;
  dataTable: any;

  dtOptionsConsolidateMemoRpt: any;
  dtOptionConsolidatedMemoSourceRpt: any;
  dtOptionsConsolidatedMemoMainSourceWiseRpt: any;
  dtOptionAllTransaction: any;
  dtOptionDefaultCashMemoDetailsRpt: any;
  //firstDtOptions: DataTables.Settings = {};

  //secondDtOptions: DataTables.Settings = {};

  isLoggedIn = true;
  userDataDtoReturnSession: any;
  address: any;

  collectionManOptions: UserDataDto[];
  userDtoCollectionMan: UserDataDto = new UserDataDto();
  userDtoCollectionManAll: UserDataDto = new UserDataDto();
  public modelCollectionMan: any;
  collectionManTA: Array<UserDataDto> = [];
  focusCollectionManTA$ = new Subject<string>();
  searchCollectionMan = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusCollectionManTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.collectionManTA
          : this.collectionManTA.filter(
              (v) =>
                v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 200)
      )
    );
  };
  formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;

  controlOtherChargeArea = new FormControl();
  deliveryAreaOptions: LRDto[];
  lrDto: LRDto = new LRDto();

  public modelOtherDeliveryArea: any;
  deliveryAreaTA: Array<LRDto> = [];
  focusDeliveryAreaTA$ = new Subject<string>();
  searchDeliveryArea = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDeliveryAreaTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.deliveryAreaTA
          : this.deliveryAreaTA.filter((v) => v.destination)
        ).slice(0, 200)
      )
    );
  };
  formatterDeliveryArea = (x: { destination: string }) => x.destination;

  controlDestination = new FormControl();
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.destinationTA
          : this.destinationTA.filter((v) => v.destination)
        ).slice(0, 200)
      )
    );
  };
  formatterDestination = (x: { destination: string }) => x.destination;

  controlLocalDestination = new FormControl();
  localDestinationOptions: LRDto[];
  lrDtoLocalDestination: LRDto = new LRDto();
  lrDtoLocalDestinationAllOption: LRDto = new LRDto();
  public modelLocalDestination: any;
  localDestinationTA: Array<LRDto> = [];
  focusLocalDestinationTA$ = new Subject<string>();
  searchLocalDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusLocalDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.localDestinationTA
          : this.localDestinationTA.filter((v) => v.destination)
        ).slice(0, 200)
      )
    );
  };
  formatterLocalDestination = (x: { destination: string }) => x.destination;

  validateCollectionManName: any = null;
  validateArea: any = null;
  validateFromDate: any = null;
  validateToDate: any = null;
  validateMemoType: any = null;
  validateDestination: any = null;
  validateLocalDestination: any = null;
  validateCheatCodeId: any = null;
  validateCheckboxEnable: any = false;
  validateCheckBoxAll: any = null;

  modeString: string = null;

  // Check Box
  //checkBoxCollectionMan:any=false;
  //checkBoxArea:any=false;

  //
  hideAndShowToDateField = false;
  hideAndShowFromDateField = false;

  // Drop Down
  searchByCollectionManWise = false;
  searchByAreaWise = false;
  hideAndShowDestination = false;
  hideAndShowLocalDestination = false;
  hideAndShowMemoType = false;

  // Check Box Down
  hideAndShowConsolidatedMemoRpt = false;
  hideAndShowConsolidateMemoRptSrc = false;
  hideAndShowConsolidateMemoRptMainSrc = false;
  hideAndShowAllTransaction = false;
  hideAndShowAreaCollectionManCheckBox = false;

  // Data table
  hideAndShowTableCashMemoDetails = false;
  hideAndShowTableConsolidatedMemoRpt = false;
  hideAndShowTableConsolidatedMemoRptSrc = false;
  hideAndShowTableAllTransaction = false;
  hideAndShowTableDefaultCashMemoDetailsRpt = false;

  memoDto: CashMemoDto = new CashMemoDto();
  memoDtoForBogus: CashMemoDto = new CashMemoDto();

  //hideshowConsolidateRptUnldgChgAndGD=false;
  hideshowConsolidateRptDD = false;
  hideshowTripsheetHamaliAndLRDD = false;

  rightsToShowDestination = "Show Destination";
  rightsToShowUnldChgAndGD = "Show Unld Chg And GD";
  rightsToShowDD = "Show DD";
  rghtsForRole = "Super Administrator";

  hideAndShowEnableField = false;
  fromDatesModal: any = null;
  toDatesModal: any = null;
  validateForModeAreaCheckBox: any = null;
  validateForModeCollectionManCheckBox: any = null;

  showSpinnerForAction: boolean = false;
  validatePrintMemoTypeId: any = null;

  showAllLRDDLrs: boolean = false;
  showAllLRDDLrsCheckbox: boolean = false;

  //For Custom Print
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingV3: any;
  cashMemoDtoForCustomPrintListHeadingV4: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;
  viewCustomPrintV1: boolean;

  totArtSummary: number;
  discSummary: number;
  hamGdSummary: number;
  amtSummary: number;
  actWghtSummary: number;
  chgWghtSummary: number;
  memoSummary: number;
  toPaySummary: number;
  paidSummary: number;
  demGdSummary: number;
  rcChgSummary: number;
  scChgSummary: number;
  subTotSummary: number;
  gTotSummary: number;
  ddSummary: number;
  unldgSummary: number;
  othersSummmary: number;
  lrDtoPod: LRDto = new LRDto();
  showHyperLink = false;
  loadingHamaliOptionFlow: boolean = false;
  columnLblGDLdgHamali: string = "GD";

  constructor(
    private router: Router,
    private masterReadService: MasterReadService,
    private memoReport: MemoReport,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.address =
        this.userDataDtoReturnSession.addressId == null
          ? ""
          : this.userDataDtoReturnSession.addressId;

      this.getCollectionManDetailsList(
        this.userDataDtoReturnSession.mainStation
      );
      this.getBranchAreaDetailsList(this.userDataDtoReturnSession.mainStation);
      this.getDestinationDetails();
      this.getLocalDestinationDetails(
        this.userDataDtoReturnSession.mainStation
      );

      //console.log(this.userDataDtoReturnSession.office);
      //console.log(this.userDataDtoReturnSession.role);

      if (
        this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Konditope"
      ) {
        this.hideAndShowLocalDestination = true;
      } else {
        this.hideAndShowLocalDestination = false;
      }

      //if (this.rightsToShowDestination != null &&
      //this.rightsToShowDestination == "Show Destination") {
      if (
        this.userDataDtoReturnSession.role != null &&
        this.userDataDtoReturnSession.role == "Super Administrator"
      ) {
        this.hideAndShowDestination = true;
      } else {
        this.hideAndShowDestination = false;
      }
      this.hideAndShowMemoType = true;
      this.hideAndShowConsolidatedMemoRpt = true;
      this.hideAndShowConsolidateMemoRptSrc = true;
      this.hideAndShowConsolidateMemoRptMainSrc = true;
      this.hideAndShowAllTransaction = true;

      //this.hideAndShowTableAllTransaction = true;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

      //if(this.rightsToShowUnldChgAndGD != null &&
      //this.rightsToShowUnldChgAndGD == "Show Unld Chg And GD"){
      /*if(this.userDataDtoReturnSession.mainStation != null &&
                this.userDataDtoReturnSession.mainStation == "Chennai"){
                this.hideshowConsolidateRptUnldgChgAndGD = true;
            }else{
                this.hideshowConsolidateRptUnldgChgAndGD = false;
            }*/

      //if(this.rightsToShowDD !=null &&this.rightsToShowDD !="Show DD"){

      if (
        this.userDataDtoReturnSession.mainStation != null &&
        this.userDataDtoReturnSession.mainStation != "Chennai"
      ) {
        this.hideshowConsolidateRptDD = true;
        this.hideshowTripsheetHamaliAndLRDD = false;
      } else {
        this.hideshowConsolidateRptDD = false;
        this.hideshowTripsheetHamaliAndLRDD = true;
      }
      //enabled for all, will see the logic later@Asrar @25/06/2021
      this.hideshowConsolidateRptDD = true;

      if (
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup != null &&
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup
      ) {
        this.loadingHamaliOptionFlow = true;
        this.columnLblGDLdgHamali = "Ldg Chg";
      } else {
        this.columnLblGDLdgHamali = "GD";
      }
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngOnInit(): void {
    var companyAddressDetls = this.address;
    this.validatePrintMemoTypeId = $("#" + this.pageId + "memoTypeId").val();
    var memoType = this.validatePrintMemoTypeId;
    //first datatable starts
    (this.dtOptionsConsolidateMemoRpt = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 380,
      scrollCollapse: true,
      paging: false,
      info: true,
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
            ? i
            : 0;
        };
        // computing column Total of the complete result
        var totalMemo = api
          .column(1)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var totalArticle = api
          .column(2)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actualWgt = api
          .column(3)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var chargeWgt = api
          .column(4)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var toPayAmt = api
          .column(5)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paidAmt = api
          .column(6)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var ddAmt = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var unloadingHamaliAmt = api
          .column(8)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var hamaligdAmt = api
          .column(9)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var demAmt = api
          .column(10)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var rcAmt = api
          .column(11)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var scAmt = api
          .column(12)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var otherChgAmt = api
          .column(13)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var discAmt = api
          .column(14)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var subTotAmt = api
          .column(15)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var grandTotAmt = api
          .column(16)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        // Update footer by showing the total with the reference of the column index
        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(1).footer()).html(totalMemo);
        $(api.column(2).footer()).html(totalArticle);
        $(api.column(3).footer()).html(actualWgt);
        $(api.column(4).footer()).html(chargeWgt);
        $(api.column(5).footer()).html(toPayAmt);
        $(api.column(6).footer()).html(paidAmt);
        $(api.column(7).footer()).html(ddAmt);
        $(api.column(8).footer()).html(unloadingHamaliAmt);
        $(api.column(9).footer()).html(hamaligdAmt);
        $(api.column(10).footer()).html(demAmt);
        $(api.column(11).footer()).html(rcAmt);
        $(api.column(12).footer()).html(scAmt);
        $(api.column(13).footer()).html(otherChgAmt);
        $(api.column(14).footer()).html(discAmt);
        $(api.column(15).footer()).html(subTotAmt);
        $(api.column(16).footer()).html(grandTotAmt);
      },
    }),
      //first datatable ends
      //second datatable starts
      (this.dtOptionConsolidatedMemoSourceRpt = {
        dom: "Bfrtip",
        //destroy: true,
        buttons: [
          {
            extend: "print",
            text: '<i class="fas fa-print"> Print</i>',
            titleAttr: "Print",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("th, td")
                .css("font-family", "Arial, Helvetica, sans-serif")
                .css("font-size", "12px")
                .css("text-align", "center");
            },
            footer: true,
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 13, 14, 15, 16],
            },
            messageTop: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                "<u>Consolidated Memo Report Source Wise</u><br><br>" +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                "" +
                "</td>" +
                "</tr></table><br>"
              );
            },
            title: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td align='left' style='width: 20%;'>" +
                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                "</td>" +
                "<td align='left' style='width: 30%;'>" +
                "</td>" +
                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                companyAddressDetls +
                "</td>" +
                "</tr></table>"
              );
            },
          },
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            footer: true,
            title: function () {
              return (
                "Consolidated Memo Report Source Wise - " +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                ""
              );
            },
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        responsive: true,
        scrollX: true,
        scrollY: 380,
        scrollCollapse: true,
        paging: false,
        info: true,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
              ? i
              : 0;
          };
          // computing column Total of the complete result
          var totalMemo = api
            .column(1)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var totalArticle = api
            .column(2)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actualWgt = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var chargeWgt = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var toPayAmt = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paidAmt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var ddAmt = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var unloadingHamaliAmt = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var hamaligdAmt = api
            .column(9)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var demAmt = api
            .column(10)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var rcAmt = api
            .column(11)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var scAmt = api
            .column(12)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var otherChgAmt = api
            .column(13)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var discAmt = api
            .column(14)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var subTotAmt = api
            .column(15)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var grandTotAmt = api
            .column(16)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          // Update footer by showing the total with the reference of the column index
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(1).footer()).html(totalMemo);
          $(api.column(2).footer()).html(totalArticle);
          $(api.column(3).footer()).html(actualWgt);
          $(api.column(4).footer()).html(chargeWgt);
          $(api.column(5).footer()).html(toPayAmt);
          $(api.column(6).footer()).html(paidAmt);
          $(api.column(7).footer()).html(ddAmt);
          $(api.column(8).footer()).html(unloadingHamaliAmt);
          $(api.column(9).footer()).html(hamaligdAmt);
          $(api.column(10).footer()).html(demAmt);
          $(api.column(11).footer()).html(rcAmt);
          $(api.column(12).footer()).html(scAmt);
          $(api.column(13).footer()).html(otherChgAmt);
          $(api.column(14).footer()).html(discAmt);
          $(api.column(15).footer()).html(subTotAmt);
          $(api.column(16).footer()).html(grandTotAmt);
        },
      }),
      //second datatable end
      //third datatable start
      (this.dtOptionsConsolidatedMemoMainSourceWiseRpt = {
        dom: "Bfrtip",
        //destroy: true,
        buttons: [
          {
            extend: "print",
            text: '<i class="fas fa-print"> Print</i>',
            titleAttr: "Print",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("th, td")
                .css("font-family", "Arial, Helvetica, sans-serif")
                .css("font-size", "12px")
                .css("text-align", "center");
            },
            footer: true,
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 13, 14, 15, 16],
            },
            messageTop: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                "<u> Consolidated Memo Report Main Source Wise </u><br><br>" +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                "" +
                "</td>" +
                "</tr></table><br>"
              );
            },
            title: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td align='left' style='width: 20%;'>" +
                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                "</td>" +
                "<td align='left' style='width: 30%;'>" +
                "</td>" +
                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                companyAddressDetls +
                "</td>" +
                "</tr></table>"
              );
            },
          },
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            footer: true,
            title: function () {
              return (
                "Consolidated Memo Report Main Source Wise - " +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                ""
              );
            },
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        responsive: true,
        scrollX: true,
        scrollY: 380,
        scrollCollapse: true,
        paging: false,
        info: true,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
              ? i
              : 0;
          };
          // computing column Total of the complete result
          var totalMemo = api
            .column(1)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var totalArticle = api
            .column(2)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actualWgt = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var chargeWgt = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var toPayAmt = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paidAmt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var ddAmt = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var unloadingHamaliAmt = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var hamaligdAmt = api
            .column(9)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var demAmt = api
            .column(10)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var rcAmt = api
            .column(11)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var scAmt = api
            .column(12)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var otherChgAmt = api
            .column(13)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var discAmt = api
            .column(14)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var subTotAmt = api
            .column(15)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var grandTotAmt = api
            .column(16)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          // Update footer by showing the total with the reference of the column index
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(1).footer()).html(totalMemo);
          $(api.column(2).footer()).html(totalArticle);
          $(api.column(3).footer()).html(actualWgt);
          $(api.column(4).footer()).html(chargeWgt);
          $(api.column(5).footer()).html(toPayAmt);
          $(api.column(6).footer()).html(paidAmt);
          $(api.column(7).footer()).html(ddAmt);
          $(api.column(8).footer()).html(unloadingHamaliAmt);
          $(api.column(9).footer()).html(hamaligdAmt);
          $(api.column(10).footer()).html(demAmt);
          $(api.column(11).footer()).html(rcAmt);
          $(api.column(12).footer()).html(scAmt);
          $(api.column(13).footer()).html(otherChgAmt);
          $(api.column(14).footer()).html(discAmt);
          $(api.column(15).footer()).html(subTotAmt);
          $(api.column(16).footer()).html(grandTotAmt);
        },
      }),
      //third datatable ends
      //fourth datatable starts
      (this.dtOptionAllTransaction = {
        dom: "Bfrtip",
        //destroy: true,
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            footer: true,
            title: function () {
              return (
                "All Transaction Report - " +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                " - "
              );
            },
            exportOptions: {
              columns: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                34, 35, 36, 37, 38, 39
              ],
            },
          },
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel - 1</i>',
            titleAttr: "Excel - 1",
            footer: true,
            title: function () {
              return (
                "All Transaction Report - " +
                "From Date : " +
                moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                " -  " +
                "To Date : " +
                moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                " - "
              );
            },
            exportOptions: {
              columns: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                34, 35, 36, 37, 38, 39
              ],
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        //place holder in search/filter in datatable ends
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 380,
        scrollCollapse: true,
        paging: false,
        info: true,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
              ? i
              : 0;
          };
          // computing column Total of the complete result
          var totalArticle = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actualWgt = api
            .column(9)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var chargeWgt = api
            .column(10)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var toPayAmt = api
            .column(11)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paidAmt = api
            .column(12)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var ddAmt = api
            .column(13)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var unloadingHamaliAmt = api
            .column(14)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var hamaligdAmt = api
            .column(15)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var rcAmt = api
            .column(16)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var discAmt = api
            .column(17)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var demAmt = api
            .column(18)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var scAmt = api
            .column(19)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          /* var otherChgAmt = api.column(12).data().reduce(
                         function (a, b) {
                             return intVal(a) + intVal(b);
                         }, 0);*/

          var subTotAmt = api
            .column(20)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var grandTotAmt = api
            .column(21)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var riskChg = api
            .column(24)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var tripsheetHamali = api
            .column(25)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var lrDD = api
            .column(26)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
            var aoc = api
            .column(28)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          // Update footer by showing the total with the reference of the column index
          $(api.column(0).footer()).html("Total : " + data.length);
          //$(api.column(1).footer()).html(data.length);
          $(api.column(4).footer()).html(totalArticle);
          $(api.column(9).footer()).html(actualWgt);
          $(api.column(10).footer()).html(chargeWgt);
          $(api.column(11).footer()).html(toPayAmt);
          $(api.column(12).footer()).html(paidAmt);
          $(api.column(13).footer()).html(ddAmt);
          $(api.column(14).footer()).html(unloadingHamaliAmt);
          $(api.column(15).footer()).html(hamaligdAmt);
          $(api.column(16).footer()).html(rcAmt);
          $(api.column(17).footer()).html(discAmt);
          $(api.column(18).footer()).html(demAmt);
          $(api.column(19).footer()).html(scAmt);
          //$(api.column(12).footer()).html(otherChgAmt);
          $(api.column(20).footer()).html(subTotAmt);
          $(api.column(21).footer()).html(grandTotAmt);
          $(api.column(24).footer()).html(riskChg);
          $(api.column(25).footer()).html(tripsheetHamali);
          $(api.column(26).footer()).html(lrDD);
          $(api.column(28).footer()).html(aoc);
        },
      }),
      //five datatable starts
      (this.dtOptionDefaultCashMemoDetailsRpt = {
        dom: "Bfrtip",
        //destroy: true,
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            footer: true,
            title: function () {
              var newMemoType = null;
              if ($("#chsdcmemoTypeId").val() == "os") {
                newMemoType = "OutStanding";
              } else if ($("#chsdcmemoTypeId").val() == "cash") {
                newMemoType = "Cash";
              } else {
                newMemoType = $("#chsdcmemoTypeId").val();
              }
              if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxArea").is(":checked") == true
              ) {
                return (
                  "Daily Cash Memo - " +
                  newMemoType +
                  "- " +
                  $("#chsdcdeliveryAreaId").val() +
                  " - " +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  " - "
                );
              } else if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxCollectionMan").is(":checked") == true
              ) {
                return (
                  "Daily Cash Memo - " +
                  newMemoType +
                  "- " +
                  $("#chsdccollectionManId").val() +
                  " - " +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  ""
                );
              } else {
                return (
                  "Daily Cash Memo - " +
                  newMemoType +
                  " - " +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  ""
                );
              }
            },
            /* exportOptions: {
                             columns: [0, 4, 20, 1, 2, 12, 10, 16, 8, 9, 6, 7]
                         }*/
          },
          {
            extend: "print",
            text: '<i class="fas fa-print"> Print</i>',
            titleAttr: "Print",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("th, td")
                .css("font-family", "Arial, Helvetica, sans-serif")
                .css("font-size", "12px")
                .css("text-align", "center");
            },
            footer: true,
            exportOptions: {
              columns: [0, 1, 5, 2, 3, 7, 8, 9, 12, 13],
            },
            messageTop: function () {
              var newMemoType = null;
              if ($("#chsdcmemoTypeId").val() == "os") {
                newMemoType = "OutStanding";
              } else if ($("#chsdcmemoTypeId").val() == "cash") {
                newMemoType = "Cash";
              } else {
                newMemoType = $("#chsdcmemoTypeId").val();
              }
              var areaCheckBoxPrint = $("#chsdccheckboxArea").is(":checked");
              var collectionManCheckBoxPrint = $(
                "#chsdccheckboxCollectionMan"
              ).is(":checked");
              if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxArea").is(":checked") == true
              ) {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  " - </span>" +
                  "<span>" +
                  $("#chsdcdeliveryAreaId").val() +
                  "</span> <br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "" +
                  "</td>" +
                  "</tr></table><br>"
                );
              } else if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxCollectionMan").is(":checked") == true
              ) {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  " -  </span>" +
                  "<span>" +
                  $("#chsdccollectionManId").val() +
                  "</span> <br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "</td>" +
                  "</tr></table><br>"
                );
              } else {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  "  </span><br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "" +
                  "</td>" +
                  "</tr></table><br>"
                );
              }
            },
            title: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td align='left' style='width: 20%;'>" +
                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                // companyAddressDetls +
                "</td>" +
                "<td align='left' style='width: 30%;'>" +
                // companyAddressDetls +
                "</td>" +
                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                companyAddressDetls +
                "</td>" +
                "</tr></table>"
              );
            },
          },
          {
            extend: "print",
            text: '<i class="fas fa-print"> Print - 1</i>',
            titleAttr: "Print - 1",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("th, td")
                .css("font-family", "Arial, Helvetica, sans-serif")
                .css("font-size", "12px")
                .css("text-align", "center");
            },
            footer: true,
            exportOptions: {
              columns: [0, 1, 5, 2, 3, 7, 8, 9, 12, 13],
            },
            messageTop: function () {
              var newMemoType = null;
              if ($("#chsdcmemoTypeId").val() == "os") {
                newMemoType = "OutStanding";
              } else if ($("#chsdcmemoTypeId").val() == "cash") {
                newMemoType = "Cash";
              } else {
                newMemoType = $("#chsdcmemoTypeId").val();
              }
              var areaCheckBoxPrint = $("#chsdccheckboxArea").is(":checked");
              var collectionManCheckBoxPrint = $(
                "#chsdccheckboxCollectionMan"
              ).is(":checked");
              if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxArea").is(":checked") == true
              ) {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  " - </span>" +
                  "<span>" +
                  $("#chsdcdeliveryAreaId").val() +
                  "</span> <br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "" +
                  "</td>" +
                  "</tr></table><br>"
                );
              } else if (
                $("#chsdcmemoTypeId").val() == "os" &&
                $("#chsdccheckboxCollectionMan").is(":checked") == true
              ) {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  " -  </span>" +
                  "<span>" +
                  $("#chsdccollectionManId").val() +
                  "</span> <br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "</td>" +
                  "</tr></table><br>"
                );
              } else {
                return (
                  "<table style='table-layout: fixed;width: 100%;'><tr>" +
                  "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                  "<span>Daily Cash Memo - " +
                  newMemoType +
                  "  </span><br><br>" +
                  "From Date : " +
                  moment($("#chsdcfromDates").val()).format("DD-MM-YYYY") +
                  " -  " +
                  "To Date : " +
                  moment($("#chsdctoDates").val()).format("DD-MM-YYYY") +
                  "" +
                  "</td>" +
                  "</tr></table><br>"
                );
              }
            },
            title: function () {
              return (
                "<table style='table-layout: fixed;width: 100%;'><tr>" +
                "<td align='left' style='width: 20%;'>" +
                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                // companyAddressDetls +
                "</td>" +
                "<td align='left' style='width: 30%;'>" +
                // companyAddressDetls +
                "</td>" +
                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                companyAddressDetls +
                "</td>" +
                "</tr></table>"
              );
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        //place holder in search/filter in datatable ends
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 380,
        scrollCollapse: true,
        paging: false,
        info: true,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
              ? i
              : 0;
          };

          var totalArticle = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var discAmt = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var hamaligdAmt = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var amt = api
            .column(9)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var toPayAmt = api
            .column(10)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paidAmt = api
            .column(11)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var ddAmt = api
            .column(12)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var actualWgt = api
            .column(13)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var chargeWgt = api
            .column(14)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          // Update footer by showing the total with the reference of the column index
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(3).footer()).html(totalArticle);
          $(api.column(7).footer()).html(discAmt);
          $(api.column(8).footer()).html(hamaligdAmt);
          $(api.column(9).footer()).html(amt);
          // $(api.column(7).footer()).html(unloadingHamaliAmt);
          $(api.column(10).footer()).html(toPayAmt);
          $(api.column(11).footer()).html(paidAmt);
          $(api.column(12).footer()).html(ddAmt);
          $(api.column(13).footer()).html(actualWgt);
          $(api.column(14).footer()).html(chargeWgt);
        },
      });
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Do your stuff
        //console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
        if (
          dtInstance.table().node().id == "consolidateMemoRptDetailsTableId"
        ) {
          //console.log("a");
          this.cashmemoDetailDataList = [];
          dtInstance.destroy();
          this.dtTriggerConsolidateMemoRpt.next();
        } else if (
          dtInstance.table().node().id == "consolidatedMemoSourceRptTableId"
        ) {
          //console.log("b");
          this.consolidatedMemoRptDataList = [];
          dtInstance.destroy();
          this.dtTriggerConsolidatedMemoSourceRpt.next();
        } else if (
          dtInstance.table().node().id == "consolidatedMemoRptMainSrcTableId"
        ) {
          //console.log("c");
          this.consolidatedMemoRptSrcDataList = [];
          dtInstance.destroy();
          this.dtTriggerConsolidatedMemoMainSourceWiseRpt.next();
        } else if (dtInstance.table().node().id == "allTransactionTableId") {
          //console.log("d");
          this.allTransactionDataList = [];
          dtInstance.destroy();
          this.dtTriggerAllTransaction.next();
        } else if (
          dtInstance.table().node().id == "DefaultCashMemoDetailsRptTableId"
        ) {
          //console.log("d");
          this.defaultCashmemoRptDataList = [];
          dtInstance.destroy();
          this.dtTriggerDefaultCashMemoDetailsRpt.next();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerConsolidateMemoRpt.unsubscribe();
    this.dtTriggerConsolidatedMemoSourceRpt.unsubscribe();
    this.dtTriggerConsolidatedMemoMainSourceWiseRpt.unsubscribe();
    this.dtTriggerAllTransaction.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerConsolidateMemoRpt.next();
    this.dtTriggerConsolidatedMemoSourceRpt.next();
    this.dtTriggerConsolidatedMemoMainSourceWiseRpt.next();
    this.dtTriggerAllTransaction.next();
    this.dtTriggerDefaultCashMemoDetailsRpt.next();
  }

  clearBtn() {
    this.clearAllFields();
  }
  clearAllFields() {
    this.fromDatesModal = null;
    this.toDatesModal = null;
    $("#" + this.pageId + "fromDates").val("");
    $("#" + this.pageId + "toDates").val("");
    $("#" + this.pageId + "modelCollectionMan").val("");
    $("#" + this.pageId + "modelOtherDeliveryArea").val("");
    $("#" + this.pageId + "modelDestination").val("");
    $("#" + this.pageId + "modelLocalDestination").val("");

    this.hideAndShowAreaCollectionManCheckBox = false;
    this.searchByCollectionManWise = false;
    this.searchByAreaWise = false;
    this.hideAndShowMemoType = true;
    $("#" + this.pageId + "memoTypeId").val("selected memo");

    this.hideAndShowConsolidatedMemoRpt = true;
    this.hideAndShowConsolidateMemoRptSrc = true;
    this.hideAndShowConsolidateMemoRptMainSrc = true;
    this.hideAndShowAllTransaction = true;

    $("#" + this.pageId + "checkboxConsolidatedMemoRpt").prop("checked", false);
    $("#" + this.pageId + "checkboxConsolidatedMemoRptSrc").prop(
      "checked",
      false
    );
    $("#" + this.pageId + "checkboxConsolidatedMemoRptMainSrc").prop(
      "checked",
      false
    );
    $("#" + this.pageId + "checkboxAllTransaction").prop("checked", false);
    $("#" + this.pageId + "checkboxAllTransactionShowLrDD").prop(
      "checked",
      false
    );
    this.showAllLRDDLrsCheckbox = false;
    this.showAllLRDDLrs = false;

    this.hideAndShowTableAllTransaction = false;
    this.hideAndShowTableCashMemoDetails = false;
    this.hideAndShowTableConsolidatedMemoRpt = false;
    this.hideAndShowTableConsolidatedMemoRptSrc = false;
    this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

    this.cashmemoDetailDataList = [];
    this.consolidatedMemoRptDataList = [];
    this.consolidatedMemoRptSrcDataList = [];
    this.allTransactionDataList = [];
    this.defaultCashmemoRptDataList = [];

    $("#" + this.pageId + "consolidateMemoRptDetailsTableId")
      .DataTable()
      .destroy();
    $("#" + this.pageId + "consolidatedMemoSourceRptTableId")
      .DataTable()
      .destroy();
    $("#" + this.pageId + "consolidatedMemoRptMainSrcTableId")
      .DataTable()
      .destroy();
    $("#" + this.pageId + "allTransactionTableId")
      .DataTable()
      .destroy();
    $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
      .DataTable()
      .destroy();
    this.dtTriggerDefaultCashMemoDetailsRpt.next();

    $("#" + this.pageId + "cheatCodeId").val("");
    this.hideAndShowEnableField = false;
    $("#" + this.pageId + "checkboxEnable").prop("checked", false);
    $("#" + this.pageId + "checkboxEnableAll").prop("checked", false);
    $("#" + this.pageId + "checkboxEnableSpecific").prop("checked", false);

    this.hideAndShowLocalDestination = false;
    this.modelLocalDestination = null;
    this.modelDestination = null;
  }

  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }
  areaCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      $("#" + this.pageId + "checkboxCollectionMan").prop("checked", false);
      $("#" + this.pageId + "checkboxArea").prop("checked", true);
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = true;
      this.modelCollectionMan = "";
      this.modelOtherDeliveryArea = "";
      this.validateCollectionManName = null;
      this.validateArea = null;
      //this.memoDto.mode = "Type";
    } else if (values.currentTarget.checked == false) {
      $("#" + this.pageId + "checkboxCollectionMan").prop("checked", false);
      $("#" + this.pageId + "checkboxArea").prop("checked", false);
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.modelCollectionMan = "";
      this.modelOtherDeliveryArea = "";
      this.memoDto.mode = "Type";
      this.modeString = this.memoDto.mode;
      this.validateCollectionManName = null;
      this.validateArea = null;
    }
  }
  collectionManCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      $("#" + this.pageId + "checkboxCollectionMan").prop("checked", true);
      $("#" + this.pageId + "checkboxArea").prop("checked", false);
      this.searchByCollectionManWise = true;
      this.searchByAreaWise = false;
      this.modelCollectionMan = "";
      this.modelOtherDeliveryArea = "";
      this.validateCollectionManName = null;
      this.validateArea = null;
    } else if (values.currentTarget.checked == false) {
      $("#" + this.pageId + "checkboxCollectionMan").prop("checked", false);
      $("#" + this.pageId + "checkboxArea").prop("checked", false);
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.modelCollectionMan = "";
      this.modelOtherDeliveryArea = "";
      this.memoDto.mode = "Type";
      this.modeString = this.memoDto.mode;
      this.validateCollectionManName = null;
      this.validateArea = null;
    }
  }

  enableCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.hideAndShowEnableField = true;
      this.validateCheckboxEnable = true;
      $("#" + this.pageId + "checkboxEnableAll").prop("checked", true);
    } else {
      this.hideAndShowEnableField = false;
      this.validateCheckboxEnable = false;
    }
  }

  consolidatedMemoRptCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.memoDto.mode = "Consolidated";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = true;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = false;

      this.hideAndShowConsolidateMemoRptSrc = false;
      this.hideAndShowConsolidateMemoRptMainSrc = false;
      this.hideAndShowAllTransaction = false;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = false;
      this.hideAndShowToDateField = false;
      this.hideAndShowFromDateField = false;

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      this.cashmemoDetailDataList = [];
      $("#" + this.pageId + "consolidateMemoRptDetailsTableId")
        .DataTable()
        .destroy();
      this.dtTriggerConsolidateMemoRpt.next();
    } else {
      this.memoDto.mode = "";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

      this.hideAndShowConsolidatedMemoRpt = true;
      this.hideAndShowConsolidateMemoRptSrc = true;
      this.hideAndShowConsolidateMemoRptMainSrc = true;
      this.hideAndShowAllTransaction = true;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = true;

      //if (this.rightsToShowDestination != null &&
      //this.rightsToShowDestination == "Show Destination") {
      if (
        this.userDataDtoReturnSession.role != null &&
        this.userDataDtoReturnSession.role == "Super Administrator"
      ) {
        this.hideAndShowDestination = true;
      } else {
        this.hideAndShowDestination = false;
      }
      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
        .DataTable()
        .destroy();
      this.defaultCashmemoRptDataList = [];
      this.dtTriggerDefaultCashMemoDetailsRpt.next();
    }
  }

  consolidatedMemoRptSrcCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.memoDto.mode = "ConsolidatedSourceWise";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = true;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = false;

      this.hideAndShowConsolidatedMemoRpt = false;
      this.hideAndShowConsolidateMemoRptMainSrc = false;
      this.hideAndShowAllTransaction = false;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = false;
      this.hideAndShowToDateField = false;
      this.hideAndShowFromDateField = false;

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "consolidatedMemoSourceRptTableId")
        .DataTable()
        .destroy();
      this.consolidatedMemoRptDataList = [];
      this.dtTriggerConsolidatedMemoSourceRpt.next();
    } else {
      this.memoDto.mode = "";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

      this.hideAndShowConsolidatedMemoRpt = true;
      this.hideAndShowConsolidateMemoRptSrc = true;
      this.hideAndShowConsolidateMemoRptMainSrc = true;
      this.hideAndShowAllTransaction = true;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = true;

      //if (this.rightsToShowDestination != null &&
      //this.rightsToShowDestination == "Show Destination") {
      if (
        this.userDataDtoReturnSession.role != null &&
        this.userDataDtoReturnSession.role == "Super Administrator"
      ) {
        this.hideAndShowDestination = true;
      } else {
        this.hideAndShowDestination = false;
      }
      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
        .DataTable()
        .destroy();
      this.defaultCashmemoRptDataList = [];
      this.dtTriggerDefaultCashMemoDetailsRpt.next();
    }
  }

  consolidatedMemoRptMainSrcCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.memoDto.mode = "ConsolidatedMainSourceWise";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = true;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = false;

      this.hideAndShowConsolidatedMemoRpt = false;
      this.hideAndShowConsolidateMemoRptSrc = false;
      this.hideAndShowAllTransaction = false;

      this.hideAndShowAreaCollectionManCheckBox = false;
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = false;
      this.hideAndShowToDateField = false;
      this.hideAndShowFromDateField = false;

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "consolidatedMemoRptMainSrcTableId")
        .DataTable()
        .destroy();
      this.consolidatedMemoRptSrcDataList = [];
      this.dtTriggerConsolidatedMemoMainSourceWiseRpt.next();
    } else {
      this.memoDto.mode = "";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

      this.hideAndShowConsolidatedMemoRpt = true;
      this.hideAndShowConsolidateMemoRptSrc = true;
      this.hideAndShowConsolidateMemoRptMainSrc = true;
      this.hideAndShowAllTransaction = true;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = true;

      //if (this.rightsToShowDestination != null &&
      //this.rightsToShowDestination == "Show Destination") {
      if (
        this.userDataDtoReturnSession.role != null &&
        this.userDataDtoReturnSession.role == "Super Administrator"
      ) {
        this.hideAndShowDestination = true;
      } else {
        this.hideAndShowDestination = false;
      }

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
        .DataTable()
        .destroy();
      this.defaultCashmemoRptDataList = [];
      this.dtTriggerDefaultCashMemoDetailsRpt.next();
    }
  }

  allTranscationCheckBoxEvent(values: any) {
    this.showAllLRDDLrs = false;
    this.showAllLRDDLrsCheckbox = false;
    $("#" + this.pageId + "checkboxAllTransactionShowLrDD").prop(
      "checked",
      false
    );
    if (values.currentTarget.checked == true) {
      this.memoDto.mode = "All Transaction";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = true;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = false;

      this.hideAndShowConsolidatedMemoRpt = false;
      this.hideAndShowConsolidateMemoRptSrc = false;
      this.hideAndShowConsolidateMemoRptMainSrc = false;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = false;
      this.hideAndShowToDateField = true;
      this.hideAndShowFromDateField = true;

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      $("#" + this.pageId + "allTransactionTableId")
        .DataTable()
        .destroy();
      this.allTransactionDataList = [];
      this.dtTriggerAllTransaction.next();
      if (this.hideshowTripsheetHamaliAndLRDD) {
        this.showAllLRDDLrsCheckbox = true;
      }
    } else {
      this.memoDto.mode = "All Transaction";
      this.modeString = this.memoDto.mode;

      this.hideAndShowTableAllTransaction = false;
      this.hideAndShowTableCashMemoDetails = false;
      this.hideAndShowTableConsolidatedMemoRpt = false;
      this.hideAndShowTableConsolidatedMemoRptSrc = false;
      this.hideAndShowTableDefaultCashMemoDetailsRpt = true;

      this.hideAndShowConsolidatedMemoRpt = true;
      this.hideAndShowConsolidateMemoRptSrc = true;
      this.hideAndShowConsolidateMemoRptMainSrc = true;
      this.hideAndShowAllTransaction = true;

      this.hideAndShowAreaCollectionManCheckBox = false;

      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowMemoType = true;
      this.hideAndShowToDateField = true;
      this.hideAndShowFromDateField = true;

      //if (this.rightsToShowDestination != null &&
      //this.rightsToShowDestination == "Show Destination") {
      if (
        this.userDataDtoReturnSession.role != null &&
        this.userDataDtoReturnSession.role == "Super Administrator"
      ) {
        this.hideAndShowDestination = true;
      } else {
        this.hideAndShowDestination = false;
      }

      $("#" + this.pageId + "modelLocalDestination").val("");
      $("#" + this.pageId + "modelDestination").val("");

      //this.clearDataTableDestroy();
      $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
        .DataTable()
        .destroy();
      this.defaultCashmemoRptDataList = [];
      this.dtTriggerDefaultCashMemoDetailsRpt.next();
    }
  }

  allTranscationShowLrDDEvent(values: any) {
    console.log("inside allTranscationShowLrDDEvent");
    if (values.currentTarget.checked == true) {
      this.showAllLRDDLrs = true;
      console.log("1" + this.showAllLRDDLrs);
    } else {
      this.showAllLRDDLrs = false;
      console.log("2" + this.showAllLRDDLrs);
    }
  }

  searchByMode(searchBy: string) {
    if (searchBy === "cash") {
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowAreaCollectionManCheckBox = false;
      this.memoDto.mode = "Type";
      this.modeString = "Type";
      this.validateCollectionManName = null;
      this.validateArea = null;
    } else if (searchBy === "os") {
      $("#" + this.pageId + "checkboxCollectionMan").prop("checked", false);
      $("#" + this.pageId + "checkboxArea").prop("checked", false);
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowAreaCollectionManCheckBox = true;
      this.memoDto.mode = "Type";
      this.modeString = "Type";
      this.validateCollectionManName = null;
      this.validateArea = null;
    } else if (searchBy === "pp") {
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowAreaCollectionManCheckBox = false;
      this.memoDto.mode = "Type";
      this.modeString = "Type";
      this.validateCollectionManName = null;
      this.validateArea = null;
    } else if (searchBy === "co") {
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowAreaCollectionManCheckBox = false;
      this.memoDto.mode = "Type";
      this.modeString = "Type";
      this.validateCollectionManName = null;
      this.validateArea = null;
    } else {
      this.searchByCollectionManWise = false;
      this.searchByAreaWise = false;
      this.hideAndShowAreaCollectionManCheckBox = false;
      this.validateCollectionManName = null;
      this.validateArea = null;
    }
  }

  clickListnerForCollectionMan(event) {
    this.modelCollectionMan = event.item;
    $("#" + this.pageId + "collectionManId").val(
      this.modelCollectionMan.collectioMan
    );
    this.validateCollectionManName = this.modelCollectionMan.collectioMan;
    this.setModeArea();
  }
  clickListnerForDeliveryArea(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelOtherDeliveryArea = e.item;
    $("#" + this.pageId + "deliveryAreaId").val(
      this.modelOtherDeliveryArea.destination
    );
    this.setModeArea();
  }
  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelDestination = e.item;
    $("#" + this.pageId + "destinationId").val(
      this.modelDestination.destination
    );
    this.selectionChangeBasedOnDestination();
  }
  clickListnerForLocalDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelLocalDestination = e.item;
    $("#" + this.pageId + "localDestinationId").val(
      this.modelLocalDestination.destination
    );
  }

  setModeArea() {
    if (this.searchByAreaWise == true) {
      this.validateForModeAreaCheckBox = $(
        "#" + this.pageId + "checkboxArea"
      ).prop("checked");
      if (this.validateForModeAreaCheckBox == true) {
        this.memoDto.mode = "Area";
        this.modeString = this.memoDto.mode;
      } else if (this.validateForModeAreaCheckBox == false) {
        this.memoDto.mode = "Type";
        this.modeString = this.memoDto.mode;
      }
    } else if (this.searchByCollectionManWise == true) {
      this.validateForModeCollectionManCheckBox = $(
        "#" + this.pageId + "checkboxCollectionMan"
      ).prop("checked");
      if (this.validateForModeCollectionManCheckBox == true) {
        this.memoDto.mode = "Area";
        this.modeString = this.memoDto.mode;
      } else if (this.validateForModeCollectionManCheckBox == false) {
        this.memoDto.mode = "Type";
        this.modeString = this.memoDto.mode;
      }
    }
  }

  selectionChangeBasedOnDestination() {
    this.validateDestination = $("#" + this.pageId + "destinationId").val();
    this.validateLocalDestination = $(
      "#" + this.pageId + "localDestinationId"
    ).val();
    if (
      this.validateDestination != null &&
      this.validateDestination != undefined &&
      this.validateDestination != "" &&
      this.validateDestination == "Chennai"
    ) {
      this.hideAndShowLocalDestination = true;
      this.modelLocalDestination = null;
      this.getLocalDestinationDetails(this.validateDestination);
    } else {
      this.hideAndShowLocalDestination = false;
    }

    if (
      this.validateDestination != null &&
      this.validateDestination != undefined &&
      this.validateDestination != ""
    ) {
      this.getCollectionManDetailsList(this.validateDestination);
      this.getBranchAreaDetailsList(this.validateDestination);
    }
  }

  getDetailsForCollectionMasterRead() {
    this.userDtoCollectionMan = new UserDataDto();
    //this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
    this.userDtoCollectionMan.companyId =
      this.userDataDtoReturnSession.companyId;
    this.userDtoCollectionMan.status = "D";
  }

  getCollectionManDetailsList(destination) {
    this.showSpinnerForAction = true;
    this.getDetailsForCollectionMasterRead();
    this.userDtoCollectionMan.branchId = destination;
    this.masterReadService
      .getCollectionManMasterDetails(this.userDtoCollectionMan)
      .subscribe((response) => {
        if (response.length > 0) {
          this.collectionManOptions = response;
          this.collectionManTA = [];
          for (let i = 0; i < this.collectionManOptions.length; i++) {
            this.collectionManTA.push(this.collectionManOptions[i]);
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Server Error",
          "Problem occur while getting Collection Man Details",
          "error"
        );
      },
      () => console.log("done");
  }

  getBranchAreaDetails() {
    this.lrDto = new LRDto();
    this.lrDto.branch = this.userDataDtoReturnSession.office;
    // this.lrDto.mode = this.userDataDtoReturnSession.mainStation;
    this.lrDto.mode = "ALL";
    //this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
    this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
  }

  getBranchAreaDetailsList = (destination) => {
    this.showSpinnerForAction = true;
    this.getBranchAreaDetails();
    this.lrDto.mainstation = destination;
    this.masterReadService.getBranchAreas(this.lrDto).subscribe((response) => {
      if (response.length > 0) {
        this.controlOtherChargeArea.reset();
        this.deliveryAreaOptions = response;
        this.deliveryAreaTA = [];
        for (let i = 0; i < this.deliveryAreaOptions.length; i++) {
          this.deliveryAreaTA.push(this.deliveryAreaOptions[i]);
        }
        this.showSpinnerForAction = false;
      } else {
        this.showSpinnerForAction = false;
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Server Error",
          "Problem occur while getting delivery area Details",
          "error"
        );
      },
      () => console.log("done");
  };

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
    this.lrDtoDestination.mode = "LrForm";
  }
  getDestinationDetails() {
    this.showSpinnerForAction = true;
    this.getDestinationMethod();
    this.masterReadService
      .getDestinationForLREntryService(this.lrDtoDestination)
      .subscribe((response) => {
        if (response.length > 0) {
          this.controlDestination.reset();
          this.destinationOptions = response;
          this.destinationTA = [];
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Server Error", "While Getting destination details", "warning");
      },
      () => console.log("done");
  }

  getLocalDestinationMethod() {
    this.lrDtoLocalDestination = new LRDto();
    this.lrDtoLocalDestination.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoLocalDestination.branch = this.userDataDtoReturnSession.office;
    //this.lrDtoLocalDestination.mainstation = this.userDataDtoReturnSession.mainStation;
    this.lrDtoLocalDestination.mode = "All";
  }
  getLocalDestinationDetails(station) {
    this.showSpinnerForAction = true;
    this.getLocalDestinationMethod();
    this.lrDtoLocalDestination.mainstation = station;
    this.masterReadService
      .getDestinationForLREntryService(this.lrDtoLocalDestination)
      .subscribe((response) => {
        if (response.length > 0) {
          this.controlLocalDestination.reset();
          this.localDestinationOptions = response;
          this.localDestinationTA = [];
          this.lrDtoLocalDestinationAllOption.destination = "All";
          this.localDestinationTA.push(this.lrDtoLocalDestinationAllOption);
          for (let i = 0; i < this.localDestinationOptions.length; i++) {
            this.localDestinationTA.push(this.localDestinationOptions[i]);
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Server Error", "While Getting destination details", "warning");
      },
      () => console.log("done");
  }

  searchBtnForCashMemoDetails() {
    //console.log(this.hideAndShowLocalDestination);
    if (
      this.hideAndShowLocalDestination == true &&
      ($("#" + this.pageId + "localDestinationId").val() == null ||
        $("#" + this.pageId + "localDestinationId").val() == undefined ||
        $("#" + this.pageId + "localDestinationId").val() == "")
    ) {
      swal("Mandatory Field", "Please select the Godown", "warning");
      return false;
    } else {
      if (
        (this.modeString != null && this.modeString == "Type") ||
        this.modeString == "Area"
      ) {
        this.gridDefaultSearch(this.modeString);
      } else if (this.modeString != null && this.modeString == "Consolidated") {
        this.getConsolidatedMemoReport(this.modeString);
      } else if (
        this.modeString != null &&
        this.modeString == "ConsolidatedSourceWise"
      ) {
        //ConsolidatedSourceWise
        this.getConsolidatedSourceWise(this.modeString);
      } else if (
        this.modeString != null &&
        this.modeString == "ConsolidatedMainSourceWise"
      ) {
        this.getConsolidatedMainSourceWise(this.modeString);
      } else if (
        this.modeString != null &&
        this.modeString == "All Transaction"
      ) {
        this.getAllTransaction(this.modeString);
      }
    }
  }
  //default
  gridDefaultSearch(mode) {
    // if((this.rghtsForRole == "Super Administrator")&&
    //($("#"+this.pageId+"destinationId").val() == null || $("#"+this.pageId+"destinationId").val() == undefined ||
    //$("#"+this.pageId+"destinationId").val() == "")) {
    if (
      this.userDataDtoReturnSession.role == "Super Administrator" &&
      ($("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined ||
        $("#" + this.pageId + "destinationId").val() == "")
    ) {
      swal("ALERT", "Destination Is Mandatory Field", "warning");
      return false;
    } else {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateMemoType = $("#" + this.pageId + "memoTypeId").val();
      this.validateArea = $("#" + this.pageId + "deliveryAreaId").val();
      this.validateCollectionManName = $(
        "#" + this.pageId + "collectionManId"
      ).val();
      this.validateDestination = $("#" + this.pageId + "destinationId").val();
      this.validateLocalDestination = $(
        "#" + this.pageId + "localDestinationId"
      ).val();

      if (
        this.validateFromDate != null &&
        this.validateFromDate != undefined &&
        this.validateFromDate != ""
      ) {
        this.memoDto.fromdate = this.validateFromDate;
        this.memoDto.todate = this.validateToDate;
      }
      if (this.searchByAreaWise == true) {
        this.memoDto.area = this.validateArea;
        this.memoDto.collectionMan = null;
      }
      if (this.searchByCollectionManWise == true) {
        this.memoDto.area = null;
        this.memoDto.collectionMan = this.validateCollectionManName;
      }

      if (this.hideAndShowLocalDestination == true) {
        if (
          this.validateLocalDestination != null &&
          this.validateLocalDestination != undefined &&
          this.validateLocalDestination != "" &&
          this.validateLocalDestination == "All"
        ) {
          //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
          if (
            this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator"
          ) {
            this.memoDto.station = this.validateDestination;
          } else {
            this.memoDto.station = this.userDataDtoReturnSession.mainStation;
          }
          this.memoDto.godown = "all";
        } else {
          // this.memoDto.station = this.validateDestination;
          this.memoDto.station = this.validateLocalDestination;
          this.memoDto.godown = "specific";
        }
      } else {
        this.memoDto.godown = "specific";
        //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
        if (
          this.userDataDtoReturnSession.role != null &&
          this.userDataDtoReturnSession.role == "Super Administrator"
        ) {
          this.memoDto.station = this.validateDestination;
        } else {
          this.memoDto.station = this.userDataDtoReturnSession.office;
        }
      }

      this.memoDto.memoType = this.validateMemoType;
      this.memoDto.mode = mode;
      this.memoDto.withLetterHead = null;
      this.setBogusValue();
      this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
      this.memoDto.showAllLRDDLrs = this.showAllLRDDLrs;
      //console.log(this.memoDto);
      this.showSpinnerForAction = true;
      this.memoReport.getCashMemoReport(this.memoDto).subscribe((response) => {
        //console.log(response);
        $("#" + this.pageId + "DefaultCashMemoDetailsRptTableId")
          .DataTable()
          .destroy();
        this.defaultCashmemoRptDataList = [];
        if (response.length > 0) {
          console.log(response);
          this.defaultCashmemoRptDataList = response;
        } else {
          swal("Alert", "No Datas Found For This Information", "warning");
        }
        this.dtTriggerDefaultCashMemoDetailsRpt.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
        (error) => {
          this.showSpinnerForAction = false;
          swal(
            "Error",
            "Server Problem Occurred While getting Cash Memo details",
            "info"
          );
        },
        () => console.log("done");
    }
  }

  // All Transcation

  getAllTransaction(mode) {
    // if((this.rghtsForRole == "Super Administrator")&&
    //($("#"+this.pageId+"destinationId").val() == null || $("#"+this.pageId+"destinationId").val() == undefined ||
    //$("#"+this.pageId+"destinationId").val() == "")) {
    if (
      this.userDataDtoReturnSession.role == "Super Administrator" &&
      ($("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined ||
        $("#" + this.pageId + "destinationId").val() == "")
    ) {
      swal("ALERT", "Destination Is Mandatory Field", "warning");
      return false;
    } else {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateMemoType = $("#" + this.pageId + "memoTypeId").val();
      this.validateArea = $("#" + this.pageId + "deliveryAreaId").val();
      this.validateCollectionManName = $(
        "#" + this.pageId + "collectionManId"
      ).val();
      this.validateDestination = $("#" + this.pageId + "destinationId").val();
      this.validateLocalDestination = $(
        "#" + this.pageId + "localDestinationId"
      ).val();

      if (
        this.validateFromDate != null &&
        this.validateFromDate != undefined &&
        this.validateFromDate != ""
      ) {
        this.memoDto.fromdate = this.validateFromDate;
        this.memoDto.todate = this.validateToDate;
      }
      if (this.hideAndShowLocalDestination == true) {
        if (
          this.validateLocalDestination != null &&
          this.validateLocalDestination != undefined &&
          this.validateLocalDestination != "" &&
          this.validateLocalDestination == "All"
        ) {
          //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
          if (
            this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator"
          ) {
            this.memoDto.station = this.validateDestination;
          } else {
            this.memoDto.station = this.userDataDtoReturnSession.mainStation;
          }
          this.memoDto.godown = "all";
        } else {
          // this.memoDto.station = this.validateDestination;
          this.memoDto.station = this.validateLocalDestination;
          this.memoDto.godown = "specific";
        }
      } else {
        this.memoDto.godown = "specific";
        //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
        if (
          this.userDataDtoReturnSession.role != null &&
          this.userDataDtoReturnSession.role == "Super Administrator"
        ) {
          this.memoDto.station = this.validateDestination;
        } else {
          this.memoDto.station = this.userDataDtoReturnSession.office;
        }
      }
      this.memoDto.mode = mode;
      this.memoDto.withLetterHead = null;
      this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
      this.setBogusValue();
      //console.log(this.memoDto);
      this.memoDto.showAllLRDDLrs = this.showAllLRDDLrs;
      this.showSpinnerForAction = true;
      this.memoReport.getCashMemoReport(this.memoDto).subscribe((response) => {
        //console.log(response);
        $("#" + this.pageId + "allTransactionTableId")
          .DataTable()
          .destroy();
        this.allTransactionDataList = [];
        if (response.length > 0) {
          console.log(response);
          this.allTransactionDataList = response;
        } else {
          swal("Alert", "No Datas Found For This Information", "warning");
        }
        this.dtTriggerAllTransaction.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
        (error) => {
          this.showSpinnerForAction = false;
          swal(
            "Error",
            "Server Problem Occurred While getting  Cash Memo of All Transaction Details",
            "info"
          );
        },
        () => console.log("done");
    }
  }

  setBogusValue() {
    this.validateCheatCodeId = $("#" + this.pageId + "cheatCodeId").val();
    this.memoDto.column1 = this.validateCheatCodeId;
    this.memoDto.column2 = this.userDataDtoReturnSession.mainStation;
    this.validateCheckBoxAll = $("#" + this.pageId + "checkboxEnableAll").val();
    if (this.validateCheckboxEnable == true) {
      this.memoDto.column3 = "enable";
      if (this.validateCheckBoxAll == "All") {
        this.memoDto.reportMode = "All";
      } else {
        this.memoDto.reportMode = "Specific";
      }
    }
    console.log(this.memoDto);
  }

  //cashmemo consolidateRpt
  getConsolidatedMemoReport(mode) {
    // if((this.rghtsForRole == "Super Administrator")&&
    //($("#"+this.pageId+"destinationId").val() == null || $("#"+this.pageId+"destinationId").val() == undefined ||
    //$("#"+this.pageId+"destinationId").val() == "")) {
    if (
      this.userDataDtoReturnSession.role == "Super Administrator" &&
      ($("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined ||
        $("#" + this.pageId + "destinationId").val() == "")
    ) {
      swal("ALERT", "Destination Is Mandatory Field", "warning");
      return false;
    } else {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateMemoType = $("#" + this.pageId + "memoTypeId").val();
      this.validateArea = $("#" + this.pageId + "deliveryAreaId").val();
      this.validateCollectionManName = $(
        "#" + this.pageId + "collectionManId"
      ).val();
      this.validateDestination = $("#" + this.pageId + "destinationId").val();
      this.validateLocalDestination = $(
        "#" + this.pageId + "localDestinationId"
      ).val();

      if (
        this.validateFromDate != null &&
        this.validateFromDate != undefined &&
        this.validateFromDate != ""
      ) {
        this.memoDto.fromdate = this.validateFromDate;
        this.memoDto.todate = this.validateToDate;
      }

      if (this.hideAndShowLocalDestination == true) {
        if (
          this.validateLocalDestination != null &&
          this.validateLocalDestination != undefined &&
          this.validateLocalDestination != "" &&
          this.validateLocalDestination == "All"
        ) {
          //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
          if (
            this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator"
          ) {
            this.memoDto.station = this.validateDestination;
          } else {
            this.memoDto.station = this.userDataDtoReturnSession.mainStation;
          }
          this.memoDto.godown = "all";
        } else {
          this.memoDto.station = this.validateLocalDestination;
          this.memoDto.godown = "specific";
        }
      } else {
        this.memoDto.godown = "specific";
        //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
        if (
          this.userDataDtoReturnSession.role != null &&
          this.userDataDtoReturnSession.role == "Super Administrator"
        ) {
          this.memoDto.station = this.validateDestination;
        } else {
          this.memoDto.station = this.userDataDtoReturnSession.office;
        }
      }
      this.memoDto.mode = mode;
      this.memoDto.withLetterHead = null;
      this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
      //console.log(this.memoDto);
      this.showSpinnerForAction = true;
      this.memoReport
        .getConsolidatedMemoReport(this.memoDto)
        .subscribe((response) => {
          //console.log(response);
          $("#" + this.pageId + "consolidateMemoRptDetailsTableId")
            .DataTable()
            .destroy();
          this.cashmemoDetailDataList = [];
          if (response.length > 0) {
            console.log(response);
            this.cashmemoDetailDataList = response;
          } else {
            swal("Alert", "No Datas Found For This Information", "warning");
          }
          this.dtTriggerConsolidateMemoRpt.next();
          this.showSpinnerForAction = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerForAction = false;
          swal(
            "Error",
            "Server Problem Occurred While getting  Consolidated Memo Report Details",
            "info"
          );
        },
        () => console.log("done");
    }
  }

  //
  getConsolidatedSourceWise(mode) {
    // if((this.rghtsForRole == "Super Administrator")&&
    //($("#"+this.pageId+"destinationId").val() == null || $("#"+this.pageId+"destinationId").val() == undefined ||
    //$("#"+this.pageId+"destinationId").val() == "")) {
    if (
      this.userDataDtoReturnSession.role == "Super Administrator" &&
      ($("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined ||
        $("#" + this.pageId + "destinationId").val() == "")
    ) {
      swal("ALERT", "Destination Is Mandatory Field", "warning");
      return false;
    } else {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateMemoType = $("#" + this.pageId + "memoTypeId").val();
      this.validateArea = $("#" + this.pageId + "deliveryAreaId").val();
      this.validateCollectionManName = $(
        "#" + this.pageId + "collectionManId"
      ).val();
      this.validateDestination = $("#" + this.pageId + "destinationId").val();
      this.validateLocalDestination = $(
        "#" + this.pageId + "localDestinationId"
      ).val();

      if (
        this.validateFromDate != null &&
        this.validateFromDate != undefined &&
        this.validateFromDate != ""
      ) {
        this.memoDto.fromdate = this.validateFromDate;
        this.memoDto.todate = this.validateToDate;
      }
      if (this.hideAndShowLocalDestination == true) {
        if (
          this.validateLocalDestination != null &&
          this.validateLocalDestination != undefined &&
          this.validateLocalDestination != "" &&
          this.validateLocalDestination == "All"
        ) {
          //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
          if (
            this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator"
          ) {
            this.memoDto.station = this.validateDestination;
          } else {
            this.memoDto.station = this.userDataDtoReturnSession.mainStation;
          }
          this.memoDto.godown = "all";
        } else {
          this.memoDto.station = this.validateLocalDestination;
          this.memoDto.godown = "specific";
        }
      } else {
        this.memoDto.godown = "specific";
        //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
        if (
          this.userDataDtoReturnSession.role != null &&
          this.userDataDtoReturnSession.role == "Super Administrator"
        ) {
          this.memoDto.station = this.validateDestination;
        } else {
          this.memoDto.station = this.userDataDtoReturnSession.office;
        }
      }
      this.memoDto.mode = mode;
      this.memoDto.withLetterHead = null;
      this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
      this.showSpinnerForAction = true;
      //console.log(this.memoDto);
      this.memoReport
        .getConsolidatedMemoReportSourceWise(this.memoDto)
        .subscribe((response) => {
          //console.log(response);
          $("#" + this.pageId + "consolidatedMemoSourceRptTableId")
            .DataTable()
            .destroy();
          this.consolidatedMemoRptDataList = [];
          if (response.length > 0) {
            console.log(response);
            this.consolidatedMemoRptDataList = response;
          } else {
            swal("Alert", "No Datas Found For This Information", "warning");
          }
          this.dtTriggerConsolidatedMemoSourceRpt.next();
          this.showSpinnerForAction = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerForAction = false;
          swal(
            "Error",
            "Server Problem Occurred While getting  Consolidated Source Wise Details",
            "info"
          );
        },
        () => console.log("done");
    }
  }

  getConsolidatedMainSourceWise(mode) {
    if (
      this.userDataDtoReturnSession.role == "Super Administrator" &&
      ($("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined ||
        $("#" + this.pageId + "destinationId").val() == "")
    ) {
      swal("ALERT", "Destination Is Mandatory Field", "warning");
      return false;
    } else {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateMemoType = $("#" + this.pageId + "memoTypeId").val();
      this.validateArea = $("#" + this.pageId + "deliveryAreaId").val();
      this.validateCollectionManName = $(
        "#" + this.pageId + "collectionManId"
      ).val();
      this.validateDestination = $("#" + this.pageId + "destinationId").val();
      this.validateLocalDestination = $(
        "#" + this.pageId + "localDestinationId"
      ).val();

      if (
        this.validateFromDate != null &&
        this.validateFromDate != undefined &&
        this.validateFromDate != ""
      ) {
        this.memoDto.fromdate = this.validateFromDate;
        this.memoDto.todate = this.validateToDate;
      }
      if (this.hideAndShowLocalDestination == true) {
        if (
          this.validateLocalDestination != null &&
          this.validateLocalDestination != undefined &&
          this.validateLocalDestination != "" &&
          this.validateLocalDestination == "All"
        ) {
          //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
          if (
            this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator"
          ) {
            this.memoDto.station = this.validateDestination;
          } else {
            this.memoDto.station = this.userDataDtoReturnSession.mainStation;
          }
          this.memoDto.godown = "all";
        } else {
          this.memoDto.station = this.validateLocalDestination;
          this.memoDto.godown = "specific";
        }
      } else {
        this.memoDto.godown = "specific";
        //if(this.rghtsForRole !=null && this.rghtsForRole == "Super Administrator"){
        if (
          this.userDataDtoReturnSession.role != null &&
          this.userDataDtoReturnSession.role == "Super Administrator"
        ) {
          this.memoDto.station = this.validateDestination;
        } else {
          this.memoDto.station = this.userDataDtoReturnSession.office;
        }
      }
      this.memoDto.mode = mode;
      this.memoDto.withLetterHead = null;
      this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
      //console.log(this.memoDto);
      this.showSpinnerForAction = true;
      this.memoReport
        .getConsolidatedMemoReportSourceMainWise(this.memoDto)
        .subscribe((response) => {
          console.log(response);
          $("#" + this.pageId + "consolidatedMemoRptMainSrcTableId")
            .DataTable()
            .destroy();
          this.consolidatedMemoRptSrcDataList = [];
          if (response.length > 0) {
            console.log(response);
            this.consolidatedMemoRptSrcDataList = response;
          } else {
            swal("Alert", "No Datas Found For This Information", "warning");
          }
          this.dtTriggerConsolidatedMemoMainSourceWiseRpt.next();
          this.showSpinnerForAction = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerForAction = false;
          swal(
            "Error",
            "Server Problem Occurred While getting  Consolidated Main Source Wise Details",
            "info"
          );
        },
        () => console.log("done");
    }
  }

  getLrPodCopy(podCoryUrl) {
    if (
      podCoryUrl != null &&
      podCoryUrl != undefined &&
      podCoryUrl != "" &&
      podCoryUrl != "NA"
    ) {
      window.open(podCoryUrl);
    } else {
      swal("No POD", "No POD found for this LR!", "info");
    }

    // this.lrDtoPod = new LRDto();
    // this.lrDtoPod.lrNumber = lrnumber;

    // this.memoReport.getLRPodDetails(this.lrDtoPod).subscribe(
    //     (response) => {
    //         console.log('pod');
    //         if (response.viewPodLrCopy != null && response.viewPodLrCopy != undefined && response.viewPodLrCopy != '') {
    //             window.open(response.viewPodLrCopy);
    //         } else {
    //             swal("No POD", "No POD found for this LR!", "info");
    //         }
    //     }),
    //     (error) => {
    //         this.showSpinnerForAction = false;
    //         swal("Error", "Server Problem Occurred While getting  the POD details.", "info");
    //     },
    //     () => console.log('done');
  }

  customPrintCashMemoDetails() {
    if (this.cashmemoDetailDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Type",
        "Memos",
        "Art",
        "To Pay",
        "Paid",
        "DD",
        "UnLdg",
        this.columnLblGDLdgHamali,
        "Dem",
        "Rc",
        "Sc",
        "Oth",
        "Disc",
        "G.Total",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        6, 7, 6, 9, 9, 7, 7, 7, 6, 6, 6, 7, 7, 10,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      //heading logics For Date
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        moment(this.validateFromDate).format("DD-MM-YYYY"),
        moment(this.validateToDate).format("DD-MM-YYYY"),
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
        this.cashMemoDtoForCustomPrintListHeadingV2.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      this.toPaySummary =
        this.paidSummary =
        this.totArtSummary =
        this.ddSummary =
        this.unldgSummary =
        this.demGdSummary =
        this.rcChgSummary =
        this.scChgSummary =
        this.othersSummmary =
        this.discSummary =
        this.hamGdSummary =
        this.amtSummary =
        this.actWghtSummary =
        this.chgWghtSummary =
        this.memoSummary =
        this.gTotSummary =
          0;

      for (let i = 0; i < this.cashmemoDetailDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.cashmemoDetailDataList[i].memoType,
          this.cashmemoDetailDataList[i].numberofmemos,
          this.cashmemoDetailDataList[i].totalArticles,
          this.cashmemoDetailDataList[i].topay,
          this.cashmemoDetailDataList[i].paid,
          this.cashmemoDetailDataList[i].hamaliDD,
          this.cashmemoDetailDataList[i].unloadingHamali,
          this.cashmemoDetailDataList[i].hamaligd,
          this.cashmemoDetailDataList[i].demurrage,
          this.cashmemoDetailDataList[i].receiptCharges,
          this.cashmemoDetailDataList[i].serviceCharges,
          this.cashmemoDetailDataList[i].otherChgs,
          this.cashmemoDetailDataList[i].discount,
          this.cashmemoDetailDataList[i].grandTotal,
        ];

        /////////
        this.memoSummary =
          this.memoSummary + this.cashmemoDetailDataList[i].numberofmemos;
        this.totArtSummary =
          this.totArtSummary + this.cashmemoDetailDataList[i].totalArticles;
        this.actWghtSummary =
          this.actWghtSummary + this.cashmemoDetailDataList[i].actualWeight;
        this.chgWghtSummary =
          this.chgWghtSummary + this.cashmemoDetailDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.cashmemoDetailDataList[i].topay;
        this.paidSummary =
          this.paidSummary + this.cashmemoDetailDataList[i].paid;
        this.hamGdSummary =
          this.hamGdSummary + this.cashmemoDetailDataList[i].hamaligd;
        this.demGdSummary =
          this.demGdSummary + this.cashmemoDetailDataList[i].demurrage;
        this.rcChgSummary =
          this.rcChgSummary + this.cashmemoDetailDataList[i].receiptCharges;
        this.scChgSummary =
          this.scChgSummary + this.cashmemoDetailDataList[i].serviceCharges;
        this.discSummary =
          this.discSummary + this.cashmemoDetailDataList[i].discount;
        this.subTotSummary =
          this.subTotSummary + this.cashmemoDetailDataList[i].subTotal;
        this.gTotSummary =
          this.gTotSummary + this.cashmemoDetailDataList[i].grandTotal;
        this.ddSummary =
          this.amtSummary + this.cashmemoDetailDataList[i].hamaliDD;
        this.unldgSummary =
          this.unldgSummary + this.cashmemoDetailDataList[i].unloadingHamali;
        this.othersSummmary =
          this.othersSummmary + this.cashmemoDetailDataList[i].otherChgs;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total",
        this.memoSummary,
        this.totArtSummary,
        this.toPaySummary,
        this.paidSummary,
        this.ddSummary,
        this.unldgSummary,
        this.hamGdSummary,
        this.demGdSummary,
        this.rcChgSummary,
        this.scChgSummary,
        this.othersSummmary,
        this.discSummary,
        this.gTotSummary,
      ];

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );

      localStorage.setItem("printTitle", "Consolidate Memo Report");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  customPrintDefaultCashMemoDetailsRpt() {
    if (this.defaultCashmemoRptDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Memo No",
        "Party Name",
        "Area",
        "LR No",
        "Art",
        "Discount",
        "Hamali G.D",
        "Amount",
        "Act Wgt",
        "Chg Wgt",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        17, 19, 8, 12, 6, 9, 8, 7, 7, 7,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.totArtSummary =
        this.discSummary =
        this.hamGdSummary =
        this.amtSummary =
        this.actWghtSummary =
        this.chgWghtSummary =
          0;

      for (let i = 0; i < this.defaultCashmemoRptDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.defaultCashmemoRptDataList[i].memoNumber,
          this.defaultCashmemoRptDataList[i].consigneeName,
          this.defaultCashmemoRptDataList[i].area,
          this.defaultCashmemoRptDataList[i].lrNumber,
          this.defaultCashmemoRptDataList[i].totalArticles,
          this.defaultCashmemoRptDataList[i].discount,
          this.defaultCashmemoRptDataList[i].hamaligd,
          this.defaultCashmemoRptDataList[i].amount,
          this.defaultCashmemoRptDataList[i].actualWeight,
          this.defaultCashmemoRptDataList[i].chargedWeight,
        ];

        /////////
        this.totArtSummary =
          this.totArtSummary + this.defaultCashmemoRptDataList[i].totalArticles;
        this.discSummary =
          this.discSummary + this.defaultCashmemoRptDataList[i].discount;
        this.hamGdSummary =
          this.hamGdSummary + this.defaultCashmemoRptDataList[i].hamaligd;
        this.amtSummary =
          this.amtSummary + this.defaultCashmemoRptDataList[i].amount;
        this.actWghtSummary =
          this.actWghtSummary + this.defaultCashmemoRptDataList[i].actualWeight;
        this.chgWghtSummary =
          this.chgWghtSummary +
          this.defaultCashmemoRptDataList[i].chargedWeight;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.defaultCashmemoRptDataList.length,
        "",
        "",
        "",
        this.totArtSummary,
        this.discSummary,
        this.hamGdSummary,
        this.amtSummary,
        this.actWghtSummary,
        this.chgWghtSummary,
      ];

      //heading logics For Date
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [
        "From Date",
        "To Date",
      ];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        this.validateFromDate,
        this.validateToDate,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );
      // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
      // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
      localStorage.setItem("printTitle", "Daily Cash Memo - Cash");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  customPrintConsolidatedMemoRpt() {
    if (this.consolidatedMemoRptDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Memos",
        "State",
        "Art",
        "Act Wgt",
        "Chg Wgt",
        "To Pay",
        "Paid",
        "G.D",
        "Dem",
        "RC",
        "SC",
        "Disc",
        "S.Total",
        "G.Total",
        "DD",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        9, 9, 6, 6, 6, 7, 5, 6, 6, 5, 5, 7, 8, 8, 7,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.totArtSummary =
        this.actWghtSummary =
        this.chgWghtSummary =
        this.toPaySummary =
        this.paidSummary =
        this.hamGdSummary =
        this.demGdSummary =
        this.rcChgSummary =
        this.scChgSummary =
        this.discSummary =
        this.subTotSummary =
        this.gTotSummary =
        this.ddSummary =
          0;

      for (let i = 0; i < this.consolidatedMemoRptDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.consolidatedMemoRptDataList[i].numberofmemos,
          this.consolidatedMemoRptDataList[i].mainStation,
          this.consolidatedMemoRptDataList[i].totalArticles,
          this.consolidatedMemoRptDataList[i].actualWeight,
          this.consolidatedMemoRptDataList[i].chargedWeight,
          this.consolidatedMemoRptDataList[i].topay,
          this.consolidatedMemoRptDataList[i].paid,
          this.consolidatedMemoRptDataList[i].hamaligd,
          this.consolidatedMemoRptDataList[i].demurrage,
          this.consolidatedMemoRptDataList[i].receiptCharges,
          this.consolidatedMemoRptDataList[i].serviceCharges,
          this.consolidatedMemoRptDataList[i].discount,
          this.consolidatedMemoRptDataList[i].subTotal,
          this.consolidatedMemoRptDataList[i].grandTotal,
          this.consolidatedMemoRptDataList[i].hamaliDD,
        ];

        /////////

        this.totArtSummary =
          this.totArtSummary +
          this.consolidatedMemoRptDataList[i].totalArticles;
        this.actWghtSummary =
          this.actWghtSummary +
          this.consolidatedMemoRptDataList[i].actualWeight;
        this.chgWghtSummary =
          this.chgWghtSummary +
          this.consolidatedMemoRptDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.consolidatedMemoRptDataList[i].topay;
        this.paidSummary =
          this.paidSummary + this.consolidatedMemoRptDataList[i].paid;
        this.hamGdSummary =
          this.hamGdSummary + this.consolidatedMemoRptDataList[i].hamaligd;
        this.demGdSummary =
          this.demGdSummary + this.consolidatedMemoRptDataList[i].demurrage;
        this.rcChgSummary =
          this.rcChgSummary +
          this.consolidatedMemoRptDataList[i].receiptCharges;
        this.scChgSummary =
          this.scChgSummary +
          this.consolidatedMemoRptDataList[i].serviceCharges;
        this.discSummary =
          this.discSummary + this.consolidatedMemoRptDataList[i].discount;
        this.subTotSummary =
          this.subTotSummary + this.consolidatedMemoRptDataList[i].subTotal;
        this.gTotSummary =
          this.gTotSummary + this.consolidatedMemoRptDataList[i].grandTotal;
        this.ddSummary =
          this.ddSummary + this.consolidatedMemoRptDataList[i].hamaliDD;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.consolidatedMemoRptDataList.length,
        "",
        this.totArtSummary,
        this.actWghtSummary,
        this.chgWghtSummary,
        this.toPaySummary,
        this.paidSummary,
        this.hamGdSummary,
        this.demGdSummary,
        this.rcChgSummary,
        this.scChgSummary,
        this.discSummary,
        this.subTotSummary,
        this.gTotSummary,
        this.ddSummary,
      ];

      //heading logics For Date
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [
        "From Date",
        "To Date",
      ];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        this.validateFromDate,
        this.validateToDate,
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );

      localStorage.setItem("printTitle", "Cash Memo Report Source Wise");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  customPrintConsolidatedMemoRptSrc() {
    if (this.consolidatedMemoRptSrcDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Branch",
        "Memos",
        "Art",
        "Act Wgt",
        "Chg Wgt",
        "To Pay",
        "Paid",
        "G.D",
        "Dem",
        "RC",
        "SC",
        "Disc",
        "G.Total",
        "DD",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        10, 7, 5, 8, 8, 8, 6, 6, 6, 6, 6, 7, 10, 7,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.toPaySummary =
        this.paidSummary =
        this.totArtSummary =
        this.ddSummary =
        this.unldgSummary =
        this.demGdSummary =
        this.rcChgSummary =
        this.scChgSummary =
        this.othersSummmary =
        this.discSummary =
        this.hamGdSummary =
        this.amtSummary =
        this.actWghtSummary =
        this.chgWghtSummary =
        this.gTotSummary =
        this.memoSummary =
          0;

      for (let i = 0; i < this.consolidatedMemoRptSrcDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.consolidatedMemoRptSrcDataList[i].mainStation,
          this.consolidatedMemoRptSrcDataList[i].numberofmemos,
          this.consolidatedMemoRptSrcDataList[i].totalArticles,
          this.consolidatedMemoRptSrcDataList[i].actualWeight,
          this.consolidatedMemoRptSrcDataList[i].chargedWeight,
          this.consolidatedMemoRptSrcDataList[i].topay,
          this.consolidatedMemoRptSrcDataList[i].paid,
          this.consolidatedMemoRptSrcDataList[i].hamaligd,
          this.consolidatedMemoRptSrcDataList[i].demurrage,
          this.consolidatedMemoRptSrcDataList[i].receiptCharges,
          this.consolidatedMemoRptSrcDataList[i].serviceCharges,
          this.consolidatedMemoRptSrcDataList[i].discount,
          this.consolidatedMemoRptSrcDataList[i].grandTotal,
          this.consolidatedMemoRptSrcDataList[i].hamaliDD,
        ];

        /////////
        this.memoSummary =
          this.memoSummary +
          this.consolidatedMemoRptSrcDataList[i].numberofmemos;
        this.totArtSummary =
          this.totArtSummary +
          this.consolidatedMemoRptSrcDataList[i].totalArticles;
        this.actWghtSummary =
          this.actWghtSummary +
          this.consolidatedMemoRptSrcDataList[i].actualWeight;
        this.chgWghtSummary =
          this.chgWghtSummary +
          this.consolidatedMemoRptSrcDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.consolidatedMemoRptSrcDataList[i].topay;
        this.paidSummary =
          this.paidSummary + this.consolidatedMemoRptSrcDataList[i].paid;
        this.hamGdSummary =
          this.hamGdSummary + this.consolidatedMemoRptSrcDataList[i].hamaligd;
        this.demGdSummary =
          this.demGdSummary + this.consolidatedMemoRptSrcDataList[i].demurrage;
        this.rcChgSummary =
          this.rcChgSummary +
          this.consolidatedMemoRptSrcDataList[i].receiptCharges;
        this.scChgSummary =
          this.scChgSummary +
          this.consolidatedMemoRptSrcDataList[i].serviceCharges;
        this.discSummary =
          this.discSummary + this.consolidatedMemoRptSrcDataList[i].discount;
        this.subTotSummary =
          this.subTotSummary + this.consolidatedMemoRptSrcDataList[i].subTotal;
        this.gTotSummary =
          this.gTotSummary + this.consolidatedMemoRptSrcDataList[i].grandTotal;
        this.ddSummary =
          this.ddSummary + this.consolidatedMemoRptSrcDataList[i].hamaliDD;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total",
        this.memoSummary,
        this.totArtSummary,
        this.actWghtSummary,
        this.chgWghtSummary,
        this.toPaySummary,
        this.paidSummary,
        this.hamGdSummary,
        this.demGdSummary,
        this.rcChgSummary,
        this.scChgSummary,
        this.discSummary,
        this.gTotSummary,
        this.ddSummary,
      ];

      //heading logics For Date
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        moment(this.validateFromDate).format("DD-MM-YYYY"),
        moment(this.validateToDate).format("DD-MM-YYYY"),
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );

      localStorage.setItem("printTitle", "Cash Memo Report Main Source");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  customPrintAllTransaction() {
    if (this.allTransactionDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Cash Memo",
        "LR No.",
        "No.of.Art",
        "Act Wgt",
        "Chg Wgt",
        "To Pay",
        "Paid",
        "G.D",
        "RC",
        "Disc",
        "Dem",
        "SC",
        "Total",
        "G.Total",
        "Type",
        "DD",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        12, 10, 6, 6, 6, 6, 5, 5, 5, 5, 5, 5, 6, 6, 7, 5,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.totArtSummary =
        this.discSummary =
        this.hamGdSummary =
        this.amtSummary =
        this.actWghtSummary =
        this.chgWghtSummary =
          0;

      for (let i = 0; i < this.allTransactionDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.allTransactionDataList[i].memoNumber,
          this.allTransactionDataList[i].lrNumber,
          this.allTransactionDataList[i].totalArticles,
          this.allTransactionDataList[i].actualWeight,
          this.allTransactionDataList[i].chargedWeight,
          this.allTransactionDataList[i].topay,
          this.allTransactionDataList[i].paid,
          this.allTransactionDataList[i].hamaligd,
          this.allTransactionDataList[i].receiptCharges,
          this.allTransactionDataList[i].discount,
          this.allTransactionDataList[i].demurrage,
          this.allTransactionDataList[i].serviceCharges,
          this.allTransactionDataList[i].subTotal,
          this.allTransactionDataList[i].grandTotal,
          this.allTransactionDataList[i].memoType,
          this.allTransactionDataList[i].hamaliDD,
        ];

        /////////

        this.totArtSummary =
          this.totArtSummary + this.allTransactionDataList[i].totalArticles;
        this.actWghtSummary =
          this.actWghtSummary + this.allTransactionDataList[i].actualWeight;
        this.chgWghtSummary =
          this.chgWghtSummary + this.allTransactionDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.allTransactionDataList[i].topay;
        this.paidSummary =
          this.paidSummary + this.allTransactionDataList[i].paid;
        this.hamGdSummary =
          this.hamGdSummary + this.allTransactionDataList[i].hamaligd;
        this.demGdSummary =
          this.demGdSummary + this.allTransactionDataList[i].demurrage;
        this.rcChgSummary =
          this.rcChgSummary + this.allTransactionDataList[i].receiptCharges;
        this.scChgSummary =
          this.scChgSummary + this.allTransactionDataList[i].serviceCharges;
        this.discSummary =
          this.discSummary + this.allTransactionDataList[i].discount;
        this.subTotSummary =
          this.subTotSummary + this.allTransactionDataList[i].subTotal;
        this.gTotSummary =
          this.gTotSummary + this.allTransactionDataList[i].grandTotal;
        this.ddSummary =
          this.amtSummary + this.allTransactionDataList[i].hamaliDD;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.allTransactionDataList.length,
        "",
        this.totArtSummary,
        this.actWghtSummary,
        this.chgWghtSummary,
        this.toPaySummary,
        this.paidSummary,
        this.hamGdSummary,
        this.demGdSummary,
        this.rcChgSummary,
        this.scChgSummary,
        this.discSummary,
        this.subTotSummary,
        this.gTotSummary,
        "",
        this.ddSummary,
      ];

      //heading logics For Date
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [
        "From Date",
        "To Date",
      ];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        moment(this.validateFromDate).format("DD-MM-YYYY"),
        moment(this.validateToDate).format("DD-MM-YYYY"),
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );

      localStorage.setItem("printTitle", "All Transaction Report");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }
}
