import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import { MemoService } from "src/app/dataService/memo-service";
@Component({
    selector: 'app-paymentlink-transaction-details',
    templateUrl: './paymentlink-transaction-details.component.html',
    styleUrls: ['./paymentlink-transaction-details.component.css']
})
export class PaymentLinkTransactionDetailsComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "pltdr";
    dtTriggerPaymentLinkOnlineTrxDetails: Subject<any> = new Subject();
    dtOptionsPaymentLinkOnlineTrxDetails: any;

    showSpinnerForAction = false;
    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoCollectionManAll: UserDataDto = new UserDataDto();
    cashmemoForGet: CashMemoDto = new CashMemoDto();
    selectedFromDate: any;
    selectedToDate: any;
    selectedCollectionMan: any;

    toSetDestForColMan: any;
    selecDestinationForTemp: any;
    public modelDestinationFotGet: any;
    destMemoOnlinePaymentDetailLists: any;
    cashmemoUpdate: CashMemoDto = new CashMemoDto();
    cashmemoRet: any;
    collectionManAllOption: UserDataDto = new UserDataDto();
    collectionManTA: Array<UserDataDto> = [];
    collectionManTATemp: Array<UserDataDto> = [];
    collectionManTATempForDest: Array<UserDataDto> = [];

    focusCollectionManTA$ = new Subject<string>();
    collectionManSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManTA
                : this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
    collectionManDataList: any;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    colmrIsChecked = false;

    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    viewDestination = false;
    destView = false;
    selectedDestination: any;
    sendCollectionCloseWhatsappUpdates: boolean = false;
    userDataDtoForWhatsApp: UserDataDto = new UserDataDto();
    constructor(private masterReadService: MasterReadService, private router: Router,
        public changeDetectorRef: ChangeDetectorRef, private memoReport: MemoReport,
        private memoService: MemoService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.toSetDestForColMan = this.userDataDtoReturnSession.mainStation;

            this.getCollectionManDetailsList(false);
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Online Payment DestView") {
                        this.destView = true;
                    }
                }
            }
            if (this.destView == true) {
                this.viewDestination = true;
                this.getDestinationDetails();
            } else {
                this.viewDestination = false;
            }
            if (this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp != null && this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp) {
                this.sendCollectionCloseWhatsappUpdates = true;
            }
        }

    }

    ngOnInit() {
        var groupColumnTrxIdCol = 0;
        this.dtOptionsPaymentLinkOnlineTrxDetails = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "Payment Link Trx Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var count = 0;
                var paymentAmt = 0;
                var articles = 0;
                var toPay = 0;
                api.column(groupColumnTrxIdCol, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 2,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paymentAmt
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 4,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": articles
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));



                            count = 0;
                            paymentAmt = 0;
                            articles = 0;
                            toPay = 0;

                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 15,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }


                    count++;
                    paymentAmt += +val[4];
                    articles += +val[9];
                    toPay += +val[11];

                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 2,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paymentAmt
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 4,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": articles
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .prop('outerHTML'));

                        paymentAmt = 0;
                        articles = 0;
                        toPay = 0;
                    }
                });
            },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var amount = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var article = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(amount);

                $(api.column(9).footer()).html(article);
                $(api.column(11).footer()).html(toPay);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerPaymentLinkOnlineTrxDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerPaymentLinkOnlineTrxDetails.next();
    }



    getDetailsForCollectionMasterRead(destShow) {
        this.userDtoCollectionMan = new UserDataDto();
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";

    }

    getCollectionManDetailsList(destShow) {
        this.collectionManDataList = [];
        this.collectionManTA = [];
        this.getDetailsForCollectionMasterRead(destShow);
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Collection Man Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.collectionManDataList = [];
                    this.collectionManTA = [];
                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    $("#" + this.pageId + "collectionMan").val('All');
                } else {
                    console.log(response);
                    this.collectionManDataList = response;
                    console.log(this.collectionManDataList);
                    this.collectionManTA = [];
                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    for (let i = 0; i < this.collectionManDataList.length; i++) {
                        this.collectionManTA.push(this.collectionManDataList[i]);
                    }
                    this.selecDestinationForTemp = this.toSetDestForColMan;
                    if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
                        this.collectionManTATempForDest = this.collectionManTA;
                    }
                    this.toSetDestForColMan = '';
                    console.log(this.collectionManTATempForDest, this.selecDestinationForTemp)
                    this.collectionManTATemp = this.collectionManTA;

                    $("#" + this.pageId + "collectionMan").val('All');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
            }, () => console.log('done');
    };



    getPaymentTrxDetails() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.cashmemoForGet = new CashMemoDto();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return false;
        } else {
            this.cashmemoForGet.fromdate = this.selectedFromDate;
            this.cashmemoForGet.todate = this.selectedToDate;
            this.cashmemoForGet.tillDateMode = "false";
        }

        this.cashmemoForGet.reportMode = 'All';
        this.cashmemoForGet.mode = 'amt';
        this.cashmemoForGet.companyId = this.userDataDtoReturnSession.companyId;


        if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
            this.cashmemoForGet.collectionMan = this.selectedCollectionMan;
        } else {
            this.cashmemoForGet.collectionMan = 'All';
        }

        this.cashmemoForGet.consigneeName = 'All';
        if (this.destView == true) {
            if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
                this.cashmemoForGet.office = this.selectedDestination;
            } else {
                this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;
            }
        } else {
            this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;
        }
        console.log(this.cashmemoForGet);
        this.getPaymentLinkOnlineTrxDtls();
    }

    getPaymentLinkOnlineTrxDtls = () => {
        this.showSpinnerForAction = true;
        this.memoReport.getPaymentLinkOnlineTrxDetails(this.cashmemoForGet).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "paymentLinkOnlineTrxDetailsTableId").DataTable().destroy();
                this.destMemoOnlinePaymentDetailLists = [];
                if (response.consigneeWiseMemo.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destMemoOnlinePaymentDetailLists = response.consigneeWiseMemo;
                }
                this.dtTriggerPaymentLinkOnlineTrxDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Cashmemo Online Payment Details", "info");
            }, () => console.log('done');
    };

    clearDetails() {
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "collectionMan").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedCollectionMan = '';
        this.cashmemoForGet = new CashMemoDto();
        $("#" + this.pageId + "paymentLinkOnlineTrxDetailsTableId").DataTable().destroy();
        this.destMemoOnlinePaymentDetailLists = [];
        this.dtTriggerPaymentLinkOnlineTrxDetails.next();
        $("#" + this.pageId + "failedTrxChk").prop("checked", false);


    }

    
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                        $("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };





}
