<div class="row" *ngIf="isLoggedIn" id="{{pageId}}prtBUdt">
    <div class="col-lg-12">
        <div class="card" style="border: 1px solid darkcyan !important;">
            <div *ngIf="showSpinnerForAction" class="col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                            Please Wait Getting E-Way Bill Details.....</h6>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>E-Way Bill No *</label>
                                <input #ewayBillNo type="text" id="{{pageId}}ewayBillNo" class="form-control"
                                    placeholder="Search by EWay Bill No" aria-describedby="basic-addon11"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button type="submit" class="btn btn-success" (click)="validateForEWayBillNo();"
                            id="{{pageId}}searchBtn">
                            Search
                        </button>
                        <button type="submit" class="btn btn-dark" (click)="clearField();" id="{{pageId}}clearBtn">
                            Clear
                        </button>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <label><strong>From Address:</strong></label>
                                <label>{{fromAddressDtls}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <label><strong>To Address:</strong></label>
                                <label>{{toAddressDtls}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div *ngIf="showSpinnerForActionForVeh" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Updating the E-Way Bill Details.....</h6>
                        </div>
                    </div>
                </div>
                <h5 class="text-primary">Update Part - B</h5>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Vehicle No *</label>
                                <input #vehicleNo type="text" id="{{pageId}}vehicleNo" class="form-control"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Place of Change *</label>
                                <input #placeChange type="text" id="{{pageId}}placeChange" class="form-control"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>State Name *</label>
                                <input #stateCode id="{{pageId}}stateCode" type="text" class="form-control"
                                    [(ngModel)]="modelStateNameAndCode" [ngbTypeahead]="searchStateNameAndCode"
                                    [resultFormatter]="formatterStateNameAndCode"
                                    [inputFormatter]="formatterStateNameAndCode"
                                    (selectItem)="clickListnerForState($event)"
                                    (focus)="focusStateNameAndCodeTA$.next($any($event).target.value)"
                                    autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Reason *</label>
                                <select class="custom-select" id="{{pageId}}reason" name="reason" #reason>
                                    <option value="Due to Break Down">Due to Break Down</option>
                                    <option value="Due to Transshipment">Due to Transshipment</option>
                                    <option value="Others">Others</option>
                                    <option value="First Time">First Time</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Transporter Doc No</label>
                                <input #transDocNo type="text" id="{{pageId}}transDocNo" class="form-control"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button type="submit" class="btn btn-primary m-r-10" (click)="validateEwayPartBUpdate();"
                            id="{{pageId}}updateBtn">
                            Update
                        </button>
                        <button type="submit" class="btn btn-dark" (click)="clearFields();" id="{{pageId}}clearBtn">
                            Clear
                        </button>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <table datatable id="{{pageId}}vehicleDetailsTable"
                                    class="table table-striped table-bordered row-border hover"
                                    [dtOptions]="dtOptionsVehicleDtls" [dtTrigger]="dtTriggerVehicleDtls">
                                    <thead>
                                        <tr>
                                            <th>Trans Mode</th>
                                            <th>Vehicle No / Trans Doc No</th>
                                            <th>From Place</th>
                                            <th>Updated By</th>
                                            <th>Updated Date</th>
                                            <th>Cons.EWB No.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let vehicle of vehicleDetails">
                                            <td>Road</td>
                                            <td>{{ vehicle.vehicleNo }} / {{ vehicle.transDocNo }}</td>
                                            <td>{{ vehicle.changePlace }}</td>
                                            <td>{{ vehicle.enteredBy }}</td>
                                            <td>{{ vehicle.enteredDate }}</td>
                                            <td>{{ vehicle.cewbNo }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>