import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { LrEnquiryFormComponent } from 'src/app/lr/enquiry/lr-enquiry-form/lr-enquiry-form.component';
import { LrAdvanceComponent } from 'src/app/lr/enquiry/lr-advance/lr-advance.component';
import { ConsigneeDetailsComponent } from 'src/app/lr/enquiry/consignee-details/consignee-details.component';
import { LrDeliveryStatusComponent } from 'src/app/lr/enquiry/lr-delivery-status/lr-delivery-status.component';
import { MaterialModule } from "src/app/material.module";
import { EntryModule } from 'src/app/lr/entry/entry.module';
import { ShortExtraLrEnquiryPopupComponent } from "src/app/lr/enquiry/short-extra-lr-enquiry-popup/short-extra-lr-enquiry-popup.component";
import { MultipleArticleSizePopupComponent } from "src/app/lr/enquiry/multiple-article-size-popup/multiple-article-size-popup.component";
import { BayInfoPopupComponent } from "src/app/lr/enquiry/bay-details-popup/bay-info-popup.component";
import { MultipleArticleSizeV2PopupComponent } from './multiple-article-size-V2-popup/multiple-article-size-V2-popup.component';
import { MultipleArticleSizeV3PopupComponent } from './multiple-article-size-V3-popup copy/multiple-article-size-V3-popup.component';
import { PodInfoPopupComponent } from './pod-details-popup/pod-info-popup.component';
import { MultipleArticleRangePopupComponent } from "src/app/lr/enquiry/multiple-article-range-popup/multiple-article-range-popup.component";
import { MultiEwayDetailsPopupComponent } from './multi-eway-details-popup/multi-eway-details-popup.component';
import { LrRateCalculatorComponent } from './lr-rate-calculator/lr-rate-calculator.component';
@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( EnquiryRoutes ),
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, EntryModule],
    declarations: [
        LrEnquiryFormComponent,
        LrAdvanceComponent,
        ConsigneeDetailsComponent,
        LrDeliveryStatusComponent,
        ShortExtraLrEnquiryPopupComponent,
        MultipleArticleSizePopupComponent,
        BayInfoPopupComponent,
        MultipleArticleSizeV2PopupComponent,
        MultipleArticleSizeV3PopupComponent,
        PodInfoPopupComponent,
        MultipleArticleRangePopupComponent,
        MultiEwayDetailsPopupComponent,
        LrRateCalculatorComponent
    ],
    entryComponents: [ShortExtraLrEnquiryPopupComponent, MultipleArticleSizePopupComponent, BayInfoPopupComponent, MultipleArticleSizeV2PopupComponent, MultipleArticleSizeV3PopupComponent, PodInfoPopupComponent,MultipleArticleRangePopupComponent, MultiEwayDetailsPopupComponent],
    exports: [MultipleArticleSizePopupComponent, MultipleArticleSizeV2PopupComponent, MultipleArticleSizeV3PopupComponent,MultipleArticleRangePopupComponent]
})
export class EnquiryModule { }
