<head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>

<!-- <body> -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showStockTripSheetMain">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <div class="row">
                    <div class="col-md-12">
                        <h6 class="card-title text-white">Stocks For Tripsheet</h6>
                    </div>
                </div>
            </div>
            <div class="row system_responsive" style="margin-bottom: 5px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-horizontal-stepper [linear]="false" #stepper>
                                    <!-- one starts -->
                                    <mat-step [stepControl]="firstFormGroup">
                                        <!--<form [formGroup]="firstFormGroup">-->
                                        <ng-template matStepLabel>Stocks For Local Booking</ng-template>
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForAction" class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center'
                                                            style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="card-body">
                                                    <div class="box-body">
                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                                            Stocks Summary</h6>
                                                        <table datatable id="{{pageId}}stockSummaryTableId"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsStockSummary"
                                                            [dtTrigger]="dtTriggerStockSummary">
                                                            <thead>
                                                                <tr>
                                                                    <th>Destination</th>
                                                                    <th>Tot LRs</th>
                                                                    <th>Art</th>
                                                                    <th>Act Wgt</th>
                                                                    <th>Chg Wgt</th>
                                                                    <th>Amount</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let stockSummaryData of stockSummaryDataList ">
                                                                    <td>{{ stockSummaryData.localTripSpeLogicStations }}
                                                                    </td>
                                                                    <td>{{ stockSummaryData.totalLrs }}</td>
                                                                    <td>{{ stockSummaryData.totalArticles }}</td>
                                                                    <td>{{ stockSummaryData.actualWeight }}</td>
                                                                    <td>{{ stockSummaryData.chargedWeight }}</td>
                                                                    <td>{{ stockSummaryData.totalAmount }}</td>
                                                                    <td><button type="button"
                                                                            style="padding: 1px 4px; background-color: lightgrey;"
                                                                            class="btn m-r-10"
                                                                            id="{{pageId}}tableLRDetailsBtn"
                                                                            (click)="rowSelectedGetTripLRDeatils(stockSummaryData);">
                                                                            <i title="Get Total LR Details"
                                                                                class="fas fa-eye"></i>
                                                                        </button></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="card-body">
                                                    <div class="box-body">
                                                        <h6 id="{{pageId}}viewBinsStockMsgId"
                                                            style="border-bottom: 1px solid orange;" class="card-title">
                                                            {{viewBinsStockMsg}}</h6>
                                                        <table datatable
                                                            id="{{pageId}}localBookingForStockDetailsTableId"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsStockDetails"
                                                            [dtTrigger]="dtTriggerStockDetails">
                                                            <thead>
                                                                <tr>
                                                                    <th>LR No</th>
                                                                    <th>Consignee</th>
                                                                    <th>Destination</th>
                                                                    <th>Agent Name</th>
                                                                    <th>Art</th>
                                                                    <th>Act Wgt</th>
                                                                    <th>Chg Wgt</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let stockDetailsData of stockDetailsDataList ">

                                                                    <td>{{stockDetailsData.lrNumber }}</td>
                                                                    <td>{{ stockDetailsData.consigneeName }}</td>
                                                                    <td>{{ stockDetailsData.destination }}</td>
                                                                    <td>{{ stockDetailsData.agentName }}</td>
                                                                    <td>{{ stockDetailsData.totalArticles }}</td>
                                                                    <td>{{ stockDetailsData.actualWeight }}</td>
                                                                    <td>{{ stockDetailsData.chargedWeight }}</td>
                                                                    <td>{{ stockDetailsData.totalAmount }}</td>

                                                                </tr>
                                                            </tbody>
                                                            <!---->
                                                            <tfoot>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="text-align: right;">
                                                <!-- <button class="btn btn-dark m-r-10" id="{{pageId}}tripSheetBtnId"
											*ngIf="tripSheetBlackBtnHideShow"
											(click)="tripSheetBlackBtnClickListner();">Trip
											Sheet</button>
										<button class="btn btn-success m-r-10" id="{{pageId}}tripSheetBtnId"
											*ngIf="tripSheetBtnHideShow" mat-button matStepperNext
											(click)="tripSheetBlackBtnClickListner();">Trip Sheet</button> -->
                                                <button class="btn btn-success m-r-10" id="{{pageId}}tripSheetBtnId"
                                                    *ngIf="tripSheetBlackBtnHideShow" mat-button matStepperNext
                                                    (click)="tripSheetBlackBtnClickListner();">Trip Sheet</button>
                                            </div>
                                        </div>
                                        <!--</form>-->
                                    </mat-step>
                                    <!-- one flow is ends -->
                                    <!-- two flow is starts  -->
                                    <mat-step [stepControl]="secondFormGroup">
                                        <!--<form [formGroup]="secondFormGroup">-->
                                        <ng-template matStepLabel>Trip Schedule For <span
                                                style="color: blue;">{{viewLocalTripsheetMsg}} </span></ng-template>
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForTripScheduleAction" class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center'
                                                            style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2 ">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" style="display: flex; gap: 0;">
                                                                    <!-- <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="fas fa-clipboard-list"></i>
                                                                        </span>
                                                                    </div> -->
                                                                    <input type="text" id="{{pageId}}lrNumberPrefix"
                                                                        class="form-control"
                                                                        aria-describedby="basic-addon11"
                                                                        autocomplete="off"
                                                                        [(ngModel)]="showLrCodeInputFields"
                                                                        style="flex: 0 0 6ch;">
                                                                    <input type="text" Placeholder="LR Number"
                                                                        class="form-control" id="{{pageId}}lrNumber"
                                                                        autocomplete="off"
                                                                        (keypress)="onKeyPressListnerForLRNumber($event);"
                                                                        aria-describedby="basic-addon11"
                                                                        style="flex: 1;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="control">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-search"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input #binsDropDown
                                                                            id="{{pageId}}dropDownInputFieldBins"
                                                                            type="text" class="form-control"
                                                                            (selectItem)="clickListnerForDropDownInputFieldBins($event)"
                                                                            [(ngModel)]="modelDropDownBins"
                                                                            [ngbTypeahead]="searchDropDownInputFieldBins"
                                                                            [resultFormatter]="formatterDropDownBins"
                                                                            [inputFormatter]="formatterDropDownBins"
                                                                            placeholder="Please Select The Bins"
                                                                            (keypress)="onKeyPressListnerForBinsDestination($event);"
                                                                            (focus)="focusDropDownBinsTA$.next($any($event).target.value)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-10 vl">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="box-body">
                                                                <h6 class="card-title">Eligible LRs</h6>
                                                                <table datatable id="{{pageId}}eligibleLrsTableId"
                                                                    class="table table-striped table-bordered row-border hover"
                                                                    [dtOptions]="dtOptionsEligibleLrs"
                                                                    [dtTrigger]="dtTriggerEligibleLrs">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Action</th>
                                                                            <th>LR Number</th>
                                                                            <th>Articles</th>
                                                                            <th>Consignor</th>
                                                                            <th>Consignee</th>
                                                                            <th>Agent Name</th>
                                                                            <th>Booking Date</th>
                                                                            <th>Actual Wgt</th>
                                                                            <th>Charged Wgt</th>
                                                                            <th>Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let eligibleLrsData of eligibleLrsDataList ">
                                                                            <td><button type="button"
                                                                                    style="padding: 1px 4px; background-color: lightgrey;"
                                                                                    class="btn m-r-10"
                                                                                    (click)="rowSelectedEligibleLrDelete(eligibleLrsData);">
                                                                                    <i title="Remove From Eligible Lrs"
                                                                                        class="fas fa-trash"></i>
                                                                                </button></td>
                                                                            <td>{{ eligibleLrsData.lrNumber }}</td>
                                                                            <td>{{ eligibleLrsData.totalArticles }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.consignorName }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.consigneeName }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.agentName }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.bookingDateStr }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.actualWeight }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.chargedWeight }}
                                                                            </td>
                                                                            <td>{{ eligibleLrsData.totalAmount }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 vl">
                                                            <button style='float: right;' class="btn btn-dark m-r-10"
                                                                (click)="validateMoveAllBtnForScheduleTrip();"
                                                                title="Move All To Eligible Lrs"
                                                                id="{{pageId}}moveAllBtn">Move
                                                                All</button>
                                                            <button style='float: right;' class="btn btn-dark m-r-10"
                                                                (click)="validateMoveSelectedLrs();"
                                                                title="Move Selected To Eligible Lrs"
                                                                id="{{pageId}}moveAllBtn">Move
                                                                Selected</button>
                                                            <div class="box-body">
                                                                <h6 class="card-title">Remaining LRs</h6>
                                                                <table datatable id="{{pageId}}remainingLrsTableId"
                                                                    class="table table-striped table-bordered row-border hover"
                                                                    [dtOptions]="dtOptionRemainingLrs"
                                                                    [dtTrigger]="dtTriggerRemainingLrs">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Action</th>
                                                                            <th>LR Number</th>
                                                                            <th>Articles</th>
                                                                            <th>Consignor</th>
                                                                            <th>Consignee</th>
                                                                            <th>Agent Name</th>
                                                                            <th>Booking Date</th>
                                                                            <th>Actual Wgt</th>
                                                                            <th>Charged Wgt</th>
                                                                            <th>Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let remainingLrsData of remainingLrsDataList; let i=index ">
                                                                            <td><input type="checkbox"
                                                                                    [checked]="multiSelect"
                                                                                    id='{{pageId}}selected'
                                                                                    (change)="rowCheckBoxChecked($event, remainingLrsData,i)" />
                                                                            </td>
                                                                            <td>{{ remainingLrsData.lrNumber }}</td>
                                                                            <td>{{ remainingLrsData.totalArticles }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.consignorName }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.consigneeName }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.agentName }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.bookingDateStr }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.actualWeight }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.chargedWeight }}
                                                                            </td>
                                                                            <td>{{ remainingLrsData.totalAmount }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="text-align: right;">
                                                <!-- <button class="btn btn-dark m-r-10"
											*ngIf="viewNextDarkBtnForScheduleTrip"
											(click)="validateNextBtnForScheduleTrip();"
											id="{{pageId}}clearToTripDetailsBtn">Next</button>
										<button class="btn btn-success m-r-10"
											*ngIf="viewNextGreenBtnForScheduleTrip"
											(click)="scheduleForNextTripBtn();" id="{{pageId}}nextToTripDetailsBtn"
											mat-button matStepperNext>Next</button> -->
                                                <button class="btn btn-success m-r-10"
                                                    *ngIf="viewNextDarkBtnForScheduleTrip"
                                                    (click)="validateNextBtnForScheduleTrip();"
                                                    id="{{pageId}}nextToTripDetailsBtn" mat-button
                                                    matStepperNext>Next</button>
                                                <button class="btn btn-dark m-r-10" mat-button
                                                    (click)="resetBtnTripSheduleFrom();">Reset</button>

                                                <button class="btn btn-dark m-r-10" (click)="stockTripClearBtn();"
                                                    id="{{pageId}}clearToTripDetailsBtn">Clear</button>
                                            </div>
                                        </div>
                                        <!--</form>-->
                                    </mat-step>
                                    <!-- two flow is end -->
                                    <!-- three flow is starts -->
                                    <mat-step [stepControl]="thirdFormGroup">
                                        <!--<form [formGroup]="thirdFormGroup">-->
                                        <ng-template matStepLabel>Trip Details</ng-template>
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForTripDetailsAction" class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center'
                                                            style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12">
                                                        <h6 class="card-title bbtitle">Vehicle Details</h6>
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Vehicle</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="fas fa-truck"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #vehicleNumberDropDown
                                                                        id="{{pageId}}dropDownInputFieldVehicleNumber"
                                                                        type="text" class="form-control"
                                                                        (selectItem)="clickListnerForDropDownInputFieldVehicleNumber($event,vehicleNumberPopUpTemplate)"
                                                                        [(ngModel)]="modelDropDownVehicleNumber"
                                                                        [ngbTypeahead]="searchDropDownInputFieldVehicleNumber"
                                                                        [resultFormatter]="formatterDropDownVehicleNumber"
                                                                        [inputFormatter]="formatterDropDownVehicleNumber"
                                                                        placeholder="Please Select The Vehicle Number"
                                                                        (focus)="focusDropDownVehicleNumberTA$.next($any($event).target.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 vl">
                                                <div class="row">
                                                    <!-- 											the second autocomplete starts -->
                                                    <div class="col-sm-12 col-md-12">
                                                        <h6 class="card-title bbtitle">Driver Details</h6>
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Driver Name</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #driverNameDropDown
                                                                        id="{{pageId}}dropDownInputFieldDriverName"
                                                                        type="text" class="form-control"
                                                                        (selectItem)="clickListnerForDropDownInputFieldDriverName($event)"
                                                                        [(ngModel)]="modelDropDownDriverName"
                                                                        [ngbTypeahead]="searchDropDownInputFieldDriverName"
                                                                        [resultFormatter]="formatterDropDownDriverName"
                                                                        [inputFormatter]="formatterDropDownDriverName"
                                                                        placeholder="Please Select The Driver Name"
                                                                        (focus)="focusDropDownDriverNameTA$.next($any($event).target.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Contact Number</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input id="{{pageId}}inputFieldDriverConatctNumber"
                                                                        type="text" class="form-control"
                                                                        placeholder="Contact Number"
                                                                        autocomplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Lorry Hire</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="fas fa-rupee-sign"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input id="{{pageId}}lorryHire" type="number"
                                                                        class="form-control"
                                                                        placeholder="Enter Lorry Hire" name="lorryHire"
                                                                        (keyup)="calculateLorryHireAmtOnEnter($event, advance)"
                                                                        (blur)="calculateLorryHireAmtOnBlur($event)"
                                                                        autocomplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Advance</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="fas fa-rupee-sign"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input id="{{pageId}}advance" #advance type="number"
                                                                        class="form-control" placeholder="Enter Advance"
                                                                        name="advance"
                                                                        (keyup)="calculateLorryHireAmtOnEnter($event, labourHeadNameDropDown)"
                                                                        (blur)="calculateLorryHireAmtOnBlur($event)"
                                                                        autocomplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Balance</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="fas fa-rupee-sign"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input id="{{pageId}}balance" type="number"
                                                                        class="form-control" placeholder="Balance Amt"
                                                                        name="balance" autocomplete="off" readonly />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 vl">
                                                <h6 class="card-title bbtitle">Labour Details</h6>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12"
                                                        [hidden]="!viewToShowDeliveryManField">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Delivery Man</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #labourHeadNameDropDown
                                                                        id="{{pageId}}dropDownInputFieldLabourHeadName"
                                                                        type="text" class="form-control"
                                                                        (selectItem)="clickListnerForDropDownInputFieldLabourHeadName($event)"
                                                                        [(ngModel)]="modelDropDownLabourHeadName"
                                                                        [ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
                                                                        [resultFormatter]="formatterDropDownLabourHeadName"
                                                                        [inputFormatter]="formatterDropDownLabourHeadName"
                                                                        placeholder="Please Select Labour Head Name"
                                                                        (keypress)="onKeyPressListnerForDropDownLabourHead($event);"
                                                                        (focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Labour Name</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #labourNameDropDown
                                                                        id="{{pageId}}dropDownInputFieldLabourName"
                                                                        type="text" class="form-control"
                                                                        (selectItem)="clickListnerForDropDownInputFieldLabourName($event)"
                                                                        [(ngModel)]="modelDropDownLabourName"
                                                                        [ngbTypeahead]="searchDropDownInputFieldLabourName"
                                                                        [resultFormatter]="formatterDropDownLabourName"
                                                                        [inputFormatter]="formatterDropDownLabourName"
                                                                        placeholder="Please Select The Labour Name"
                                                                        (keypress)="onKeyPressListnerForDropDownLabourName($event);"
                                                                        (focus)="focusDropDownLabourNameTA$.next($any($event).target.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label>Hamalis</label>
                                                                <div class="form-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-notes-medical"></i>
                                                                    </span>
                                                                </div>
                                                                <textarea class="form-control" rows="4"
                                                                    id="{{pageId}}hamalis" readonly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="text-align: right;">
                                                <button class="btn btn-dark m-r-10"
                                                    (click)="backBtnForStockTripSheetDetails();" mat-button
                                                    matStepperPrevious>Back</button>
                                                <button class="button_custome btn btn-info m-r-10"
                                                    (click)="startOverBtnTripDetailsFrom()">Start Over</button>
                                                <button class="button_custome btn btn-info m-r-10"
                                                    (click)="resetBtnTripDetailsFrom()">Reset</button>
                                                <!--<button class="btn btn-success m-r-10 button_custome"
													*ngIf="viewScheduleTripBtn" id="{{pageId}}scheduleTripBtn"
													(click)="validateScheduleTripSheet();">Schedule
													Trip</button>-->
                                                    <!-- //removed hiding buton for temp -->
                                                <!-- <button class="btn btn-success m-r-10 button_custome"
                                                    id="{{pageId}}createTripSheetBtn" *ngIf="!viewScheduleTripBtnV2"
                                                    (click)="validateCreateTripSheet('Pending');">Create
                                                    Trip Sheet</button> -->
                                                    <button class="btn btn-success m-r-10 button_custome"
                                                    id="{{pageId}}createTripSheetBtn" 
                                                    (click)="validateCreateTripSheet('Pending');">Create
                                                    Trip Sheet</button>

                                                <button class="btn btn-success m-r-10 button_custome"
                                                    *ngIf="viewScheduleTripBtnV2" id="{{pageId}}scheduleTripBtn"
                                                    (click)="validateCreateTripSheet('Scheduled');">Schedule
                                                    Trip</button>
                                            </div>
                                        </div>
                                        <!--</form>-->
                                    </mat-step>
                                    <!-- three flow is ends -->
                                </mat-horizontal-stepper>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Print Start-->
<!-- onafterPopUp="afterPopUp()" -->
<!-- <div *ngIf="viewStockTripsheetPrint" onafterPopUp="afterPrint()" id="{{pageId}}stockTripsheetPrintId"> -->
<div *ngIf="viewStockTripsheetPrint" onafterprint="afterPrint()" id="{{pageId}}stockTripsheetPrintId">
    <app-stock-for-tripsheet-driver-print-v2>
    </app-stock-for-tripsheet-driver-print-v2>
</div>

<div *ngIf="viewStockTripsheetPrintOld" onafterprint="afterPrint()" id="{{pageId}}stockTripsheetPrintId">
    <app-stock-for-tripsheet-driver-print>
    </app-stock-for-tripsheet-driver-print>
</div>
<!--Print End -->

<!--POP UP START HERE-->
<div class="row">
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #vehicleNumberPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h4 class="modal-title">Add New Vehicle</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Add Vehicle Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        id="{{pageId}}addNewVehicleNumberId"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Vehicle Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-id-card"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        id="{{pageId}}addNewVehicleNameId"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark"
                            (click)="saveBtnVehicleNumberPopup();">Save</button>
                        <button type="button" class="btn btn-outline-dark"
                            (click)="resetBtnVehicleNumberPopup();">Reset</button>
                        <button type="button" class="btn btn-outline-dark"
                            (click)="closePopUpVehiclePopup();">Cancel</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<!--POP Up END HERE-->
<!-- </body> -->