<div class="row" *ngIf="isLoggedIn" id="{{pageId}}estimateEnqRpt">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date &nbsp;*</label>
                                                    <input class="form-control" id="{{pageId}}fromDate"
                                                        placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
                                                        #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date &nbsp;*</label> <input id="{{pageId}}toDate"
                                                        class="form-control" placeholder="yyyy-mm-dd" name="toDates"
                                                        ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label class="bold-label">Location &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-home"></i>
                                                        </span>
                                                    </div>
                                                    <input #location id="{{pageId}}location" type="text"
                                                        class="form-control" [(ngModel)]="modelsrcDest"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [ngbTypeahead]="searchSrcDest"
                                                        [resultFormatter]="formatterSrcDest"
                                                        [inputFormatter]="formatterSrcDest"
                                                        placeholder="Select Location"
                                                        (focus)="focusSrcDestTA$.next($any($event).target.value)"
                                                        #instanceLocation="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-5" id="{{pageId}}searchBtn"
                            (click)="validateEstimateEnquiryDetails()">Search</button>
                        <button type="submit" class="btn btn-dark m-r-5" id="{{pageId}}clearBtn"
                            (click)="clearMethod()">Clear</button>
                        <hr>
                    </div>
                </div>


                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}popupDetails3">
                                    <ng-template #rateDetails let-c="close" let-d="dismiss">
                                        <div class="modal-header">
                                            <h6 class="modal-title" id="{{pageId}}modal-basic-title "
                                                style="text-align: center; padding-left: 110px;">Rate
                                                Details
                                            </h6>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">Rate Type : </label>
                                                                <label>{{consignorRateType}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">Type : </label>
                                                                <label>{{consignorRatePerUnit}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">Rate On AW : </label>
                                                                <label>{{consignorRatePerUnitRateOnActWgt}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">B.Rate : </label>
                                                                <label>{{consignorRateBasicRate}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">Hamali : </label>
                                                                <label>{{consignorRateHamali}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">GC Chg : </label>
                                                                <label>{{consignorRateGcChg}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">AOC : </label>
                                                                <label>{{consignorRateAoc}}</label>
                                                            </div>
                                                        </div>&nbsp;

                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">Others : </label>
                                                                <label>{{consignorRateOthers}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">DD Chg : </label>
                                                                <label>{{consignorRateDDChg}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="bold-label">FOV : </label>
                                                                <label>{{consignorRateFov}}</label>
                                                            </div>
                                                        </div>&nbsp;

                                                        <div class="col-sm-12 col-md-12" style="text-align: center;">
                                                            <button type="submit" class="btn btn-dark"
                                                                id="{{pageId}}clearPopupBtn"
                                                                (click)="d('Cross click')">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}estimateEnquiryReportTableId"
                                                    class="table table-striped table-bordered row-border hover"
                                                    [dtOptions]="dtOptionsEstimateEnquiryReport"
                                                    [dtTrigger]="dtTriggerEstimateEnquiryReport">

                                                    <thead>
                                                        <tr>
                                                            <th>Client Name</th>
                                                            <th>Location</th>
                                                            <th>Contact No</th>
                                                            <th>Estimation Date</th>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Commodity</th>
                                                            <th>Articles</th>
                                                            <th>Act Wgt</th>
                                                            <th>Chrg Wgt</th>
                                                            <th>Goods Value</th>
                                                            <th>Estimated Amt</th>
                                                            <th>Quoted Amt</th>
                                                            <th>Articles Size</th>
                                                            <th>Rate Dtls</th>
                                                            <th>Prepared By</th>
                                                            <th>Prepared At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let estimateEnquiryReportData of estimateEnquiryDetails ">
                                                            <td>{{ estimateEnquiryReportData.contactPerson }}</td>
                                                            <td>{{ estimateEnquiryReportData.location }}</td>
                                                            <td>{{ estimateEnquiryReportData.mobileNum }}</td>
                                                            <td>{{ estimateEnquiryReportData.bookingDateStr }}</td>
                                                            <td>{{ estimateEnquiryReportData.source }}</td>
                                                            <td>{{ estimateEnquiryReportData.destination }}</td>
                                                            <td>{{ estimateEnquiryReportData.commodityName }}</td>
                                                            <td>{{ estimateEnquiryReportData.totalArticles }}</td>
                                                            <td>{{ estimateEnquiryReportData.actualWeight }}</td>
                                                            <td>{{ estimateEnquiryReportData.chargedWeight }}</td>
                                                            <td>{{ estimateEnquiryReportData.goodsValue }}</td>
                                                            <td>{{ estimateEnquiryReportData.totalAmount }}</td>
                                                            <td>{{ estimateEnquiryReportData.quotedTotalAmt }}</td>
                                                            <td style='font-size: 13px; font-weight: bold;'>
                                                                <span>
                                                                    <a style='color: green; cursor: pointer;'
                                                                        (click)="getArticleRangeTable(estimateEnquiryReportData)">
                                                                        <u>View Size</u>
                                                                    </a>
                                                                </span>
                                                            </td>
                                                            <td style='font-size: 13px; font-weight: bold;'>
                                                                <span>
                                                                    <a style='color: green; cursor: pointer;'
                                                                        (click)="viewRateDetails(estimateEnquiryReportData, rateDetails)">
                                                                        <u>Rate Details</u>
                                                                    </a>
                                                                </span>
                                                            </td>
                                                            <td>{{ estimateEnquiryReportData.userName }}</td>
                                                            <td>{{ estimateEnquiryReportData.bookingDateStr }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>