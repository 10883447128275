<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row ">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row">
                    <!-- Row for label and input fields -->
                    <div class="col-12 d-flex flex-wrap">
                        <!-- Trip Date -->
                        <div class="col-sm-6 col-md-2 mb-2">
                            <label for="{{pageId}}tripDate" class="form-label">Trip Date</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="text" class="form-control" id="{{pageId}}tripDate" readonly>
                            </div>
                        </div>

                        <!-- Vehicle No -->
                        <div class="col-sm-6 col-md-3 mb-2">
                            <label for="{{pageId}}vehicleNumber" class="form-label">Vehicle No</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="text" class="form-control" id="{{pageId}}vehicleNumber" readonly>
                            </div>
                        </div>

                        <!-- Trip No -->
                        <div class="col-sm-6 col-md-2 mb-2">
                            <label for="{{pageId}}tripNo" class="form-label">Trip No</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="text" class="form-control" id="{{pageId}}tripNo" readonly>
                            </div>
                        </div>

                        <!-- Agent Name -->
                        <div class="col-sm-6 col-md-3 mb-2">
                            <label for="{{pageId}}agent" class="form-label">Agent Name</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="text" class="form-control" id="{{pageId}}agent" readonly>
                            </div>
                        </div>

                        <!-- Total Lrs -->
                        <div class="col-sm-6 col-md-2 mb-2">
                            <label for="{{pageId}}totalLrs" class="form-label">Total Lrs</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="text" class="form-control" id="{{pageId}}totalLrs" readonly>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="row">
                    
                    <div class="col-lg-12 d-flex flex-wrap">

                        <div class="col-md-2">
                            <label>Bay No</label>
                        </div>
                        <div class="col-md-2">
                            <label>Unloading Date</label>
                        </div>
                        <div class="col-md-2">
                            <label>Unloading Time</label>
                        </div>
                    </div>

                    
                    <div class="col-lg-12 d-flex flex-wrap">

                        <div class="col-md-2">
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input #bayNoDropDown id="{{pageId}}dropDownInputFieldBayNo" type="text"
                                    class="form-control" [(ngModel)]="modelDropDownBayNo"
                                    [ngbTypeahead]="searchDropDownInputFieldBayNo"
                                    [resultFormatter]="formatterDropDownBayNo" [inputFormatter]="formatterDropDownBayNo"
                                    placeholder="Select Bay No"
                                    (focus)="focusDropDownBayNoTA$.next($any($event).target.value)" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input class="form-control" id="{{pageId}}unldDt" placeholder="yyyy-mm-dd" name="unldDt"
                                    ngbDatepicker #unldDt="ngbDatepicker">
                                <div class="input-group-append" (click)="unldDt.toggle()">
                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                                <input type="time" class="form-control" id="{{pageId}}unldTime">
                            </div>
                        </div>
                    </div>
                </div> -->


                <div class="row system_responsive">

                    <div class="col-md-12 vl">
                        <div class="card-body">


                            <div class="row">
                                <div class="col-md-5">
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="col-sm-8 col-md-6">
                                                    <div class="input-group">
                                                        <span class="input-group-text"><i
                                                                class="fas fa-plus"></i></span>
                                                        <input type="text" #barcodeInput class="form-control"
                                                            id="{{pageId}}barcodeNumber" autocomplete="off"
                                                            (keypress)="handleBarcodeScan($event)">
                                                    </div>
                                                </div>





                                                <button *ngIf="showMovelAllButton" style='float: right;'
                                                    class="btn btn-dark m-r-10"
                                                    (click)="validateMoveAllBtnForCheckedLr();"
                                                    title="Move All To Checked Lrs" id="{{pageId}}moveAllBtn">Move
                                                    All</button>

                                                <button style="float: right;"
                                                    class="btn btn-success m-r-5 button_custome"
                                                    id="{{pageId}}manualLoadBtn"
                                                    (click)="validateManualLoad(contentForManualLoading);">Manual
                                                    UnLoad</button>
                                                <!-- <button type="button" class="btn btn-success waves-light"
                                                    (click)="onPlusButtonClick(contenetUpdateOnAction)">
                                                    <i class="fas fa-plus-circle" style="font-size: 23px;"></i>
                                                </button> -->

                                                <!-- <button type="button" class="btn btn-danger waves-light"
                                                    (click)="onMinusButtonClick(contenetUpdateOnAction)">
                                                    <i class="fas fa-minus-circle" style="font-size: 23px;"></i>
                                                </button> -->
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <h6 class="card-title">LR Details</h6>
                                            </div>
                                            <div class="row">
                                                <!-- spinner start-->
                                                <div *ngIf="showSpinnerForAction" class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <mat-progress-bar mode="indeterminate"
                                                                style="color: green;"> </mat-progress-bar>
                                                            <br>
                                                            <h6 class="card-title" align='center'
                                                                style="color: green; margin: auto; font-size: 18px;">
                                                                Please Wait Loading Details.....</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- spinner end-->
                                            </div>


                                        </div>
                                        <!-- 							<the first datatable starts  -->
                                        <table datatable id="{{pageId}}bkgTripLrsTableId"
                                            class="table table-striped table-bordered row-border hover"
                                            style="height: 240px;" [dtOptions]="dtOptionsLrDetails"
                                            [dtTrigger]="dtTriggerLrDetails">

                                            <thead>
                                                <tr>
                                                    <th>LR Number</th>
                                                    <th>Booking Date</th>
                                                    <th [hidden]=true>Destination</th>
                                                    <th>Articles</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                                    <td>{{ lrDetailsData.lrNumber }}</td>
                                                    <td>{{ lrDetailsData.bookingDateStr }}</td>
                                                    <td [hidden]=true>{{ lrDetailsData.destination }}</td>
                                                    <td>{{ lrDetailsData.totalArticles }}</td>
                                                    <td>{{ lrDetailsData.actualWeight }}</td>
                                                    <td>{{ lrDetailsData.chargedWeight }}</td>
                                                    <td>{{ lrDetailsData.toPay }}</td>
                                                    <td>{{ lrDetailsData.paid }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-md-7 vl">
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-8">
                                                <h6 class="card-title">Checked LR Details</h6>
                                            </div>
                                        </div>
                                        <table datatable id="{{pageId}}bkgTripCheckedLrsTableId"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionCheckedLrDetails"
                                            [dtTrigger]="dtTriggerCheckedLrDetails">
                                            <thead>
                                                <tr>
                                                    <th>LR Number</th>
                                                    <th>Booking Date</th>
                                                    <th>Destination</th>
                                                    <th>Tot Art</th>
                                                    <th>UnLoaded Art</th>
                                                    <th>Bal Art</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr *ngFor="let checkedLrDetailsData of checkedLrDetailsDataList ">
                                                    <td>{{ checkedLrDetailsData.lrNumber }}</td>
                                                    <td>{{ checkedLrDetailsData.bookingDateStr }}</td>
                                                    <td>{{ checkedLrDetailsData.destination }}</td>
                                                    <td>{{ checkedLrDetailsData.totalArticles }}</td>
                                                    <td>{{ checkedLrDetailsData.loadingArticle }}</td>
                                                    <td>{{ checkedLrDetailsData.remArticles }}</td>
                                                    <td>{{ checkedLrDetailsData.actualWeight }}</td>
                                                    <td>{{ checkedLrDetailsData.chargedWeight }}</td>
                                                    <td>{{ checkedLrDetailsData.toPay }}</td>
                                                    <td>{{ checkedLrDetailsData.paid }}</td>
                                                    <td><button type="button"
                                                            style="padding: 1px 4px; background-color: lightgrey;"
                                                            class="btn m-r-10"
                                                            (click)="rowSelectedLrDelete(checkedLrDetailsData);">
                                                            <i title="Remove" class="fas fa-trash"></i>
                                                        </button></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>Total:</td>
                                                    <td colspan="5"></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>

                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12" style="text-align: right;">
                                    <!-- <button class="btn btn-success m-r-10" id="{{pageId}}confirmLrsBtn"
                                        (click)="confirmLrs();">Confirm Lrs</button>
                                    <button *ngIf="showMovelAllButton" class="btn btn-success m-r-10"
                                        id="{{pageId}}confirmLrsBtn" (click)="confirmAllLrs();">Confirm All Lrs</button> -->
                                    <button class="btn btn-success m-r-10" id="{{pageId}}coleBtn"
                                        (click)="closeUnload();">Close Unload</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12" id="{{pageId}}hideLrPrintCopy">
        <div class="form-group">
            <div class="input-group">
                <ng-template #lrPrintCopyPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewLrPrintCopyPopUp" onafterPopUp="afterPopUp()"
                                                id="{{pageId}}popupLrPrintCopyDetails">
                                                <app-lrentryform-print3></app-lrentryform-print3>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark"
                                                id="{{pageId}}confrimBtnId" (click)="confirmBtnPopup()">Confirm</button>
                                            <button style="float: right;" type="button" class="btn btn-dark"
                                                id="{{pageId}}cancelBtnId" (click)="closeBtnPopup()">Cancel</button>
                                        </div>
                                        <div class="col-sm-12 col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <ng-template #contentForManualLoading let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h5 class="modal-title">Manual UnLoading</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Enter LR No:</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
                                        </span>
                                    </div>
                                    <input #manualLoadLrNumber id="{{pageId}}manualLoadLrNumberId" type="text"
                                        class="form-control" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Enter Article:</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
                                        </span>
                                    </div>
                                    <input #manualLoadArtciles id="{{pageId}}manualLoadArtcilesId" type="number"
                                        class="form-control" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-success"
                            (click)="submitManualLoading()">Submit</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
                            id="{{pageId}}clearManualLoadingBtn" (click)="d('Cross click');">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #contenetUpdateOnAction let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h5 class="modal-title">Enter Barcode Details</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Enter Barcode No:</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
                                        </span>
                                    </div>
                                    <input #barcodeNoPopup id="{{pageId}}barcodeNoPopupId" type="text"
                                        class="form-control" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button *ngIf="showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
                            class="btn btn-success" (click)="proceedbarcodeNoPopupPlusBtn()">Proceed</button>
                        <button *ngIf="!showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
                            class="btn btn-success" (click)="proceedbarcodeNoPopupMinusBtn()">Proceed</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
                            id="{{pageId}}clearActionbtnBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</body>

</html>