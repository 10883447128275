<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showPartyLessRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDates" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" [(ngModel)]="fromDatesModel" autocomplete="off"
                                                        />
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModel" autocomplete="off"
                                                        />
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}partyName">
                                                            <label>Party Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="consigneeDropDownListner($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                                [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn()" id="{{pageId}}searchBtn">Search</button>
                            <button type="submit" class="btn btn-dark" (click)="clearBtn()" id="{{pageId}}clearBtn">Clear</button>
                        </div>
                        <div class="box-body">
                            <hr style="background: none; padding-top: 10px">
                            <!-- 						<div class="card " style="border: 1px solid darkcyan !important;"> -->
                            <h6 class="imb_patch card-title text-white">Source Wise Memo Discount
                            </h6>
                            <div [hidden]=" detailTable">
                                <table datatable id="{{pageId}}partyLessSourceWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionPartyLessSrcWise" [dtTrigger]="dtTriggerPartyLessSrcWise">
                                    <thead>
                                        <tr>
                                            <th>Main Station</th>
                                            <th>Discount Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let partyLessSrcWiseData of partyLessSrcWiseDataList ">
                                            <td>{{ partyLessSrcWiseData.mainStation }}</td>
                                            <td>{{ partyLessSrcWiseData.lessAmt }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <div class="box-body">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintPartyLessRpt()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
                            <table datatable id="{{pageId}}partyLessDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPartyLessRpt" [dtTrigger]="dtTriggerPartyLessRpt">

                                <thead>
                                    <tr>
                                        <th>Cash Memo</th>
                                        <th>Agent</th>
                                        <th>LR No</th>
                                        <th>Consignee Name</th>
                                        <th>Area</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Freight</th>
                                        <th>GD</th>
                                        <th>RC</th>
                                        <th>Disc</th>
                                        <th>Dem</th>
                                        <th>SC</th>
                                        <th>Total</th>
                                        <th>G. Total</th>
                                        <th>Memo Type</th>
                                        <th>DD</th>
                                        <th [hidden]=true>FOV</th>

                                        <th [hidden]=true>Rate</th>
                                        <th [hidden]=true>Unit</th>
                                        <th [hidden]=true>Per Unit</th>
                                        <th [hidden]=true>S.H</th>
                                        <th [hidden]=true>D.H</th>
                                        <th [hidden]=true>RC</th>
                                        <th [hidden]=true>SC</th>
                                        <th [hidden]=true>GC</th>
                                        <th>Invoice Date</th>
                                        <th>Fixed By</th>
                                        <th>Commodity Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let partyLessRptData of partyLessRptDataList ">
                                        <td>{{ partyLessRptData.memoNumber }}</td>
                                        <td>{{ partyLessRptData.agentName }}</td>
                                        <td>{{ partyLessRptData.lrNumber }}</td>
                                        <td>{{ partyLessRptData.consignee }}</td>
                                        <td>{{ partyLessRptData.area }}</td>
                                        <td>{{ partyLessRptData.totalArticles }}</td>
                                        <td>{{ partyLessRptData.actualWeight }}</td>
                                        <td>{{ partyLessRptData.chargedWeight }}</td>
                                        <td>{{ partyLessRptData.lorryFreight }}</td>
                                        <td>{{ partyLessRptData.hamaligd }}</td>
                                        <td>{{ partyLessRptData.receiptCharges }}</td>
                                        <td>{{ partyLessRptData.discount }}</td>
                                        <td>{{ partyLessRptData.demurrage }}</td>
                                        <td>{{ partyLessRptData.serviceCharges }}</td>
                                        <td>{{ partyLessRptData.subTotal }}</td>
                                        <td>{{ partyLessRptData.grandTotal }}</td>
                                        <td>{{ partyLessRptData.memoType }}</td>
                                        <td>{{ partyLessRptData.hamaliDD }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.riskCharge }}</td>

                                        <td [hidden]=true>{{ partyLessRptData.rate }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateUnit }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.ratePerUnit == "Chargable Weight" ? "CW" : partyLessRptData.ratePerUnit =="Actual Weight" ? "AW" : partyLessRptData.ratePerUnit =="Article" ? "Art" : partyLessRptData.ratePerUnit== null ? "NA"
                                            : partyLessRptData.ratePerUnit== undefined ? "NA" : partyLessRptData.ratePerUnit== '' ? "NA" : partyLessRptData.ratePerUnit}}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateSrcHamali }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateDestHamali }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateReceiptChg }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateServiceChg }}</td>
                                        <td [hidden]=true>{{ partyLessRptData.rateGcchrage }}</td>
                                        <td>{{ partyLessRptData.date }}</td>
                                        <td>{{ partyLessRptData.fixedBy }}</td>
                                        <td>{{ partyLessRptData.rateCommodity }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]=true></td>

                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>