import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModal, NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap/';
import { NgxPrintModule } from 'ngx-print';
import { SuplierService } from 'src/app/master/supplier/supplier-master/supplier-master-service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { MaterialModule } from "src/app/material.module";
import { LoginComponent } from "src/app/authentication/login/login.component";
import { DatePipe } from '@angular/common';

import { DashboardModule } from './dashboards/dashboard.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { MobileAppsModule } from './mobileApps/mobileApps.module'
// import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TabqueueComponent } from './tabqueue/tabqueue.component';
import { TripCreationModule } from './trip-creation/trip-creation.module';
import { StocksForTripSheetPageModule } from './stocks-for-tripsheet-page/stocks-for-tripsheet-page.module';
import { NotfoundComponent } from './authentication/404/not-found.component';
import { LockComponent } from './authentication/lock/lock.component';
import { Login2Component } from './authentication/login2/login2.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { Signup2Component } from './authentication/signup2/signup2.component';
import { EnquiryModule } from './lr/enquiry/enquiry.module';
import { ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent } from './cashmemo/transfer-cashmemo/manual-cashmemo-check-and-collectionman-stock-check-print/manual-cashmemo-check-and-collectionman-stock-check-print.component';
import { GenerationModule } from './cashmemo/generation/generation.module';
import { AgentsModule } from './report/agents/agents.module';
import { TransferCashmemoModule } from './cashmemo/transfer-cashmemo/transfer-cashmemo.module';
import { AgentModule } from './fortnightStatement/agent/agent.module';
import { HamaliFortnightStatementModule } from './fortnightStatement/hamali-fortnightStatement/hamali-fortnightStatement.module';
import { BookingModule } from './fortnightStatement/booking/booking.module';
import { OthersModule } from './fortnightStatement/others/others.module';
import { EditModule } from './lr/edit/edit.module';
import { EntryModule } from './lr/entry/entry.module';
import { LrModule } from './lr/lr.module';
import { IssueModule } from './lr/issue/issue.module';
import { CommodityModule } from './master/commodity/commodity.module';
import { CommunicationModule } from './master/communication/communication.module';
import { CompanyModule } from './master/company/company.module';
import { ConsigneeModule } from './master/consignee/consignee.module';
import { ConsignorModule } from './master/consignor/consignor.module';
import { DetailsModule } from './master/details/details.module';
import { FixingModule } from './master/fixing/fixing.module';
import { SupplierModule } from './master/supplier/supplier.module';
import { TranshipmentModule } from './master/transhipment/transhipment.module';
import { TripModule } from './master/trip/trip.module';
import { BookingsModule } from './report/bookings/bookings.module';
import { CashmemoReportModule } from './report/cashmemo-report/cashmemo-report.module';
import { DailyReportModule } from './report/daily-report/daily-report.module';
import { DebitModule } from './report/debit/debit.module';
import { HamaliReportModule } from './report/hamali-report/hamali-report.module';
import { HireslipAndInvoiceModule } from './report/hireslip-and-invoice-report/hireslip-and-invoice-report.module';
import { LrReportModule } from './report/lr-report/lr-report.module';
import { OthersReportModule } from './report/others-report/others-report.module';
import { PartyReportModule } from './report/party-report/party-report.module';
import { StatementReportsModule } from './report/statement-reports/statement-reports.module';
import { StocksReportModule } from './report/stocks-report/stocks-report.module';
import { TruckReportModule } from './report/truck-report/truck-report.module';
import { CustomizationModule } from './stock/customization/customization.module';
import { DriverModule } from './stock/driver/driver.module';
import { PaymentModule } from './stock/payment/payment.module';
import { TransferModule } from './stock/transfer/transfer.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EWayBillModule } from './eway-bill/eway-bill.module'; 

// export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
//     constructor(private momentFormat: string) {
//         super();
//     };
//     format(date: NgbDateStruct): string {
//         if (date === null) {
//             return '';
//         }
//         let d = moment({
//             year: date.year,
//             month: date.month - 1,
//             date: date.day
//         });
//         return d.isValid() ? d.format(this.momentFormat) : '';
//     }

//     parse(value: string): NgbDateStruct {
//         if (!value) {
//             return null;
//         }
//         let d = moment(value, this.momentFormat);
//         return d.isValid() ? {
//             year: d.year(),
//             month: d.month() + 1,
//             day: d.date()
//         } : null;
//     }
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20
};

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        TabqueueComponent,
        NotfoundComponent,
        LoginComponent,
        SignupComponent,
        LockComponent,
        Login2Component,
        Signup2Component,

        // SpinnerComponent,
        // ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent
        //        BookingCashmemoComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        // HttpModule,
        // NgbModule,
        NgbModule,
        NgbDatepickerModule,
        RouterModule.forRoot(Approutes),
        PerfectScrollbarModule,
        AgmCoreModule.forRoot({ apiKey: 'AIzaSyBYWtRuy8pgNuiPt5JOy3og1LaBAaT8ym0' }),
        NgxPrintModule,
        DataTablesModule,
        MaterialModule,
        DashboardModule,
        AuthenticationModule,
        MobileAppsModule,
        StocksForTripSheetPageModule,
        TripCreationModule,
        EnquiryModule,
        GenerationModule,
        AgentsModule,
        TransferCashmemoModule,
        AgentModule,
        BookingModule,
        HamaliFortnightStatementModule,
        OthersModule,
        EditModule,
        EntryModule,
        IssueModule,
        CommodityModule,
        CommunicationModule,
        CompanyModule,
        ConsigneeModule,
        ConsignorModule,
        DetailsModule,
        FixingModule,
        SupplierModule,
        TranshipmentModule,
        TripModule,
        BookingsModule,
        CashmemoReportModule,
        DailyReportModule,
        DebitModule,
        HamaliReportModule,
        HireslipAndInvoiceModule,
        LrReportModule,
        OthersReportModule,
        PartyReportModule,
        StatementReportsModule,
        StocksReportModule,
        TruckReportModule,
        CustomizationModule,
        DriverModule,
        PaymentModule,
        TransferModule,
        NgxDatatableModule,
        EWayBillModule
        //        BookingCashmemoComponent

        //       NgbDateMomentParserFormatter
    ],
    providers: [
        LoginComponent,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        // {
        //     provide: NgbDateParserFormatter,
        //             //    useFactory: () => { return new NgbDateMomentParserFormatter( "DD-MM-YYYY" ) }
        //     useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") }
        // },
        //        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        SuplierService,
        DatePipe,
        DataTableDirective
        //        BookingCashmemoComponent
    ],
    //    exports: [
    //        BookingCashmemoComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
