import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { map } from 'rxjs/operators';
import { MemoReport } from 'src/app/dataService/memo-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
@Component({
    selector: 'app-party-less-report',
    templateUrl: './party-less-report.component.html',
    styleUrls: ['./party-less-report.component.css']
})
export class PartyLessReportComponent implements OnInit {

    gettingDataFrmServiceFrPartyLessRptTable: any;
    gettingDataFrmServiceFrPartyLessSrcWiseTable: any;

    partyLessRptDataList: any;
    partyLessSrcWiseDataList: any;

    onDestroyUnsubscribtionPartyLessRpt: Subscription;
    onDestroyUnsubscribtionPartyLessSrcWise: Subscription;


    //PartyLessRptTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDates: NgbDateStruct;
    toDates: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerPartyLessRpt: Subject<any> = new Subject();
    dtTriggerPartyLessSrcWise: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsPartyLessRpt: any;
    dtOptionPartyLessSrcWise: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    showSpinnerForAction = false;
    memoDto: CashMemoDto = new CashMemoDto();
    cashMemoDto: CashMemoDto = new CashMemoDto();
    validateFromDate: any = null;
    validateToDate: any = null;
    validateConsigneeName: any = null;
    validateConsigneeId: any = null;
    fromDatesModel: any = null;
    toDatesModel: any = null;

    //for custom print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    artSummary: number;
    actWtsummary: number;
    chgWtSummary: number;
    frieghtsummary: number;
    discSummary: number;
    gtotalsummary: number;
    pageId = "plrc";

    constructor(private partyLessRpt: ReportService, private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getConsigneeDetails();
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            //console.log(this.address);
        }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsPartyLessRpt = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Party Less Report - " +
                            "From Date : " + moment($("#plrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#plrctoDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
                    },
                },
                {
                    extend: 'print',
                    exportOptions: {
                        //columns: [0, 1, 2, 4, 5, 6, 7, 10, 14]
                        columns: [0, 1, 2, 3, 5, 6, 7, 8, 11, 15]
                    },
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                            "<u>Party Less Report</u><br><br>" +
                            "From Date : " + moment($("#plrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#plrctoDates").val()).format('DD-MM-YYYY') + "" +
                            "</td>" +
                            "</tr></table><br>"
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                }, {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel1</i>',
                    titleAttr: 'Excel1',
                    footer: true,
                    title: function () {
                        return "Party Less Report - " +
                            "From Date : " + moment($("#plrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#plrctoDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
                    }
                },
                {
                    extend: 'print',
                    exportOptions: {
                        //columns: [0, 1, 2, 4, 5, 6, 7, 10, 14]
                        columns: [0, 2, 3, 5, 6, 7, 8, 11, 15, 1]
                    },
                    text: '<i class="fas fa-print"> Print1</i>',
                    titleAttr: 'Print - 1',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                            "<u>Party Less Report</u><br><br>" +
                            "From Date : " + moment($("#plrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#plrctoDates").val()).format('DD-MM-YYYY') + "" +
                            "</td>" +
                            "</tr></table><br>"
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var article = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var freightAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gd = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var rc = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var discAmt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var dem = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var sc = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalAmt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(b);
                    }, 0);
                var subTotalAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var ddAmt = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var riskChg = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(article);
                $(api.column(6).footer()).html(actWgt);
                $(api.column(7).footer()).html(chgWgt);
                $(api.column(8).footer()).html(freightAmt);
                $(api.column(9).footer()).html(gd);
                $(api.column(10).footer()).html(rc);
                $(api.column(11).footer()).html(discAmt);
                $(api.column(12).footer()).html(dem);
                $(api.column(13).footer()).html(sc);
                $(api.column(14).footer()).html(totalAmt);
                $(api.column(15).footer()).html(subTotalAmt);
                $(api.column(17).footer()).html(ddAmt);
                $(api.column(18).footer()).html(riskChg);
            },
        }

        this.dtOptionPartyLessSrcWise = {
            /* columns: [
                 {
                     title: 'Main Station',
                     data: 'mainStation'
                 },
                 {
                     title: 'Discount Amount',
                     data: 'discountAmount'
                 }
             ],*/
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var discountAmt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(discountAmt);
            },
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerPartyLessRpt.unsubscribe();
        this.dtTriggerPartyLessSrcWise.unsubscribe();
        //this.onDestroyUnsubscribtionPartyLessRpt.unsubscribe();
        //this.onDestroyUnsubscribtionPartyLessSrcWise.unsubscribe();

    }

    ngAfterViewInit(): void {
        this.dtTriggerPartyLessRpt.next();
        this.dtTriggerPartyLessSrcWise.next();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    consigneeDropDownListner(event) {
        this.modelConsigneeName = event.item;
        this.validateConsigneeName = this.modelConsigneeName.consigneeName;
        this.validateConsigneeId = this.modelConsigneeName.consigneeId;
        $("#" + this.pageId + "consigneeName").val(this.validateConsigneeName);
    }

    getConsigneeDetailsList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
    }
    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    clearField() {
        this.fromDatesModel = null;
        this.toDatesModel = null;
        //$("#"+this.pageId+"fromDates").val('');
        //$("#"+this.pageId+"toDates").val('');
        $('input[type="text"],[type="number"]').val('');
        this.modelConsigneeName = null;
        this.validateConsigneeName = null;
        this.validateConsigneeId = null;
        this.partyLessRptDataList = [];
        this.partyLessSrcWiseDataList = [];
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
        this.dtTriggerPartyLessRpt.next();
        this.dtTriggerPartyLessSrcWise.next();
    }

    clearBtn() {
        this.clearField();
    }

    validateSearchBtn() {
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateConsigneeName = $("#" + this.pageId + "consigneeName").val();

        if ((this.validateFromDate == null) || (this.validateFromDate == undefined) ||
            (this.validateFromDate == "") || (this.validateToDate == null) ||
            (this.validateToDate == undefined) || (this.validateToDate == "") ||
            (this.validateConsigneeName == null) || (this.validateConsigneeName == undefined) ||
            (this.validateConsigneeName == "")) {
            swal("Mandatory Field", "please enter the mandatory fields", "warning");
            return false;
        } else {
            this.getPartyLessDetailsReport();
        }
    }
    getPartyLessReport() {
        this.memoDto = new CashMemoDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateConsigneeName = $("#" + this.pageId + "consigneeName").val();
        if (this.validateFromDate != null && this.validateToDate != undefined &&
            this.validateToDate != null && this.validateToDate != undefined) {
            this.memoDto.fromdate = this.validateFromDate;
            this.memoDto.todate = this.validateToDate;
            this.memoDto.mode = "dateBetween";
        }
        this.memoDto.station = this.userDataDtoReturnSession.office;
        if (this.validateConsigneeName == null || this.validateConsigneeName == undefined ||
            this.validateConsigneeName == "") {
            this.memoDto.consigneeName = "ALL";
            this.memoDto.consigneeId = 0;
        } else {
            this.memoDto.consigneeName = this.validateConsigneeName;
            this.memoDto.consigneeId = this.validateConsigneeId;
        }
        this.memoDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getPartyLessDetailsReport() {
        this.showSpinnerForAction = true;
        this.getPartyLessReport();
        console.log(this.memoDto);
        this.memoReport.getPartyLessReport(this.memoDto).subscribe(
            (response) => {
                console.log(response);
                this.partyLessRptDataList = [];
                $("#" + this.pageId + "partyLessDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.partyLessRptDataList = response;
                    this.showSpinnerForAction = false;
                    this.getPartyLessForSourceWiseDetails();
                } else {
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerPartyLessRpt.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Party Less Details", "info");
            },
            () => console.log('done');
    }

    getPartyLessForSourceWiseDetails() {
        this.showSpinnerForAction = true;
        this.memoReport.getMemoDiscountDetailsSourceWise(this.memoDto).subscribe(
            (response) => {
                console.log(response);
                this.partyLessSrcWiseDataList = [];
                $("#" + this.pageId + "partyLessSourceWiseTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.partyLessSrcWiseDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerPartyLessSrcWise.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Party Less Source Wise Details", "info");
            },
            () => console.log('done');
    }
    customPrintPartyLessRpt() {
        if (this.partyLessRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Cash Memo", "LR No", "Consignee Name", "Art", "Act Wt", "Chg Wt", "Frieght", "Disc", "G.Total", "Agent"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [18, 10, 20, 5, 8, 7, 8, 7, 8, 9];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.artSummary = this.actWtsummary = this.chgWtSummary = this.frieghtsummary = this.discSummary = this.gtotalsummary = 0;

            for (let i = 0; i < this.partyLessRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.partyLessRptDataList[i].memoNumber, this.partyLessRptDataList[i].lrNumber, this.partyLessRptDataList[i].consignee, this.partyLessRptDataList[i].totalArticles,
                this.partyLessRptDataList[i].actualWeight, this.partyLessRptDataList[i].chargedWeight,
                this.partyLessRptDataList[i].lorryFreight, this.partyLessRptDataList[i].discount, this.partyLessRptDataList[i].grandTotal, this.partyLessRptDataList[i].agentName];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.artSummary = this.artSummary + this.partyLessRptDataList[i].totalArticles;
                this.actWtsummary = this.actWtsummary + this.partyLessRptDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.partyLessRptDataList[i].chargedWeight;
                this.frieghtsummary = this.frieghtsummary + this.partyLessRptDataList[i].lorryFreight;
                this.discSummary = this.discSummary + this.partyLessRptDataList[i].discount;
                this.gtotalsummary = this.gtotalsummary + this.partyLessRptDataList[i].grandTotal;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.partyLessRptDataList.length, "", "", this.artSummary, this.actWtsummary, this.chgWtSummary, this.frieghtsummary, this.discSummary, this.gtotalsummary, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];


            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.memoDto.fromdate, this.memoDto.todate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Party Less Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}
