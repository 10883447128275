//ChgV1_Imran = Changed path of rewarding screen for new update in rewar flow By Imran On 27062024

import { Component, OnInit, ViewChild } from '@angular/core';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { EditProfilePopupComponent } from 'src/app/master/company/edit-profile/edit-profile.component';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    @ViewChild('clear') searchField;

    showMenu = '';
    showSubMenu = '';
    userDataDtoReturnSession: any;
    viewDashboardModule = true;
    viewMasterModule = false;
    viewCashmemoModule = false;
    viewCommunicationModule = false;
    viewMessageScreen = false;
    viewSendSmsScreen = false;
    viewSendSmsGroupDtlsScreen = false;
    viewCompanyModule = false;
    viewCommonChargesScr = false;
    viewDeveloperPageScr = false;
    viewEditCompanyScr = false;
    viewStaffMasterScr = false;
    viewStationCreationScr = false;
    viewUserCreationScr = false;
    viewUserFeaturesCustomizationScr = false;
    viewUserDashboardCustomizationScr = false;
    viewUserRightsCustomizationScr = false;
    viewExpensesDetailsEntryScr = false;
    viewConsignmentsClaimSettlementEntryScr = false;
    viewConsigneeModule = false;
    viewCneeAdminRateMasterScr = false;
    viewCneeGroupingScr = false;
    viewConsigneeMasterScr = false;
    viewConsigneeMergeScr = false;
    viewRateMasterScr = false;
    viewConsigneeServiceTaxExpScr = false;
    viewEdeclarationPasswordScr = false;
    viewConsignorModule = false;
    viewConsignorMasterScr = false;
    viewConsignorMergeScr = false;
    viewConsignorServiceTaxExpScr = false;
    viewDetailsModule = false;
    viewDestinationAgentScr = false;
    viewBookingAgentScr = false;
    viewDestinationTypeScr = false;
    viewFixingModule = false;
    viewAgentFeatureCusmtScr = false;
    viewAreaScr = false;
    viewBankScr = false;
    viewCollectionScr = false;
    viewDebitNoteMasterScr = false;
    viewExpensesHeadsScr = false;
    viewStandardRateScr = false;
    viewFovExemptionMasterForBkgScr = false;
    viewTdsDetailsEntryScr = false;
    viewUnloadingChrgAmtDtlsScr = false;
    viewSupplierModule = false;
    viewBalanceDtlsScr = false;
    viewSupplierMasterScr = false;
    viewTranshipmentModule = false;
    viewDriverMasterScr = false;
    viewGetHireslipBarcodeValueScr = false;
    viewLorryHireDeductionSetupScr = false;
    viewNatureOfPackScr = false;
    viewPointToPointSerChkScr = false;
    viewTruckMasterScr = false;
    viewVehicleSetupMasterScr = false;
    viewExpensesPaymentFollowupScr = false;
    viewConsigmentLiveTrackingScr = false;
    viewTrackingLiveReportScr = false;
    viewTripModule = false;
    viewRoutingFeatureScr = false;
    viewTimeScr = false;
    viewTruckCapacityScr = false;
    viewTripEtaSetupScr = false;
    viewCommodityModule = false;
    viewCommodityMasterScr = false;
    viewCommodityRateMasterScr = false;
    viewTpMasterScr = false;
    viewUnloadingRateScr = false;
    viewCommodityRoutingScr = false;
    viewCommodityMergeScr = false;
    viewGenerationModule = false;
    viewBookingCashMemoScreen = false;
    viewGenerateCashMemoScreen = false;
    viewDummyMemo = false;
    viewTransferModule = false;
    viewBillingToSrdCollectionStockScr = false;
    viewCashmemoTransferScr = false;
    viewTransferByLrScr = false;
    viewManualCashmemoCheckScr = false;
    viewTransferToCollectionScr = false;
    viewCollectionManStockTransferScr = false;
    viewColtManToColtManStockTransferScr = false;
    viewChartsScr = false;
    viewCollectionManCashmemoStockCheckScr = false;
    viewPaymentFollowupScr = false;
    viewCashMemoBlockScr = false;
    viewFortnightStatementModule = false;
    viewFortngtStmtAgentModule = false;
    viewCommissionMasterScr = false;
    viewFortngtStmtCommodityRateMasterScr = false;
    viewAmountReceivableFromAgentScr = false;
    viewDestinationAgentStmtGenerationScr = false;
    viewFortngtStmtBookingModule = false;
    viewAgentCommissionMasterScr = false;
    viewAgentStatementGenerationScr = false;
    viewMemoInvoiceGenerationScr = false;
    viewFortngtStmtHamaliModule = false;
    viewLabourCommissionSetupMumbaiScr = false;
    viewSrdLabourMasterScr = false;
    viewSrdLabourStmtGenerationScr = false;
    viewFortngtStmtOthersModule = false;
    viewPartyLessCheckScr = false;
    viewBalancingDifferenceTallyRptScr = false;
    viewDebitNoteStmtScr = false;
    viewLrCrossCheckScr = false;
    viewPartyLessCheckUploadTypeScr = false;
    viewLrModule = false;
    viewEditModule = false;
    viewInvoieLrEditScr = false;
    viewLrEditScr = false;
    viewLrsShortAndExtraScr = false;
    viewSalesTaxLrScr = false;
    viewEnquiryModule = false;
    viewConsigneeDetailsScr = false;
    viewLrDeliveryStatusScr = false;
    viewLrAdvanceScr = false;
    viewLrEnquiryFormScr = false;
    viewLrInSalesTaxScr = false;
    viewEntryModule = false;
    viewLrEntryFormScr = false;
    viewMissAgentScr = false;
    viewIssueModule = false;
    viewChallanScr = false;
    viewLrIssueScr = false;
    viewMobileAppModule = false;
    viewAboutUsScr = false;
    viewLatestNewsScr = false;
    viewMobileAppUserRptScr = false;
    viewOurBranchesScr = false;
    viewRouteMappingScr = false;
    viewStocksModule = false;
    viewCustomizationModule = false;
    viewManualStockCheckScr = false;
    viewStocksGroupingScr = false;
    viewDriverModule = false;
    viewRewardingScr = false;
    viewBarcodeScanningScr = false;
    viewPaymentsModule = false;
    viewHireslipAdvanceScr = false;
    viewHireslipBalanceScr = false;
    viewStocksTransferModule = false;
    viewShipmentStatusScr = false;
    viewStocksForTripsheetScr = false;
    viewStocksInTakenScr = false;
    viewStockstoCPScr = false;
    viewStocksBetweenGodownsScr = false;
    viewStocksToGodownScr = false;
    viewStocksForLocalBookingScr = false;
    viewReportModule = false;
    viewRptAgentsModule = false;
    viewRankingScr = false;
    viewStatementVerificationScr = false;
    viewDestCommissionScr = false;
    viewDestIncomeScr = false;
    viewBookingModule = false;
    viewAgentExpensesScr = false;
    viewBookingAgentRptScr = false;
    viewAgentStatementScr = false;
    viewAgentTDSOnLoadingScr = false;
    viewMemoInvoiceScr = false;
    viewMemoScr = false;
    viewOfficeDailyExpensesScr = false;
    viewOfficeHireslipScr = false;
    viewAgentLocalTripScr = false;
    viewCashmemoRptModule = false;
    viewAmountReceivableScr = false;
    viewCollectionManScr = false;
    viewSRDCollectionStockRptScr = false;
    viewPaidReportScr = false;
    viewCashMemoReportScr = false;
    viewCashmemoDetailsScr = false;
    viewLetterHeadReportScr = false;
    viewToBeBilledPartyScr = false;
    viewBookingCashmemoScr = false;
    viewBookingCashmemoByInvoiceScr = false;
    viewCashMemoInCollectionManStockScr = false;
    viewChequeSearchDetailsScr = false;
    viewMemoLessDetailsScr = false;
    viewTotalLessReportScr = false;
    viewDebitModule = false;
    viewDebitNoteRptScr = false;
    viewSummaryScr = false;
    viewDailyModule = false;
    viewBangaloreOfficeScr = false;
    viewDailyBookingScr = false;
    viewDoorDeliveryScr = false;
    viewLoadingDtlsScr = false;
    viewOfficeReportScr = false;
    viewHamaliRptModule = false;
    viewBrokerRptScr = false;
    viewLabourStatementRptScr = false;
    viewHireslipAndInvoiceModule = false;
    viewInvoiceModule = false;
    viewInvoiceDtlsScr = false;
    viewServiceTaxScr = false;
    viewAdvancePaymentScr = false;
    viewHireslipDtlsScr = false;
    viewHireslipHistoryRptcr = false;
    viewHireslipUnloadingRptScr = false;
    viewLocalTripSheetScr = false;
    viewTransshipmentTripScr = false;
    viewLrRptModule = false;
    viewAutomatedSearchScr = false;
    viewDelayed_E_SugamScr = false;
    viewGcNotReceivedScr = false;
    viewLorryHireBalanceScr = false;
    viewLorryHireStatementScr = false;
    viewLRDispatchBookingRptScr = false;
    viewLRHistoryRptScr = false;
    viewInSalesTaxScr = false;
    viewIssueBalanceScr = false;
    viewLRIssueRptScr = false;
    viewPerformanceScr = false;
    viewLRReportScr = false;
    viewChallanIssueScr = false;
    viewShortAndExtraScr = false;
    viewShortAndExtraConflictScr = false;
    viewPartyModule = false;
    viewConsigneeRptModule = false;
    viewConsigneeAdminRateRptScr = false;
    viewRateHistoryScr = false;
    viewRateReportScr = false;
    viewConsingeeRptScr = false;
    viewBusinessStopReportScr = false;
    viewBusinessStartReportScr = false;
    viewAdminBusinessRateReportScr = false;
    viewConsignorRptScr = false;
    viewDiscontinuousDtlsScr = false;
    viewPartyWiseOscr = false;
    viewPartyWiseTotalStatusDtlsScr = false;
    viewCommodityMismatchRptScr = false;
    viewStatementModule = false;
    viewInvoiceRptScr = false;
    viewStatementRptScr = false;
    viewPartyLessCheckedAndUncheckLrDtlsScr = false;
    viewStocksRptModule = false;
    viewCommodityReportScr = false;
    viewGodownStocksScr = false;
    viewGodownStocksBookingScr = false;
    viewPendingDetailsScr = false;
    viewStocksInTakenRptScr = false;
    viewStorageReportScr = false;
    viewStocksUnloadingRptScr = false;
    viewBkgAgentsDispatchPerformanceRptScr = false;
    viewTruckModule = false;
    viewTruckAverageScr = false;
    viewHaltAndDispatchedDtlsScr = false;
    viewNonAvailabilityOfTruckScr = false;
    viewPerformanceReportScr = false;
    viewTruckReportScr = false;
    viewHireDetailsByDestScr = false;
    viewTruckOwnerDeclarationRptScr = false;
    viewOthersModule = false;
    viewLcAndBcRptScr = false;
    viewLoaderPerformanceRptScr = false;
    viewMonthlyReportScr = false;
    viewSalesTaxReportScr = false;
    viewCbrReportScr = false;
    viewDriverRewardedReportScr = false;
    viewTempoPerformanceReportScr = false;
    viewAgentTempoMasterScr = false;
    viewChequeDetailsScr = false;
    viewPartyLessReportScr = false;
    viewLiveTrack = false;
    viewLiveTrackReport = false;
    viewEwayBillDateValidationExtensionReport = false;
    viewShortAndExtraConsolidateRpt = true;
    viewLrDwsWeightReport = false;
    viewStocksForLocalBookingBarcodeScr = false;
    viewConsignorAdminRateMasterScr = false;
    viewSrcLrRateChkRpt = false;
    viewMemoPODUploadScr = false;
    viewVehicleDDChargeSetup = false;
    viewAdvanceConsentProcessScr = false;
    viewCflFormulaSetupScr = false;
    viewInchargeMasterScr = false;
    viewLoaderHeadMasterScr = false;
    viewCftExemptionSetupScr = false;
    viewCftRangeRptScr = false;
    viewPaymentFollowupBookingScr = false;
    viewConsignorTopartyMasterScr = false;
    viewPaidCollectionReportScr = false;
    viewBookingPartyOSReportScr = false;
    viewHirepaymentEnetBankScr = false;
    viewSpecificConsigneeDetailsScr = false;
    viewEnableCCLRScr = false;
    viewLrHoldReasonScr = false;
    viewConsigneeMobileNoConfirmScr = false;
    viewConsignorMobileNoConfirmScr = false;
    viewLRDeleteMasterPwdSetup = false;
    viewGstInvoiceDetainedScr = false;
    viewPartywiseCFTDetailsReportScr = false;
    viewGstSearchScr = false;
    viewLrBkgDateCustomChangeScr = false;
    viewloadingInvoiceEditScr = false;
    viewAgentSubstationMasterScr = false;
    viewGenNewEwbScr = false;
    viewEditLrFreightScr = false;
    viewRewardingNewVersionScr = false;
    viewPartywisePerformanceMonitorScr = false;
    viewPerKgRateSetupScr = false;
    viewCommodityKgLimitSetupScr = false;
    viewCommodityBookingBlockScr = false;
    viewGenerateBarcodeScr = false;
    viewLrBlockedPartywiseScr = false;
    viewDestinationStocksReportScr = false;
    viewUnclearedLrDetailsScr = false;
    viewReGenerateBarcodeScr = false;
    viewPartyContactDetailsScr = false;
    viewShortAndExtraConsolidateRptV2 = false;
    viewCashmemoOnlinePaymentDetailsScr = false;
    viewLrRateCalculatorScr = false;

    public sidebarnavItems: any[];

    loggedInUserName: string;
    loggedInUser: string;
    branch: string;
    userRole: string;
    lastLogin: string;
    // userDataDtoReturnSession: any;
    isLoggedIn = true;
    public showSearch = false;

    // this is for the open close
    addExpandClass(element: any) {
        console.log("1st");
        console.log(element);
        if (element === this.showMenu) {
            alert("1");
            this.showMenu = '0';
        } else {
            alert("2");
            this.showMenu = element;

        }
    }


    addActiveClass(element: any) {
        console.log("2nd");
        console.log(element);
        if (element === this.showSubMenu) {
            alert("3");
            this.showSubMenu = '0';
        } else {
            alert("4");
            this.showSubMenu = element;

        }
    }
    searchmenuItems: Array<AgentDetailsDto> = [];
    searchmenuItemsAll: AgentDetailsDto = new AgentDetailsDto();
    focusMenuNameListTA$ = new Subject<string>();
    menuNameListTA: Array<AgentDetailsDto> = [];
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    public modelMenuNameList: any;
    searchMenuNameList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMenuNameListTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.menuNameListTA
                : this.menuNameListTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMenuNameList = (x: { subStation: string }) => x.subStation;
    selectedActionType: any;
    public modelActionType: any;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
        // this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
        //console.log( this.userDataDtoReturnSession );
        //console.log( Object.keys( this.userDataDtoReturnSession.sortedMapFeatures ) );
        // this.scrrensToLoadAsPerRights();

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //alert( 'Your session is expired, please relogin to access the application!' );
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.loggedInUserName = this.userDataDtoReturnSession.name;
            this.loggedInUser = this.userDataDtoReturnSession.userName;
            this.userRole = this.userDataDtoReturnSession.role;
            this.branch = this.userDataDtoReturnSession.office;
            this.lastLogin = this.userDataDtoReturnSession.lastLoginStr;
            console.log(this.userDataDtoReturnSession)
            this.scrrensToLoadAsPerRights();
            this.scrrensNameToLoadAsPerRights()
        }

    }

    // End open close
    ngOnInit() {
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }


    scrrensToLoadAsPerRights() {
        for (let i = 0; i < this.userDataDtoReturnSession.listOdMenuId.length; i++) {
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '100') {
                this.viewMasterModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '200') {
                this.viewCashmemoModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '300') {
                this.viewFortnightStatementModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '400') {
                this.viewLrModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '500') {
                this.viewMobileAppModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '600') {
                this.viewStocksModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '700') {
                this.viewReportModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '110') {
                this.viewCommunicationModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '111') {
                this.viewMessageScreen = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '112') {
                this.viewSendSmsScreen = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '113') {
                this.viewSendSmsGroupDtlsScreen = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '120') {
                this.viewCompanyModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '121') {
                this.viewCommonChargesScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '122') {
                this.viewDeveloperPageScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '123') {
                this.viewEditCompanyScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '124') {
                this.viewStaffMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '125') {
                this.viewStationCreationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '126') {
                this.viewUserCreationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '127') {
                this.viewUserFeaturesCustomizationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '128') {
                this.viewUserDashboardCustomizationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '129') {
                this.viewUserRightsCustomizationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7714') {
                this.viewExpensesDetailsEntryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7708') {
                this.viewConsignmentsClaimSettlementEntryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '130') {
                this.viewConsigneeModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '131') {
                this.viewCneeAdminRateMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '132') {
                this.viewCneeGroupingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '133') {
                this.viewConsigneeMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '134') {
                this.viewConsigneeMergeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '135') {
                this.viewRateMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '136') {
                this.viewConsigneeServiceTaxExpScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '137') {
                this.viewEdeclarationPasswordScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '140') {
                this.viewConsignorModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '141') {
                this.viewConsignorMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '142') {
                this.viewConsignorMergeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '143') {
                this.viewConsignorServiceTaxExpScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '150') {
                this.viewDetailsModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '151') {
                this.viewDestinationAgentScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '152') {
                this.viewBookingAgentScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '153') {
                this.viewDestinationTypeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '160') {
                this.viewFixingModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '161') {
                this.viewAgentFeatureCusmtScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '162') {
                this.viewAreaScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '163') {
                this.viewBankScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '164') {
                this.viewCollectionScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '165') {
                this.viewDebitNoteMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '166') {
                this.viewExpensesHeadsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '167') {
                this.viewStandardRateScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7710') {
                this.viewFovExemptionMasterForBkgScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7723') {
                this.viewTdsDetailsEntryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7726') {
                this.viewUnloadingChrgAmtDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '170') {
                this.viewSupplierModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '171') {
                this.viewBalanceDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '172') {
                this.viewSupplierMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '180') {
                this.viewTranshipmentModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '181') {
                this.viewDriverMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '182') {
                this.viewGetHireslipBarcodeValueScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '183') {
                this.viewLorryHireDeductionSetupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '184') {
                this.viewNatureOfPackScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '185') {
                this.viewPointToPointSerChkScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '186') {
                this.viewTruckMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7712') {
                this.viewVehicleSetupMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7713') {
                this.viewExpensesPaymentFollowupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7716') {
                this.viewConsigmentLiveTrackingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7717') {
                this.viewTrackingLiveReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '190') {
                this.viewTripModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '191') {
                this.viewRoutingFeatureScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '192') {
                this.viewTimeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '193') {
                this.viewTruckCapacityScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7715') {
                this.viewTripEtaSetupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1900') {
                this.viewCommodityModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1901') {
                this.viewCommodityMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1902') {
                this.viewCommodityRateMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1903') {
                this.viewTpMasterScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1904') {
                this.viewUnloadingRateScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1905') {
                this.viewCommodityRoutingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '1906') {
                this.viewCommodityMergeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '210') {
                this.viewGenerationModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '211') {
                this.viewBookingCashMemoScreen = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '212') {
                this.viewGenerateCashMemoScreen = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '213') {
                this.viewDummyMemo = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '220') {
                this.viewTransferModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '221') {
                this.viewBillingToSrdCollectionStockScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '222') {
                this.viewCashmemoTransferScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '223') {
                this.viewTransferByLrScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '224') {
                this.viewManualCashmemoCheckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '225') {
                this.viewTransferToCollectionScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7718') {
                this.viewCollectionManStockTransferScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7719') {
                this.viewColtManToColtManStockTransferScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7720') {
                this.viewChartsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7722') {
                this.viewCollectionManCashmemoStockCheckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '230') {
                this.viewPaymentFollowupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '240') {
                this.viewCashMemoBlockScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '310') {
                this.viewFortngtStmtAgentModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '311') {
                this.viewCommissionMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '312') {
                this.viewFortngtStmtCommodityRateMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '313') {
                this.viewAmountReceivableFromAgentScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '314') {
                this.viewDestinationAgentStmtGenerationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '320') {
                this.viewFortngtStmtBookingModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '729') {
                this.viewAgentCommissionMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '72011') {
                this.viewAgentStatementGenerationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '735') {
                this.viewMemoInvoiceGenerationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '330') {
                this.viewFortngtStmtHamaliModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '783') {
                this.viewLabourCommissionSetupMumbaiScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '784') {
                this.viewSrdLabourMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '785') {
                this.viewSrdLabourStmtGenerationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '340') {
                this.viewFortngtStmtOthersModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '321') {
                this.viewPartyLessCheckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '322') {
                this.viewBalancingDifferenceTallyRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '323') {
                this.viewDebitNoteStmtScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7204') {
                this.viewLrCrossCheckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7727') {
                this.viewPartyLessCheckUploadTypeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '410') {
                this.viewEditModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '411') {
                this.viewInvoieLrEditScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '412') {
                this.viewLrEditScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '413') {
                this.viewLrsShortAndExtraScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '414') {
                this.viewSalesTaxLrScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '420') {
                this.viewEnquiryModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '421') {
                this.viewConsigneeDetailsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '422') {
                this.viewLrDeliveryStatusScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '423') {
                this.viewLrAdvanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '424') {
                this.viewLrEnquiryFormScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '425') {
                this.viewLrInSalesTaxScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '430') {
                this.viewEntryModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '431') {
                this.viewLrEntryFormScr = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '432') {
                this.viewMissAgentScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '440') {
                this.viewIssueModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '441') {
                this.viewChallanScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '442') {
                this.viewLrIssueScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '510') {
                this.viewAboutUsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '520') {
                this.viewLatestNewsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '530') {
                this.viewMobileAppUserRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '540') {
                this.viewOurBranchesScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '550') {
                this.viewRouteMappingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '610') {
                this.viewCustomizationModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '611') {
                this.viewManualStockCheckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '612') {
                this.viewStocksGroupingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '620') {
                this.viewDriverModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '621') {
                this.viewRewardingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '622') {
                this.viewBarcodeScanningScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '630') {
                this.viewPaymentsModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '631') {
                this.viewHireslipAdvanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '632') {
                this.viewHireslipBalanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '640') {
                this.viewStocksTransferModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '641') {
                this.viewShipmentStatusScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '642') {
                this.viewStocksForTripsheetScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '643') {
                this.viewStocksInTakenScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '644') {
                this.viewStockstoCPScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '645') {
                this.viewStocksBetweenGodownsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '646') {
                this.viewStocksToGodownScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '647') {
                this.viewStocksForLocalBookingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '710') {
                this.viewRptAgentsModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '711') {
                this.viewRankingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '712') {
                this.viewStatementVerificationScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '713') {
                this.viewDestCommissionScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '714') {
                this.viewDestIncomeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '720') {
                this.viewBookingModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '721') {
                this.viewAgentExpensesScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '722') {
                this.viewBookingAgentRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '723') {
                this.viewAgentStatementScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '724') {
                this.viewAgentTDSOnLoadingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '725') {
                this.viewMemoInvoiceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '726') {
                this.viewMemoScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '727') {
                this.viewOfficeDailyExpensesScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '728') {
                this.viewOfficeHireslipScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '729720') {
                this.viewAgentLocalTripScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '730') {
                this.viewCashmemoRptModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '731') {
                this.viewAmountReceivableScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '732') {
                this.viewCollectionManScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '733') {
                this.viewSRDCollectionStockRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '734') {
                this.viewPaidReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '736') {
                this.viewCashMemoReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '737') {
                this.viewCashmemoDetailsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '738') {
                this.viewLetterHeadReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '739') {
                this.viewToBeBilledPartyScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7311') {
                this.viewBookingCashmemoScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7312') {
                this.viewBookingCashmemoByInvoiceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7721') {
                this.viewCashMemoInCollectionManStockScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7724') {
                this.viewChequeSearchDetailsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7725') {
                this.viewMemoLessDetailsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7728') {
                this.viewTotalLessReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '760') {
                this.viewDebitModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '761') {
                this.viewDebitNoteRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '762') {
                this.viewSummaryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '770') {
                this.viewDailyModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '771') {
                this.viewBangaloreOfficeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '772') {
                this.viewDailyBookingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '773') {
                this.viewDoorDeliveryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '774') {
                this.viewLoadingDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '775') {
                this.viewOfficeReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '780') {
                this.viewHamaliRptModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '781') {
                this.viewBrokerRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '782') {
                this.viewLabourStatementRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '790') {
                this.viewHireslipAndInvoiceModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7100') {
                this.viewInvoiceModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7101') {
                this.viewInvoiceDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7102') {
                this.viewServiceTaxScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '791') {
                this.viewAdvancePaymentScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '792') {
                this.viewHireslipDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '793') {
                this.viewHireslipHistoryRptcr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '794') {
                this.viewHireslipUnloadingRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '795') {
                this.viewLocalTripSheetScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '796') {
                this.viewTransshipmentTripScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7200') {
                this.viewLrRptModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7201') {
                this.viewAutomatedSearchScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7202') {
                this.viewDelayed_E_SugamScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7203') {
                this.viewGcNotReceivedScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7205') {
                this.viewLorryHireBalanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7206') {
                this.viewLorryHireStatementScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7207') {
                this.viewLRDispatchBookingRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7208') {
                this.viewLRHistoryRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7209') {
                this.viewInSalesTaxScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7210') {
                this.viewIssueBalanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7211') {
                this.viewLRIssueRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7212') {
                this.viewPerformanceScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7213') {
                this.viewLRReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7214') {
                this.viewChallanIssueScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7215') {
                this.viewShortAndExtraScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7216') {
                this.viewShortAndExtraConflictScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7300') {
                this.viewPartyModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '740') {
                this.viewConsigneeRptModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '741') {
                this.viewConsigneeAdminRateRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '742') {
                this.viewRateHistoryScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '743') {
                this.viewRateReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '744') {
                this.viewConsingeeRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '745') {
                this.viewBusinessStopReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '746') {
                this.viewBusinessStartReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '747') {
                this.viewAdminBusinessRateReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '750') {
                this.viewConsignorRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7302') {
                this.viewDiscontinuousDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7303') {
                this.viewPartyWiseOscr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7304') {
                this.viewPartyWiseTotalStatusDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7305') {
                this.viewCommodityMismatchRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7400') {
                this.viewStatementModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7401') {
                this.viewInvoiceRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7402') {
                this.viewStatementRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7403') {
                this.viewPartyLessCheckedAndUncheckLrDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7500') {
                this.viewStocksRptModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7501') {
                this.viewCommodityReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7502') {
                this.viewGodownStocksScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7503') {
                this.viewGodownStocksBookingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7504') {
                this.viewPendingDetailsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7505') {
                this.viewStocksInTakenRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7506') {
                this.viewStorageReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7507') {
                this.viewStocksUnloadingRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7730') {
                this.viewBkgAgentsDispatchPerformanceRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7600') {
                this.viewTruckModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7601') {
                this.viewTruckAverageScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7602') {
                this.viewHaltAndDispatchedDtlsScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7603') {
                this.viewNonAvailabilityOfTruckScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7604') {
                this.viewPerformanceReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7605') {
                this.viewTruckReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7606') {
                this.viewHireDetailsByDestScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7607') {
                this.viewTruckOwnerDeclarationRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7700') {
                this.viewOthersModule = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7701') {
                this.viewLcAndBcRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7702') {
                this.viewLoaderPerformanceRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7703') {
                this.viewMonthlyReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7704') {
                this.viewSalesTaxReportScr = false;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7705') {
                this.viewCbrReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7706') {
                this.viewDriverRewardedReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7711') {
                this.viewTempoPerformanceReportScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7735') {
                this.viewChequeDetailsScr = true;
            }
            /// for testing  i put 7712 
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7301') {
                this.viewPartyLessReportScr = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7731') {
                this.viewLiveTrackReport = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7732') {
                this.viewLiveTrack = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7736') {
                this.viewEwayBillDateValidationExtensionReport = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7737') {
                this.viewAgentTempoMasterScr = true;
            }
            // if ( this.userDataDtoReturnSession.listOdMenuId[i] == '7737' ) {
            // 	this.viewShortAndExtraConsolidateRpt = true;
            // }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7739') {
                this.viewLrDwsWeightReport = true;
            }

            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7740') {
                this.viewStocksForLocalBookingBarcodeScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7741') {
                this.viewConsignorAdminRateMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7742') {
                this.viewSrcLrRateChkRpt = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7743') {
                this.viewMemoPODUploadScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7744') {
                this.viewVehicleDDChargeSetup = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7745') {
                this.viewAdvanceConsentProcessScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7746') {
                this.viewCflFormulaSetupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7747') {
                this.viewInchargeMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7748') {
                this.viewLoaderHeadMasterScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7749') {
                this.viewCftExemptionSetupScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7750') {
                this.viewCftRangeRptScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7751') {
                this.viewPaymentFollowupBookingScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7752') {
                this.viewConsignorTopartyMasterScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7753') {
                this.viewPaidCollectionReportScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7754') {
                this.viewBookingPartyOSReportScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7755') {
                this.viewHirepaymentEnetBankScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7756') {
                this.viewEnableCCLRScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7757') {
                this.viewSpecificConsigneeDetailsScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7758') {
                this.viewLrHoldReasonScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7759') {
                this.viewConsigneeMobileNoConfirmScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7760') {
                this.viewConsignorMobileNoConfirmScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7761') {
                this.viewLRDeleteMasterPwdSetup = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7762') {
                this.viewGstInvoiceDetainedScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7763') {
                this.viewPartywiseCFTDetailsReportScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7764') {
                this.viewGstSearchScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7765') {
                this.viewLrBkgDateCustomChangeScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7766') {
                this.viewloadingInvoiceEditScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7767') {
                this.viewAgentSubstationMasterScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7768') {
                this.viewGenNewEwbScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7769') {
                this.viewEditLrFreightScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7770') {
                this.viewRewardingNewVersionScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7771') {
                this.viewPartywisePerformanceMonitorScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7772') {
                this.viewPerKgRateSetupScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7773') {
                this.viewCommodityKgLimitSetupScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7774') {
                this.viewCommodityBookingBlockScr = true;
            }
            if (this.userDataDtoReturnSession.listOdMenuId[i] == '7775') {
                this.viewGenerateBarcodeScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7776') {
                this.viewLrBlockedPartywiseScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7777') {
                this.viewDestinationStocksReportScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7778') {
                this.viewUnclearedLrDetailsScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7779') {
                this.viewReGenerateBarcodeScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7780') {
                this.viewPartyContactDetailsScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7781') {
                this.viewShortAndExtraConsolidateRptV2 = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7782') {
                this.viewCashmemoOnlinePaymentDetailsScr = true;
            } if (this.userDataDtoReturnSession.listOdMenuId[i] == '7783') {
                this.viewLrRateCalculatorScr = true;
            }

        }
    }
    signout() {
        sessionStorage.clear();
        this.router.navigate(['/authentication/login']);
    }
    editProfile() {
        this.dialog.open(EditProfilePopupComponent);
    }

    scrrensNameToLoadAsPerRights() {
        this.menuNameListTA = [];
        for (let i = 0; i < this.userDataDtoReturnSession.listOfScreenNames.length; i++) {
            this.searchmenuItemsAll = new AgentDetailsDto();
            this.searchmenuItemsAll.subStation = this.userDataDtoReturnSession.listOfScreenNames[i];
            this.searchmenuItems.push(this.searchmenuItemsAll);
        }
        for (let i = 0; i < this.searchmenuItems.length; i++) {
            this.menuNameListTA.push(this.searchmenuItems[i]);
        }

        // this.menuNameListTA =this.agentDtoFromStationAll;
    }
    clearMethod() {
        console.log('clear');
        this.searchField.nativeElement.value = ' ';
        this.modelMenuNameList = '';
        $("#menuNameList").val('');
        // this.showSearch = false;
    }
    actionTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelActionType = e.item;
        console.log(this.modelActionType);
        this.selectedActionType = this.modelActionType.subStation;
        console.log(this.selectedActionType);



        if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Local Trip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentLocalTrip']);


        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Entry Form') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/lr/entry/lrentry']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Send SMS Group Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/communication/sendSmsGroupDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/masters/consignee/consigneeMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Master') {
            this.selectedActionType = '';
            this.modelMenuNameList = '';
            $("#menuNameList").val('');
            this.modelActionType = '';
            this.modelMenuNameList = null;

            this.router.navigate(['/masters/consignor/consignorMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/masters/consignor/consignorMerge']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Bank') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/masters/fixing/bankMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Heads') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/masters/fixing/expensesHeads']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Supplier Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/masters/supplier/suppliermaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Driver Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/driverMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Nature Of Pack') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/natureOfPack']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LC and BC Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/lcAndBcReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/performanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Non Availability Of Truck') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/nonAvailabilityOfTruckReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agents Dispatch Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/bookingAgentDispatchPerformanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Unloading Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/stocksUnloadingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Storage Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/storageReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks In Taken Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/stocksInTakenReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Pending Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/pendingStocksDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Godown Stocks Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/godownStocksBookingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Godown Stocks') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/godownStocksReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/commodityReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Checked And Uncheck LR Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/statement-reports/partyLessCheckedAndUncheckedLrDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Statement Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/statement-reports/statementRpt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Mismatch Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/commodityMismatchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Wise Total Status Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyWiseTotalStatusDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Wise OS') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyWiseOsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyLessReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Discontinuous Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/discontinuousDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Business Start Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/businessStartReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Business Stop Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/businessStopReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate History') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/rateHistoryReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short And Extra') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/shortAndExtraReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrRpt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'History Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrHistoryReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Dispatch Booking Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrDispatchBookingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lorryHireBalance']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'G.C. Not Received') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/gcNotReceivedReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Trip Sheet') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/localTripSheet']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Hireslip Unloading Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/hireslipUnloadingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Service Tax') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/serviceTaxReport']);
        }



        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Invoice Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/invoiceDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/debit/debitNoteReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo Less Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/memoLessSearchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cheque Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/chequeDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cheque Search Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/chequeSearchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo In Collection Man Stock') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashMemoInCollectionManStock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LetterHead Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/letterHeadReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoreport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Collection Stock Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/srdCollectionStockReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/collectionManReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Amount Receivable') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/amountreceivable']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Statement Verification') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/statementVerification']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Between Godowns') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stocksBetweenGodown']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks to CP') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stocksToCp']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'HireSlip Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/payment/hireslipBalance']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rewarding') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/stock/driver/rewarding']);

        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Manual Stock Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/customization/manualStockCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Advance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrAdvance']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo Block') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/cashmemoBlock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Payment Followup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/paymentFollowup']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man Cashmemo Stock Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManCashmemoStockCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man To Collection Man Stock Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManToCollectionManStockTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man Stock Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManStockTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Transfer To Collection') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/transferToCollection']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Manual Cashmemo Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/manualCashmemoCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/cashmemoTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Billing To SRD Collection Stock') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/billingToSrdCollectionStock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Dummy') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/dummyCashmemo']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate Cash Memo') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/generateCashmemo']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Tempo Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/agentTempoMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/truckMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Edit Company') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/editCompany']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Automated Search') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/automatedSearch']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Challan Details Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/localChallanReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Message') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Common Charges') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/commonCharges']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Developer Page') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Staff Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/staffMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Station Creation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Creation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/userCreation']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Features Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/userFeaturesCustomization']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Dashboard Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Rights Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Details Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/expensesDetailsEntry']);
        }

        //new

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignments Claim Settlement Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/consignmentsClaimSettlementEntry']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Admin Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeAdminRateMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Grouping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeGrouping']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeMerge']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/rateMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Service Tax Exemption') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'E-Declaration Password') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Service Tax Exemption') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Type') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Features Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Area') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/areaMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/collectionMan']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/debitNoteMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Heads') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/expensesHeads']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Standard Rate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'FOV Exemption Master for Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/fovExemptionMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'TDS Details Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/tdsDetailsEntry']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Balance Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }


        //new2

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Get Hireslip Barcode Value') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/getHireslipBarcodeValue']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Deduction Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/lorryHireDeductionSetupMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Point To Point Service Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/pointToPointServiceCheck']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Vehicle Setup Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Payment Followup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/expensesPaymentFollowupReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Routing Feature') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Reward Time Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/trip/time']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Capacity') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Trip ETA Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/trip/tripEtaSetup']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Live Tracking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/vehicleTracking']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Tracking Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/trackingLiveReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMaster']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'TP Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/tpMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Unloading Rate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Routing') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMerge']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Cash Memo') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/bookingCashmemo']);
        }

        ///new3

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commission Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/commissionmaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Amount Receivable From Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/amountReceivableFromAgent']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Agent Statement Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/destinationAgentStatementGeneration']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Commision Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/booking/agentCommissionMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Statement Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/booking/agentStatementGeneration']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo Invoice Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            //this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Labour Commission Setup Mumbai') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/labourcommissionsetupmumbai']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Labour Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/srdlabourmaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Labour Stmt Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/srdLabourStatementGeneration']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Balancing Difference Tally Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/others/balancingDifferenceTallyReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LRs Short & Extra') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/lrsShortAndExtra']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Enquiry Form') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrEnquiry']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'About Us') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/aboutus']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Latest News') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/latestnews']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Our Branches') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/ourbranches']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Route Mapping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/routeMapping']);
        }



        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Grouping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/trip-creation/stocksGrouping']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Shipment Status') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/shipmentstatus']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks For Tripsheet') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stocks-for-tripsheet-page/stocksForTripsheet']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Ranking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/ranking']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Expenses') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentExpenses']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Statement') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentStatement']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent TDS On Loading') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/bkgAgentTdsLdg']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Hireslip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/officeHireslip']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Paid Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/paidreport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Total Less Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/totalLessRpt']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Bangalore Office Daily Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/bangaloreOffice']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Daily Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/dailyBookingReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/officeReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Labour Statement Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hamali-report/labourStatementReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Advance Payment') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/advancePayment']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Hireslip Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/hireslipDetails']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Transshipment Trip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/transhipmentTrip']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Statement') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lorryHireStatement']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR History Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrHistoryReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Issue Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/issueBalance']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Issue Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrIssueReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Performance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrPerformanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Challan Issue') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/challanIssueReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'EWay Bill Date Validation Extension Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/eWayBillDateValidationExtensionRpt']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Admin Rate Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeAdminRateReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeRateReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Admin Business Rate Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/party-report/adminBusinessRateReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Challan Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/localChallanReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loader Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/loaderPerformanceReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/truckReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Halt And Dispatched Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/haltAndDispatchedDetails']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks For Local Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stockForLocalBooking']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consignorReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Broker Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hamali-report/brokerreport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Edit') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/lredit']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Check - Upload Type') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/others/partyLessCheckUploadType']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Challan') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/issue/challan']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lr Issue') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/issue/lrissue']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Miss Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/entry/missAgent']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consignorReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agent Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/bookingAgentReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMerge']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Hireslip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/officeHireslip']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/commodityratemaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['fortnightStatement/others/partyLessCheck']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Tempo Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/others-report/tempoPerformanceReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short & Extra Consolidate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/shortAndExtraConsolidateRpt']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Invoice Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/statement-reports/invoiceReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR DWS Weight Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/lrDwsWgtRpt']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Local Trip Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stockForLocalBookingBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Stmt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['fortnightStatement/others/debitNoteStmt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Send SMS') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['masters/communication/sendSms']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor & LR Entry Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['masters/consignor/consignorRateMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Source LR Entry Rate Check Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/srcLRRateChkRpt']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Owner Declaration Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['report/truck-report/truckOwnerDeclarationReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo POD Upload') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/memoPodUpload']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Vehicle Setup Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/vehicleSetupMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Trip Advance Consent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/getAdvanceConsent']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'CFT Formula Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/cflFormulaSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loader Name Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/staffMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loading Incharge Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/inchargeMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loader Head Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/loaderHeadMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'CFT Exemption Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/cftExemptionSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'CFT Range Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/cftRangeReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Payment Followup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/paymentFollowupBooking']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Toparty Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignor/consignorTopartyMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Paid Collection Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/paidCollectionReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Party OS Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/bkgPartyOsReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'E-Net Lorry Hire Payment') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/hirepaymentEnet']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Enable CC Copy In LR Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/entry/enablelrcccopy']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Specific Consignee Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/specificConsigneeDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Commission') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/destinationCommission']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Income') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/destinationIncome']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Hold Reason Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/lrHoldReason']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Invoice Lr Edit') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/invoiceLrEdit']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Mobile Number Confirm') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeMobileNoConfirm']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Mobile Number Confirm') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignor/consignorMobileNoConfirm']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Delete Master Password Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignor/lrDeletePwdSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'GST Invoice Detained') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/gstInvoiceDetained']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Partywise CFT Details Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/partywiseCFTDetailsReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'GST Search') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/gstSearch']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Booking Date - Custom Change') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/lrBkgDateCustomChange']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loading Invoice Edit') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/trip-creation/loadingInvoiceEdit']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Substation Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/details/agentSubstationMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate New EWB') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/generateNewEwb']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Edit LR Freight') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/editLrFreight']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rewarding') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/driver/rewardingNewVersion']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Partywise Performance Monitor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['masters/company/partywisePerformanceMonitor']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Per Kg Rate Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/commodityKgLimitSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Kg Limit Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/commodityKgLimitSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Booking Block') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityBookingBlock']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/lr/entry/generateBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Blocked Partywise') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/lrBlockedPartywise']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Stocks Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/destinationStocksReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Uncleared Lr Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/unclearedLrDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Re Generate Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');

            this.router.navigate(['/lr/entry/reGenerateBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Contact Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/partyContactDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short & Extra Overview') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/shortAndExtraOverview']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Online Payment Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoOnlinePaymentDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Rate Calculator') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelMenuNameList = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrRateCalculator']);
        }

        this.clearMethod();
    }
}


