<div class="row" *ngIf="isLoggedIn" id="{{pageId}}gcewb">
    <div class="col-lg-12">
        <div class="card" style="border: 1px solid darkcyan !important;">
            <div *ngIf="showSpinnerForAction" class="col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                            Please Wait Getting E-Way Bill Details.....</h6>
                    </div>
                </div>
            </div>
            <div class="card-body">


                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>From State *</label>
                            <div class="input-group">
                                <input #stateCode id="{{pageId}}stateCode" type="text" class="form-control"
                                    [(ngModel)]="modelStateNameAndCode" [ngbTypeahead]="searchStateNameAndCode"
                                    [resultFormatter]="formatterStateNameAndCode"
                                    [inputFormatter]="formatterStateNameAndCode"
                                    (selectItem)="clickListnerForState($event)"
                                    (focus)="focusStateNameAndCodeTA$.next($any($event).target.value)"
                                    autocomplete="off" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Vehicle From *</label>
                            <div class="input-group">
                                <input #vehicleFrom type="text" id="{{pageId}}vehicleFrom" class="form-control"
                                    autocomplete="off" (keyup)="fieldFocus($event, vehicleNo)">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Vehicle No *</label>
                            <div class="input-group">
                                <input #vehicleNo type="text" id="{{pageId}}vehicleNo" class="form-control"
                                    autocomplete="off" (keyup)="fieldFocus($event, billDate)">
                            </div>
                        </div>
                    </div>


                </div>

                <hr>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <label>E-Way Bill No </label>
                                <input #ewayBillNo type="text" id="{{pageId}}ewayBillNo" class="form-control"
                                    placeholder="Search by EWay Bill No" aria-describedby="basic-addon11"
                                    autocomplete="off" (keyup)="validateEwayBillDetails($event)">
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4">
                        <button type="button" id="{{pageId}}addEwayBillInTable" title="Add E-way Bill"
                            (click)="validateForEWayBillNo()">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <table datatable id="{{pageId}}ewayBillDetailsTableId"
                                    class="table table-striped table-bordered row-border hover"
                                    [dtOptions]="dtOptionsEwayBillDtls" [dtTrigger]="dtTriggerEwayBillDtls">
                                    <thead>
                                        <tr>
                                            <th>E-Way Bill No</th>
                                            <th>E-way Bill Date</th>
                                            <th>Generated By</th>
                                            <th>Inv.No & Date</th>
                                            <th>Inv. Amount</th>
                                            <th>Source</th>
                                            <th>Valid Till</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ewayBillData of ewayBillDetails">
                                            <td>{{ ewayBillData.eWayBillNo }} </td>
                                            <td>{{ ewayBillData.transDocDate }}</td>
                                            <td>{{ ewayBillData.transportId }}</td>
                                            <td>{{ ewayBillData.transDocNo }} - {{ ewayBillData.transDocDate }}</td>
                                            <td>{{ ewayBillData.goodsValue }}</td>
                                            <td>{{ ewayBillData.source }}</td>
                                            <td>{{ ewayBillData.ewayBillValidUptoStr }}</td>
                                            <td><button type="button" class="btn btn-danger waves-light"
                                                    (click)="removeFromEwayBillDetails(ewayBillData)">
                                                    <i class="fas fa-minus-circle" style="font-size: 23px;"></i>
                                                </button> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}confirmEWBId"
                            (click)="validateConfirmConsolidateEWBDetails();">Confirm</button>
                        <button type="submit" class="btn btn-success m-r-10" (click)="clearFields();"
                            id="{{pageId}}clearFieldsBtn">Clear</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>