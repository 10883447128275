<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center'
                                                style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text"
                                                            class="form-control" [(ngModel)]="agentNameModal"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [ngbTypeahead]="agentNameSearchTA"
                                                            [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName"
                                                            placeholder="Select Source"
                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                            #instanceAgentName="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text"
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Rate Type &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}rateTypeId"
                                                        name="rateType" #rateType (change)="rateTypes(rateType.value)">
                                                        <option value="commodity">Commodity</option>
                                                        <option value="party">Party</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Date &nbsp;*</label>
                                                    <input id="{{pageId}}bkgDate" class="form-control"
                                                        placeholder="yyyy-mm-dd" ngbDatepicker #date="ngbDatepicker"
                                                        autocomplete="off" [(ngModel)]="tdyBookingDate"
                                                        #bookingDates="ngbDatepicker" (click)="bookingDates.toggle()">
                                                    <div class="input-group-append" (click)="bkgToDate.toggle()">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodity" type="text"
                                                        class="form-control" [(ngModel)]="modelCommodityList"
                                                        [ngbTypeahead]="searchCommodityList"
                                                        [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList"
                                                        (keyup)="partyCommodityFocus($event)"
                                                        (focus)="focusCommodityListTA$.next($any($event).target.value)"
                                                        autocomplete="off" placeholder="Select Commodity.."
                                                        (change)="clickListnerForRateCommodity($event);" />
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;

                                        <h6 *ngIf="viewPartyFields" class="col-sm-12 col-md-12" class="card-title"
                                            style="padding-left: 15px;">
                                            Consignor Details</h6>
                                        <div *ngIf="viewPartyFields" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consignorGSTNumber placeholder="Consingor Gst No"
                                                        (keyup)="getConsignorDetailsOnEnter($event)" type="text"
                                                        class="form-control" id="{{pageId}}consignorGSTNumber">
                                                </div>
                                            </div>


                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <!-- <input #consignorName id="{{pageId}}consignorName"
                                                            type="text" class="form-control"
                                                            (selectItem)="rowSelectedConsignor($event,content9,contentEinvoice)"
                                                            [(ngModel)]="modelConsignorName"
                                                            [ngbTypeahead]="consignorSearchTA"
                                                            (focus)="focusConsignorTA$.next($any($event).target.value)"
                                                            [resultFormatter]="formatterConsignorName"
                                                            [inputFormatter]="formatterConsignorName"
                                                            (click)="clickTA(instanceConsignorName)"
                                                            #instanceConsignorName="ngbTypeahead"> -->
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}consignorName"
                                                            aria-describedby="basic-addon11" autocomplete="off"
                                                            readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consignorMobNo type="text" id="{{pageId}}consignorMobNo"
                                                        name="consignorMobNo" class="form-control"
                                                        aria-describedby="basic-addon11"
                                                        placeholder="Consignor Mobile Number" readonly>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <textarea class="form-control" id="{{pageId}}consignorAddress"
                                                        placeholder="Enter Address" rows="1" readonly></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        &nbsp;
                                        <h6 *ngIf="viewPartyFields" class="col-sm-12 col-md-12" class="card-title"
                                            style="padding-left: 15px;">
                                            Consignee Details</h6>
                                        <div *ngIf="viewPartyFields" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consigneeGSTNumber placeholder="Consingee Gst No"
                                                        (keyup)="getConsigneeDetailsOnEnter($event)" type="text"
                                                        class="form-control" id="{{pageId}}consigneeGSTNumber">
                                                </div>
                                            </div>

                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}consigneeName"
                                                            aria-describedby="basic-addon11" autocomplete="off"
                                                            readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consigneeMobNo type="text" id="{{pageId}}consigneeMobNo"
                                                        name="consigneeMobNo" class="form-control"
                                                        aria-describedby="basic-addon11"
                                                        placeholder="Consignor Mobile Number" readonly>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <textarea class="form-control" id="{{pageId}}consigneeAddress"
                                                        placeholder="Enter Address" rows="1" readonly></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 vl">
                    <div class="col-md-12" class="row">
                        <div class="col-lg-6 col-md-6 vl">
                            &nbsp;
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>No.Of Articles</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-clone"></i>
                                            </span>
                                        </div>
                                        <input #noOfArticles id="{{pageId}}noOfArticles" type="number"
                                            (keyup)="fieldFocusArticles($event)" class="form-control"
                                            aria-describedby="basic-addon11">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Actual Weight</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                            </span>
                                        </div>
                                        <input #actualWeight id="{{pageId}}actualWeight"
                                            (keyup)="actualWeightEvent($event)" (onblur)="actualWeightBlur()"
                                            type="number" class="form-control" aria-describedby="basic-addon11">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Charged Weight</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                            </span>
                                        </div>
                                        <input #chargedWeight id="{{pageId}}chargedWeight"
                                            (blur)="chargedWeightBlur(content7)" type="number" class="form-control"
                                            aria-describedby="basic-addon11" [readonly]="chgWgtReadOnly">

                                        <!-- <input *ngIf="lrArtScanAllow" #chargedWeight
                                                id="{{pageId}}chargedWeight"
                                                (keyup)="chargedWeightEvent($event, content7WithScan)"
                                                (blur)="chargedWeightBlur(content7WithScan)"
                                                type="number" class="form-control"
                                                aria-describedby="basic-addon11"> -->
                                    </div>
                                </div>
                            </div>

                            &nbsp;
                            <div class="col-sm-12 col-md-12">
                                <div class="col-md-12" style="text-align: center;">
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                        #searchBtn (click)="getPartyRateDetails()">Search</button>
                                    <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"
                                        (click)="clearDetails()">Clear</button>
                                </div>
                            </div>
                            <div [hidden]="!partyFrieghtDetails">
                                <hr>
                                <h6 class="card-title">Freight Details</h6>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>B.Freight: </label>
                                        <label>{{partyBaseFreight}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Hamali: </label>
                                        <label>{{partyHamali}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>GC.Chg: </label>
                                        <label>{{partyGcCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>A.O.C: </label>
                                        <label>{{partyAocCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Others: </label>
                                        <label>{{partyOtherCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Total Amount: </label>
                                        <label>{{totalAmount}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="!consignorRateFlowSet" class="col-lg-6 col-md-6 vl">
                            <h6 class="card-title">Consignor / Commodity Rate Details</h6>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Source: </label>
                                    <label>{{consignorRateSrc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Destination: </label>
                                    <label>{{consignorRateDest}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate For: </label>
                                    <label>{{consignorRateFor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Commodity: </label>
                                    <label>{{consignorRateCommodity}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignor: </label>
                                    <label>{{consignorRateConsignor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignee: </label>
                                    <label>{{consignorRateConsignee}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate Type: </label>
                                    <label>{{consignorRateType}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Type: </label>
                                    <label>{{consignorRatePerUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate On AW: </label>
                                    <label>{{consignorRatePerUnitRateOnActWgt}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>B. Rate: </label>
                                    <label>{{consignorRateBasicRate}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali: </label>
                                    <label>{{consignorRateHamali}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Chg: </label>
                                    <label>{{consignorRateGcChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC: </label>
                                    <label>{{consignorRateAoc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others: </label>
                                    <label>{{consignorRateOthers}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Chg: </label>
                                    <label>{{consignorRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV (%): </label>
                                    <label>{{consignorRateFov}}</label>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="!consigneeRateFlowSet" class="col-lg-6 col-md-6 vl">
                            <h6 class="card-title">Consignee Rate Details</h6>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Source: </label>
                                    <label>{{consigneeRateSrc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Destination: </label>
                                    <label>{{consigneeRateDest}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Commodity: </label>
                                    <label>{{consigneeRateCommodity}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignor: </label>
                                    <label>{{consigneeRateConsignor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignee: </label>
                                    <label>{{consigneeRateConsignee}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate Type: </label>
                                    <label>{{consigneeRateUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Type: </label>
                                    <label>{{consigneeRatePerUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate On AW: </label>
                                    <label>{{consigneeRateActWgt}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>B. Rate: </label>
                                    <label>{{consigneeRateBasicChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali: </label>
                                    <label>{{consigneeRateHamali}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Chg: </label>
                                    <label>{{consigneeRateGcChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC: </label>
                                    <label>{{consigneeRateAoc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others: </label>
                                    <label>{{consigneeRateOthers}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Chg: </label>
                                    <label>{{consigneeRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV (%): </label>
                                    <label>{{consigneeRateRiskChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Chg: </label>
                                    <label>{{consigneeRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV (%): </label>
                                    <label>{{consigneeRateRiskChg}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>