//CHG_VER_PHG_01_Changed the loading flow to load using barcode scanning (Imran  on 13/11/2024)
//CHG_VER_PHG_02 Added new method for barode wise loading soo removed old condition (Imran  on 12/20/2025)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject, Subscription } from 'rxjs';
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { StockService } from 'src/app/dataService/stock-service';

@Component({
    selector: 'app-pending-hireslip-generation',
    templateUrl: './pending-hireslip-generation.component.html',
    styleUrls: ['./pending-hireslip-generation.component.css']
})
export class PendingHireslipGenerationComponent implements OnInit {

    pendingHireslipGenerationDataList: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerPendingHireslipGeneration: Subject<any> = new Subject();
    dtOptionsPendingHireslipGeneration: any;
    invoiceDto: InvoiceDto = new InvoiceDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    lrDtoDetailForEdit: LRDto = new LRDto();
    pageId = "pnhgc";
    viewActionForBarcodeScreen = false;
    constructor(private dashboardService: DashboardService, private router: Router,
        private stockService: StockService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.enableBarcodeGen != null && this.userDataDtoReturnSession.enableBarcodeGen) {
                this.viewActionForBarcodeScreen = true;
            }
        }
    }

    ngOnInit(): void {
        this.dtOptionsPendingHireslipGeneration = {
            dom: 'Bfrtip',
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerPendingHireslipGeneration.next();
    }

    gridReconifgureOnReloadBtn() {
        this.getPendingHireSlipGenerationDetailsList();
    }

    getPendingHireSlipGenerationDetails() {
        this.invoiceDto = new InvoiceDto();
        this.invoiceDto.companyId = this.userDataDtoReturnSession.companyId;
        this.invoiceDto.status = "Loading";
        this.invoiceDto.branch = this.userDataDtoReturnSession.office;
        this.invoiceDto.mode = "BMDB";
        this.invoiceDto.stocksAt = this.userDataDtoReturnSession.office;
    }

    getPendingHireSlipGenerationDetailsList() {
        this.getPendingHireSlipGenerationDetails();
        this.showSpinnerForAction = true;
        this.dashboardService.getPendingHireSlipGeneration(this.invoiceDto).subscribe(
            (response) => {
                console.log(response);
                this.pendingHireslipGenerationDataList = [];
                //$("#"+this.pageId+"pendingHireslipGenerationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.pendingHireslipGenerationDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                // this.dtTriggerPendingHireslipGeneration.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Pending Hire Slip Generation Details", "warning");
            }, () => console.log('done');
    };

    editPendingHireslipDetails(pendingHireslipGenerationData) {
        console.log(pendingHireslipGenerationData);
        this.lrDtoDetailForEdit = new LRDto();
        this.lrDtoDetailForEdit.branch = pendingHireslipGenerationData.toStation;
        this.lrDtoDetailForEdit.mode = "hireslipGene";
        this.lrDtoDetailForEdit.invoiceNumber = pendingHireslipGenerationData.invoiceNumber;
        this.lrDtoDetailForEdit.guranteeWt = pendingHireslipGenerationData.gurWeight;
        this.lrDtoDetailForEdit.truckDataDto = pendingHireslipGenerationData;
        this.lrDtoDetailForEdit.source = pendingHireslipGenerationData.fromStation;
        this.lrDtoDetailForEdit.tripTo = pendingHireslipGenerationData.toStation;

        localStorage.clear();
        localStorage.setItem('pendingHireslipDetailsLocalStorage',
            JSON.stringify(this.lrDtoDetailForEdit));
        this.router.navigate(['/trip-creation/stocksGrouping']);
    }

    createHireslipDetails(pendingHireslipGenerationData) {
        console.log(pendingHireslipGenerationData);
        this.lrDtoDetailForEdit = new LRDto();
        this.lrDtoDetailForEdit.invoiceNumber = pendingHireslipGenerationData.invoiceNumber;
        this.lrDtoDetailForEdit.mode = "dashboardhireslip";
        this.lrDtoDetailForEdit.additional = "pendingHS";
        this.lrDtoDetailForEdit.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDetailForEdit.branchType = this.userDataDtoReturnSession.stationType;
        this.lrDtoDetailForEdit.destination = pendingHireslipGenerationData.toStation;
        this.lrDtoDetailForEdit.companyId = this.userDataDtoReturnSession.companyId;
        this.stockService.getLRList(this.lrDtoDetailForEdit).subscribe(
            (response) => {
                console.log(response);
                /* this.pendingHireslipGenerationDataList = [];
                 $("#"+this.pageId+"pendingHireslipGenerationTableId").DataTable().destroy();
                 if (response.length > 0) {
                     this.pendingHireslipGenerationDataList = response;
                 } else {
                     swal("Alert", "No Data Found", "warning");
                 }
                 this.dtTriggerPendingHireslipGeneration.next();*/
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Pending Hire Slip Generation Details", "warning");
            }, () => console.log('done');
    }

    loadingSheetFlow(pendingHireslipGenerationData) {
        localStorage.clear();
        //CHG_VER_PHG_01
        //CHG_VER_PHG_02
        // if (this.viewActionForBarcodeScreen) {
        //     pendingHireslipGenerationData.mode = 'invoice';

        //     localStorage.setItem('stockForTripsheetDashboardLocalStorage',
        //         JSON.stringify(pendingHireslipGenerationData));
        //     this.router.navigate(['/trip-creation/localTripLoadingSheetFlow']);
        // } else {

        localStorage.setItem('loadingSheetInvoiceData',
            JSON.stringify(pendingHireslipGenerationData));
        this.router.navigate(['/trip-creation/invoiceLoadingSheetFlow']);
        // }


    }

    loadingSheetFlowBarcode(pendingHireslipGenerationData) {
        localStorage.clear();

        pendingHireslipGenerationData.mode = 'invoice';
        localStorage.setItem('stockForTripsheetDashboardLocalStorage',
            JSON.stringify(pendingHireslipGenerationData));
        this.router.navigate(['/trip-creation/localTripLoadingSheetFlow']);



    }
}
