<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrShortAndExtraConsolidateRptV2">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Short & Extra Overview</h6>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Search By</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                            name="searchBy" #searchBy
                                                            (change)="searchByMode(searchBy.value)">
                                                            <option selected value="lrNoWise">LR No Wise</option>
                                                            <option value="invoiceNoWise">Invoice No Wise</option>
                                                            <option value="tripNoWise">Trip No Wise</option>
                                                            <option value="invoiceDateWise">Invoice Date Wise</option>
                                                            <option value="tripDateWise">Trip Date Wise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByLrNo" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>LR Number</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}lrNumber"
                                                            (keyup)="fieldFocus($event, searchBtn)" class="form-control"
                                                            #lrNumber name="lrNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByInvoiceNo" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Invoice No</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}invoiceNumber"
                                                            (keyup)="fieldFocus($event, searchBtn)" #invoiceNumber
                                                            name="invoiceNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchByTripNo" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Trip No</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}tripNo"
                                                            (keyup)="fieldFocus($event, searchBtn)" #tripNo
                                                            name="tripNo" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div *ngIf="viewDate" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <div class="input-group">
                                                        <input class="form-control" id="{{pageId}}fromDate"
                                                            placeholder="dd-mm-yyyy" name="fromDate" ngbDatepicker
                                                            #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>To Date</label>
                                                    <div class="input-group">
                                                        <input class="form-control" placeholder="dd-mm-yyyy"
                                                            id="{{pageId}}toDate" name="toDate" ngbDatepicker
                                                            #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-2">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}searchBtn" #searchBtn
                                                    (click)="searchDetailsBtn()">Search</button>
                                                <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                                    (click)="clearBtn()">Clear</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <hr style="width: 80%; border-top: none; margin: 3px;"> -->
                    </div>
                    <div class="col-md-12 vl p-t-10">
                        <div *ngIf="showSpinnerForAction" class="col-md-8">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10">
                                        <div class="col-md-8"></div>
                                        <div class="col-md-4">
                                            <button type="submit" class="dt-button" style="float: right;"
                                                id="{{pageId}}printBtn">
                                                <span><i class="fas fa-print">Print</i></span>
                                            </button>

                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <div class="table-responsive">
                                                    <table datatable id="{{pageId}}lrShortAndExtraConsolidateTableId"
                                                           class="table table-striped table-bordered row-border hover"
                                                           [dtOptions]="dtOptionsLrShortAndExtraConsolidate"
                                                           [dtTrigger]="dtTriggerLrShortAndExtraConsolidate">
                                                        <thead>
                                                            <tr>
                                                                <th>LR No</th>
                                                                <th>C/nee Name</th>
                                                                <th>Act Art</th>
                                                                <th>Local Trip 1</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                                <th>Local Trip 2</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                                <th>Local Trip 3</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                                <th>Invoice Number</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                                <th>Dummy Invoice 1</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                                <th>Dummy Invoice 2</th>
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                          
                                                            <tr *ngFor="let lrData of lrShortAndExtraConsolidateDataList">
                                                                <td>{{ lrData.lrNumber }}</td>
                                                                <td>{{ lrData.consigneeName }}</td>
                                                                <td>{{ lrData.totalArticles }}</td>
                                                                <td>{{ lrData.localTripNumber }}</td>
                                                                <td>{{ lrData.loadArtTrip1 }}</td>
                                                                <td>{{ lrData.unloadArtTrip1 }}</td>
                                                                <td>{{ lrData.localTripNumber2 }}</td>
                                                                <td>{{ lrData.loadArtTrip2 }}</td>
                                                            <td>{{ lrData.unloadArtTrip2 }}</td>
                                                                <td>{{ lrData.localTripNumber3 }}</td>
                                                                <td>{{ lrData.loadArtTrip3 }}</td>
                                                                <td>{{ lrData.unloadArtTrip3 }}</td>
                                                                <td>{{ lrData.invoiceNumber }}</td>
                                                                <td>{{ lrData.loadArtInvoice }}</td>
                                                                <td>{{ lrData.unloadArtInvoice }}</td>
                                                                <td>{{ lrData.dummyInvoice }}</td>
                                                                <td>{{ lrData.loadArtDummyInv1 }}</td>
                                                                <td>{{ lrData.unloadArtDummyInv1 }}</td>
                                                                <td>{{ lrData.dummyInvoice2 }}</td>
                                                                <td>{{ lrData.loadArtDummyInv2 }}</td>
                                                                <td>{{ lrData.unloadArtDummyInv2 }}</td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                            </div>




                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>



</body>

</html>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>