import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LrService } from "src/app/dataService/lr-service";

@Component({
    selector: 'app-lr-short-and-extra-consolidate-rptV2',
    templateUrl: './lr-short-and-extra-consolidate-rptV2.component.html',
    styleUrls: ['./lr-short-and-extra-consolidate-rptV2.component.css']
})
export class LrShortAndExtraConsolidateRptV2Component implements OnInit {

    searchByLrNo = true;
    searchByInvoiceNo = false;
    viewDate = false;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    model: NgbDateStruct;
    model2;
    selectedSearchBy: any;
    enteredLrNumber: any;
    enteredInvoiceNumber: any;
    selectedFromDate: any;
    selectedToDate: any;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    viewCustomPrintV1 = false
    lrDto: LRDto = new LRDto();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrShortAndExtraConsolidate: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrShortAndExtraConsolidate: any;
    pageId = "lsecrcV2";
    lrShortAndExtraConsolidateDataList: any[] = [];
    searchByTripNo = false;
    enteredTripNo: any;
    lrDtoGet: LRDto = new LRDto();
    constructor(private router: Router, private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef, private lrService: LrService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsLrShortAndExtraConsolidate = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;


                $(api.column(0).footer()).html('Total : ' + data.length);

            },

        }


    }

    ngAfterViewInit(): void {
        this.dtTriggerLrShortAndExtraConsolidate.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerLrShortAndExtraConsolidate.unsubscribe();
    }


    searchByMode(searchBy: string) {
        if (searchBy === 'lrNoWise') {
            this.searchByLrNo = true;
            this.searchByInvoiceNo = false;
            this.viewDate = false;
            this.searchByTripNo = false;

        } else if (searchBy === 'invoiceNoWise') {
            this.searchByLrNo = false;
            this.searchByTripNo = false;
            this.searchByInvoiceNo = true;
            this.viewDate = false;


        } else if (searchBy === 'tripNoWise') {
            this.searchByLrNo = false;
            this.searchByInvoiceNo = false;
            this.viewDate = false;
            this.searchByTripNo = true;

        } else if (searchBy === 'invoiceDateWise') {
            this.searchByLrNo = false;
            this.searchByInvoiceNo = false;
            this.viewDate = true;
            this.searchByTripNo = false;
        } else {
            this.searchByLrNo = false;
            this.searchByInvoiceNo = false;
            this.viewDate = true;
            this.searchByTripNo = false;

        }
    }


    validateForSearch() {
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.enteredTripNo = $("#" + this.pageId + "tripNo").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.selectedSearchBy == "lrNoWise") {
            if ((this.enteredLrNumber == null) || (this.enteredLrNumber == undefined) ||
                (this.enteredLrNumber == "")) {
                swal("Mandatory fields", "Lr Number is mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraLRWiseDetails();
            }
        } else if (this.selectedSearchBy == "invoiceNoWise") {
            if ((this.enteredInvoiceNumber == null) || (this.enteredInvoiceNumber == undefined) ||
                (this.enteredInvoiceNumber == "")) {
                swal("Mandatory fields", "Invoice Number is mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraInvoiceNoWiseDetails();
            }
        } else if (this.selectedSearchBy == "tripNoWise") {
            if ((this.enteredTripNo == null) || (this.enteredTripNo == undefined) ||
                (this.enteredTripNo == "")) {
                swal("Mandatory fields", "Trip Number is mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraTripNoWiseDetails();
            }
        } else if (this.selectedSearchBy == "invoiceDateWise" || this.selectedSearchBy == "tripDateWise") {
            if ((this.selectedFromDate == null) || (this.selectedFromDate == undefined) ||
                (this.selectedFromDate == "")
                || (this.selectedToDate == null) || (this.selectedToDate == undefined) ||
                (this.selectedToDate == "")

            ) {
                swal("Mandatory fields", "Please fill mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraHireslipDateWiseDetails();
            }
        }
    }
    searchDetailsBtn() {
        this.validateForSearch();
    }

    clearBtn() {
        this.searchByLrNo = true;
        this.searchByInvoiceNo = false;
        this.viewDate = false;
        this.searchByTripNo = false;
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) {
            el.focus();
        }
    }


    getShortExtraLRWiseDetailsMethod() {
        this.lrDtoGet = new LRDto();
        this.lrDtoGet.lrNumber = this.enteredLrNumber;
        this.lrDtoGet.type = 'lrWise';
    }
    getShortExtraLRWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraLRWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptV2(this.lrDtoGet).subscribe(
            (response: any) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = response || [];

                if (this.lrShortAndExtraConsolidateDataList.length === 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }

                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);

                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }
        );

    }

    getShortExtraInvoiceNoWiseDetailsMethod() {
        this.lrDtoGet = new LRDto();
        this.lrDtoGet.invoiceNumber = this.enteredInvoiceNumber;
        this.lrDtoGet.type = 'invoiceNoWise';
    }
    getShortExtraInvoiceNoWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraInvoiceNoWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptV2(this.lrDtoGet).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }

    getShortExtraTripNoWiseDetailsMethod() {
        this.lrDtoGet = new LRDto();
        this.lrDtoGet.localTripNumber = this.enteredTripNo;
        this.lrDtoGet.type = 'tripNoWise';
    }
    getShortExtraTripNoWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraTripNoWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptV2(this.lrDtoGet).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }


    
    getShortExtraHireslipDateWiseDetailsMethod() {
        this.lrDtoGet = new LRDto();
        this.lrDtoGet.fromDate = this.selectedFromDate;
        this.lrDtoGet.toDate = this.selectedToDate;
        this.lrDtoGet.type = this.selectedSearchBy;
    }
    getShortExtraHireslipDateWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraHireslipDateWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptV2(this.lrDtoGet).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }
}
