import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { NgModule, ViewChild } from "@angular/core";
import {
  NgbModule,
  NgbModal,
  NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";
import { ElementRef } from "@angular/core";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Subject, Subscription, Observable, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { ReportService } from "src/app/dataService/report-service";
import { map, startWith } from "rxjs/operators";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from "src/app/dataService/memo-report";
import * as moment from "moment";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LRDto } from "src/app/dto/LR-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LrService } from "src/app/dataService/lr-service";

@Component({
  selector: "app-performance-report",
  templateUrl: "./performance-report.component.html",
  styleUrls: ["./performance-report.component.css"],
})
export class PerformanceReportComponent implements OnInit {
  //for datatable starts
  gettingDataFrmServiceFrSmryTable: any;

  truckPerfDataList: any;

  onDestroyUnsubscribtionSmry: Subscription;

  //for datatable ends

  //summaryTable:any;
  //for datepicker starts
  model: NgbDateStruct;
  model2;
  //for datepicker ends

  loadingIndicator = true;
  searchByHireslipNo = true;
  searchByDestination = false;
  viewDate = false;
  viewSource = true;
  viewDestination = false;
  searchByTruckNo = false;
  searchBySupplierName = false;

  //selectSourceMain = true;
  selectSourceMain = false;
  selectSourceIndividual = false;

  //for datePicker starts
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  //for datepicker ends

  //for datatable starts
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dtTriggerTruckPerf: Subject<any> = new Subject();
  dtTriggerTruckPerfTimeCal: Subject<any> = new Subject();
  dataTable: any;
  dtOptionsTruckPerf: any;
  dtOptionsTruckPerfTimeCal: any;
  //for datatable ends

  truckPerfTimeCalcDataList: any;

  isLoggedIn = true;
  userDataDtoReturnSession: any;
  address: any = null;
  showSpinnerForAction = false;

  ///For Destination
  public modelDestinationFotGet: any;
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  lrDtoOptionDestinationAll: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.destinationTA
          : this.destinationTA.filter(
            (v) =>
              v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDestination = (x: { destination: string }) => x.destination;
  destinationList: Array<any> = [];
  ///For sources MainBooking Station
  agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
  agentDtoOptionSourceStation: AgentDetailsDto[];
  agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
  public modelMainBookingSource: any;
  mainBookingSourceTA: Array<AgentDetailsDto> = [];
  focusMainBookingSourceTA$ = new Subject<string>();
  searchMainBookingSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusMainBookingSourceTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.mainBookingSourceTA
          : this.mainBookingSourceTA.filter(
            (v) =>
              v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) >
              -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterMainBookingSource = (x: { mainBookStations: string }) =>
    x.mainBookStations;
  sourceList: Array<any> = [];

  //For Sorce Stations
  lrDtoSourceStationAllOption: LRDto = new LRDto();
  sourceStationOptions: LRDto[];
  lrDtoSourceStation: LRDto = new LRDto();
  public modelSource: any;
  sourceSubStationNameTA: Array<LRDto> = [];
  focusSourceTA$ = new Subject<string>();
  searchSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusSourceTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.sourceSubStationNameTA
          : this.sourceSubStationNameTA.filter(
            (v) =>
              v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterSource = (x: { subStations: string }) => x.subStations;
  agentDto: AgentDetailsDto = new AgentDetailsDto();
  fromStationOptionGet: any;
  lrDtoFromStnAll: LRDto = new LRDto();

  ///For Supplier Name
  supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
  supplierOptions: SupplierDetailsDto[];
  public modelSupplierName: any;
  supplierNameTA: Array<SupplierDetailsDto> = [];
  focusSupplierNameTA$ = new Subject<string>();
  searchSupplierName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusSupplierNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.supplierNameTA
          : this.supplierNameTA.filter(
            (v) =>
              v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

  //For Truck No
  truckDataDtoForTruckNo: TruckDataDto = new TruckDataDto();
  truckNumberOptions: TruckDataDto[];
  public modelTruckNumber: any;
  truckNumberTA: Array<TruckDataDto> = [];
  focusTruckNumber$ = new Subject<string>();
  searchTruckNumber = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusTruckNumber$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.truckNumberTA
          : this.truckNumberTA.filter(
            (v) =>
              v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterTruckNumber = (x: { truckNumber: string }) => x.truckNumber;

  /* Hash Map Of Invoice Details start */
  hashMapSupplierIndex: Map<String, Number> = new Map<String, Number>();
  hashMapSupplier: Map<String, SupplierDetailsDto> = new Map<
    String,
    SupplierDetailsDto
  >();
  hashmapTruckNumberIndex: Map<String, Number> = new Map<String, Number>();
  hashmapTruck: Map<String, TruckDataDto> = new Map<String, TruckDataDto>();
  /* Hash Map Of Invoice Details end */

  //For Custom Print
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingV3: any;
  cashMemoDtoForCustomPrintListHeadingV4: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;
  viewCustomPrintV1: boolean;

  gurWtSummary: number;
  kantaWtSummary: number;
  enamHSummary: number;

  //
  hireslipDtoForGetUserValue: HireSlipDto = new HireSlipDto();
  hireslipDtoForGetUserValueReturn: HireSlipDto = new HireSlipDto();

  validateHireslipNumber: any;
  validateFromDate: any;
  validateToDate: any;
  validateSource: any;
  validateDestination: any;
  validateMainSource: any;
  validateVehicleNo: any;
  validateSupplierName: any;
  private newAttributeTimeCalc: any;
  fortnightDtoReturn: any;
  footerSummaryGurWt: any;
  footerKanWt: any;
  footerEnumH: any;

  pageId = "prrpt";

  constructor(
    private performanceRpt: ReportService,
    private memoLessRpt: ReportService,
    private router: Router,
    private masterReadService: MasterReadService,
    private memoReport: MemoReport,
    private modalService: NgbModal,
    private lrService: LrService, public changeDetectorRef: ChangeDetectorRef
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
      //this.getSourceDetails();
      this.getDestinationDetails();
      this.getMainBookingSourceDetails();
      this.getSourceStationDetails();
      this.getSupplierDetailsList();
      this.getTruckMasterDetailsList();


    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngOnInit(): void {
    this.dtOptionsTruckPerf = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          exportOptions: {
            columns: ":visible",
          },
        }
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 300,
      scrollCollapse: true,
      paging: false,
      info: false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };

        $(api.column(0).footer()).html('Total : ' + data.length);
      }
    },
      this.dtOptionsTruckPerfTimeCal = {
        dom: "Bfrtip",
        buttons: [

        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        responsive: true,
        scrollX: true,
        scrollY: 150,
        scrollCollapse: true,
        paging: false,
        info: false,
      };
  }

  ngAfterViewInit(): void {
    this.dtTriggerTruckPerf.next();
    this.dtTriggerTruckPerfTimeCal.next();
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerTruckPerf.unsubscribe();
    //this.onDestroyUnsubscribtionSmry.unsubscribe();
  }

  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }

  searchByMode(searchBy: string) {
    if (searchBy === "hireslipNumber") {
      this.searchByHireslipNo = true;
      this.searchByDestination = false;
      this.viewDate = false;
      this.viewSource = false;
      this.viewDestination = false;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      //this.selectSourceMain = true;
      this.selectSourceMain = false;
      this.selectSourceIndividual = false;
      this.getMainBookingSourceDetails();
    } else if (searchBy === "destination") {
      this.searchByHireslipNo = false;
      this.searchByDestination = true;
      this.viewDate = true;
      this.viewSource = true;
      this.viewDestination = true;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      this.selectSourceMain = true;
      this.selectSourceIndividual = false;
      this.getDestinationDetails();
      this.getMainBookingSourceDetails();
    } else if (searchBy === "truckNumber") {
      this.searchByHireslipNo = false;
      this.searchByDestination = false;
      this.viewDate = true;
      this.viewSource = false;
      this.viewDestination = false;
      this.searchByTruckNo = true;
      this.searchBySupplierName = false;
      this.selectSourceMain = false;
      this.selectSourceIndividual = false;
      this.getTruckMasterDetailsList();
    } else if (searchBy === "supplierName") {
      this.searchByHireslipNo = false;
      this.searchByDestination = false;
      this.viewDate = true;
      this.viewSource = false;
      this.viewDestination = true;
      this.searchByTruckNo = false;
      this.searchBySupplierName = true;
      this.selectSourceMain = false;
      this.selectSourceIndividual = false;
      this.getDestinationDetails();
      this.getSupplierDetailsList();
    } else {
      this.searchByHireslipNo = false;
      this.searchByDestination = false;
      this.viewDate = false;
      this.viewSource = false;
      this.viewDestination = false;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      this.selectSourceMain = false;
      this.selectSourceIndividual = false;
    }
  }

  selectSourceMode(selectSource: string) {
    if (selectSource === "mainSource") {
      this.selectSourceMain = true;
      this.selectSourceIndividual = false;
      this.getMainBookingSourceDetails();
    } else if (selectSource === "individualSource") {
      this.selectSourceMain = false;
      this.selectSourceIndividual = true;
      this.getSourceStationDetails();
    } else {
      this.selectSourceMain = false;
      this.selectSourceIndividual = false;
    }
  }

  clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelMainBookingSource = e.item;
    $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
  }
  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelDestination = e.item;
    $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
  }
  sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelSource = e.item;
    $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
  }
  clickListnerForTruckNumber(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelTruckNumber = e.item;
    window.setTimeout(function () {
      $("#" + this.pageId + "supplierName").focus();
    }, 100);
  }

  clickListnerForSupplier(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelSupplierName = e.item;
    $("#" + this.pageId + "supplierName").val(this.modelSupplierName.suppliername);
    console.log(this.modelSupplierName.suppliername);
  }

  ///For Destination

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
    this.lrDtoDestination.mode = "destinationOnly";
  }

  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getDestinationForLREntryService(this.lrDtoDestination)
      .subscribe((response) => {
        this.showSpinnerForAction = false;
        if (response) {
          //this.stationOptions = response;
          if (response.length > 0) {
            this.destinationTA = [];
            this.destinationOptions = response;

            this.lrDtoOptionDestinationAll.destination = "All";
            this.destinationTA.push(this.lrDtoOptionDestinationAll);
            this.destinationList = [];
            for (let i = 0; i < this.destinationOptions.length; i++) {
              this.destinationTA.push(this.destinationOptions[i]);
              this.destinationList.push(this.destinationOptions[i].destination);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the Destination Details",
          "info"
        );
      },
      () => console.log("done");
  }

  ///For Individual
  getMainBookingSourceDataDetails() {

    this.agentDtoSourceStation = new AgentDetailsDto();
    this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
  }
  getMainBookingSourceDetails() {
    this.getMainBookingSourceDataDetails();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getMainBookingStationsDetails(this.agentDtoSourceStation)
      .subscribe((response) => {
        //console.log(response);
        if (response.length > 0) {
          this.mainBookingSourceTA = [];
          this.agentDtoOptionSourceStation = [];
          this.agentDtoOptionSourceStation = response;
          this.agentDtoOptionSourceStationAll.mainBookStations = "All";
          this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
          this.sourceList = [];
          for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
            this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
            this.sourceList.push(
              this.agentDtoOptionSourceStation[i].mainBookStations
            );
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the Main Biiking Source Details",
          "info"
        );
      },
      () => console.log("done");
  }

  //For Main Source
  getSourceStationMethod() {
    this.lrDtoSourceStation = new LRDto();
    this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    //Need To Verify Mode And Change it
    this.lrDtoSourceStation.mode = "all";
  }
  getSourceStationDetails() {
    this.getSourceStationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getSubBookingStationDetailsService(this.lrDtoSourceStation)

      .subscribe((response) => {
        console.log("Source");
        console.log(response);
        console.log(this.lrDtoSourceStation);
        if (response.length > 0) {
          this.sourceSubStationNameTA = [];
          this.sourceStationOptions = [];
          this.sourceStationOptions = response;
          console.log(this.sourceStationOptions);
          this.lrDtoSourceStationAllOption.subStations = "All";
          this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
          for (let i = 0; i < this.sourceStationOptions.length; i++) {
            this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the Source Details",
          "info"
        );
      },
      () => console.log("done");
  }

  ///For Supplier Name

  getSupplierDetails() {
    this.supplierDetailsDto = new SupplierDetailsDto();
    this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
    //this.supplierDetailsDto.mode = "Working";
  }
  getSupplierDetailsList = () => {
    this.getSupplierDetails();
    this.masterReadService
      .getSuplierMaster(this.supplierDetailsDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          swal("Warning", "No Supplier Master records found!", "warning");
        } else {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          this.supplierOptions = response;

          for (let i = 0; i < this.supplierOptions.length; i++) {
            this.supplierNameTA.push(this.supplierOptions[i]);
            this.hashMapSupplier.set(
              this.supplierOptions[i].supplierName,
              this.supplierOptions[i]
            );
            this.hashMapSupplierIndex.set(
              this.supplierOptions[i].supplierName,
              i
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Supplier Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  //For Truck Number Drop Down

  getDriverDetailsForReadTruckNo() {
    this.truckDataDtoForTruckNo = new TruckDataDto();
    this.truckDataDtoForTruckNo.companyId = this.userDataDtoReturnSession.companyId;
    this.truckDataDtoForTruckNo.status = "Working";
  }

  getTruckMasterDetailsList = () => {
    this.getDriverDetailsForReadTruckNo();
    this.masterReadService
      .getTruck(this.truckDataDtoForTruckNo)
      .subscribe((response) => {
        if (response.length == 0) {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          swal("Warning", "No Truck Number records found!", "warning");
        } else {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          this.truckNumberOptions = response;

          for (let i = 0; i < this.truckNumberOptions.length; i++) {
            this.truckNumberTA.push(this.truckNumberOptions[i]);
            this.hashmapTruck.set(
              this.truckNumberOptions[i].truckNumber,
              this.truckNumberOptions[i]
            );
            this.hashmapTruckNumberIndex.set(
              this.truckNumberOptions[i].truckNumber,
              i + 1
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Truck Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };


  validateSearchBtn() {
    if (($("#" + this.pageId + "searchBy").val() == "hireslipNumber") && ($("#" + this.pageId + "hireslipNumberId").val() == null ||
      $("#" + this.pageId + "hireslipNumberId").val() == undefined || $("#" + this.pageId + "hireslipNumberId").val() == "")) {
      swal("Alert", "Hire Slip Number is Mandatory Field", "warning");
      return false;
    } else if (($("#" + this.pageId + "searchBy").val() == "supplierName") &&
      ($("#" + this.pageId + "departFrom").val() == null || $("#" + this.pageId + "departFrom").val() == undefined ||
        $("#" + this.pageId + "departFrom").val() == "" || $("#" + this.pageId + "departTo").val() == null ||
        $("#" + this.pageId + "departTo").val() == undefined || $("#" + this.pageId + "departTo").val() == "" ||
        $("#" + this.pageId + "supplierName").val() == null || $("#" + this.pageId + "supplierName").val() == undefined ||
        $("#" + this.pageId + "supplierName").val() == "")) {
      swal("Alert", "Supplier Name, From Date and To Date is Mandatory Field", "warning");
      return false;
    } else if (($("#" + this.pageId + "searchBy").val() == "truckNumber") &&
      ($("#" + this.pageId + "departFrom").val() == null || $("#" + this.pageId + "departFrom").val() == undefined ||
        $("#" + this.pageId + "departFrom").val() == "" || $("#" + this.pageId + "departTo").val() == null ||
        $("#" + this.pageId + "departTo").val() == undefined || $("#" + this.pageId + "departTo").val() == "" ||
        $("#" + this.pageId + "truckNumber").val() == null || $("#" + this.pageId + "truckNumber").val() == undefined ||
        $("#" + this.pageId + "truckNumber").val() == "")) {
      swal("Alert", "Truck Number, From Date and To Date is Mandatory", "warning");
      return false;
    } else if ($("#" + this.pageId + "searchBy").val() == "destination") {
      if (($("#" + this.pageId + "selectSource").val() == "mainSource") &&
        ($("#" + this.pageId + "departFrom").val() == null || $("#" + this.pageId + "departFrom").val() == undefined ||
          $("#" + this.pageId + "departFrom").val() == "" || $("#" + this.pageId + "departTo").val() == null ||
          $("#" + this.pageId + "departTo").val() == undefined || $("#" + this.pageId + "departTo").val() == "" ||
          $("#" + this.pageId + "mainBookingSourceId").val() == null || $("#" + this.pageId + "mainBookingSourceId").val() == undefined ||
          $("#" + this.pageId + "mainBookingSourceId").val() == "" || $("#" + this.pageId + "destination").val() == null ||
          $("#" + this.pageId + "destination").val() == undefined || $("#" + this.pageId + "destination").val() == "")) {
        swal("Alert", "Source, Destination, From Date and To Date is Mandatory", "warning");
        return false;
      } else if (($("#" + this.pageId + "selectSource").val() == "individualSource") &&
        ($("#" + this.pageId + "departFrom").val() == null || $("#" + this.pageId + "departFrom").val() == undefined ||
          $("#" + this.pageId + "departFrom").val() == "" || $("#" + this.pageId + "departTo").val() == null ||
          $("#" + this.pageId + "departTo").val() == undefined || $("#" + this.pageId + "departTo").val() == "" ||
          $("#" + this.pageId + "sourceId").val() == null || $("#" + this.pageId + "sourceId").val() == undefined ||
          $("#" + this.pageId + "sourceId").val() == "" || $("#" + this.pageId + "destination").val() == null ||
          $("#" + this.pageId + "destination").val() == undefined || $("#" + this.pageId + "destination").val() == "")) {
        swal("Alert", "Source, Destination, From Date and To Date is Mandatory", "warning");
        return false;
      } else {
        this.gridReconfigurePerformanceDetailsRpt();
      }
    } else {
      this.gridReconfigurePerformanceDetailsRpt();
    }

  }

  getValues() {
    this.hireslipDtoForGetUserValue = new HireSlipDto();
    if ($("#" + this.pageId + "searchBy").val() == "hireslipNumber") {
      this.hireslipDtoForGetUserValue.mode = "HireSlipNoWise";
    } else if ($("#" + this.pageId + "searchBy").val() == "destination") {
      this.hireslipDtoForGetUserValue.mode = "DestWise";
      if ($("#" + this.pageId + "selectSource").val() == "mainSource") {
        this.hireslipDtoForGetUserValue.searchMode = "DestWiseMainSrc";
      } else if ($("#" + this.pageId + "selectSource").val() == "individualSource") {
        this.hireslipDtoForGetUserValue.searchMode = "DestWiseIndSrc";
      }
    } else if ($("#" + this.pageId + "searchBy").val() == "truckNumber") {
      this.hireslipDtoForGetUserValue.mode = "TruckNoWise";
    } else if ($("#" + this.pageId + "searchBy").val() == "supplierName") {
      this.hireslipDtoForGetUserValue.mode = "SuppNameWise";
    }

    if (this.userDataDtoReturnSession.role == "Super Administrator") {
      this.hireslipDtoForGetUserValue.reportMode = "All";
    } else {
      this.hireslipDtoForGetUserValue.reportMode = "Specific";
      this.hireslipDtoForGetUserValue.officeType = this.userDataDtoReturnSession.mainStation;
    }

    this.validateHireslipNumber = $("#" + this.pageId + "hireslipNumberId").val();
    this.validateFromDate = $("#" + this.pageId + "departFrom").val();
    this.validateToDate = $("#" + this.pageId + "departTo").val();
    this.validateSource = $("#" + this.pageId + "sourceId").val();
    this.validateDestination = $("#" + this.pageId + "destination").val();
    this.validateMainSource = $("#" + this.pageId + "mainBookingSourceId").val();
    this.validateVehicleNo = $("#" + this.pageId + "truckNumber").val();
    this.validateSupplierName = $("#" + this.pageId + "supplierName").val();


    this.hireslipDtoForGetUserValue.hireslipnumber = this.validateHireslipNumber;
    this.hireslipDtoForGetUserValue.fromDate = this.validateFromDate;
    this.hireslipDtoForGetUserValue.toDate = this.validateToDate;
    this.hireslipDtoForGetUserValue.fromStation = this.validateSource;
    this.hireslipDtoForGetUserValue.toStation = this.validateDestination;
    this.hireslipDtoForGetUserValue.mainStation = this.validateMainSource;
    this.hireslipDtoForGetUserValue.vehicleNumber = this.validateVehicleNo;
    this.hireslipDtoForGetUserValue.suppliername = this.validateSupplierName;
    this.hireslipDtoForGetUserValue.fromDateInDate = this.validateFromDate;
    this.hireslipDtoForGetUserValue.toDateInDate = this.validateToDate;

    this.hireslipDtoForGetUserValue.companyid = this.userDataDtoReturnSession.companyId;

    return this.hireslipDtoForGetUserValue;
  }

  gridReconfigurePerformanceDetailsRpt() {
    this.hireslipDtoForGetUserValueReturn = new HireSlipDto();
    this.hireslipDtoForGetUserValueReturn = this.getValues();
    this.showSpinnerForAction = true;
    this.lrService.getTruckPerformanceReport(this.hireslipDtoForGetUserValueReturn)
      .subscribe((response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.truckPerfDataList = [];
        this.truckPerfTimeCalcDataList = [];
        $("#" + this.pageId + "truckPerformanceRptTableid").DataTable().destroy();
        $("#" + this.pageId + "truckPerformanceTimeCalcTableid").DataTable().destroy();
        if (response.length > 0) {
          this.truckPerfDataList = response;
          this.newAttributeTimeCalc = {};
          for (let i = 0; i < this.truckPerfDataList.length; i++) {
            if (i == 0) {
              this.fortnightDtoReturn = this.truckPerfDataList[i].fortNightStmtDto;
            }
          }
          console.log(this.fortnightDtoReturn);
          if (this.fortnightDtoReturn != null && this.fortnightDtoReturn != undefined &&
            this.fortnightDtoReturn.length > 0) {
            for (let i = 0; i < this.fortnightDtoReturn.length; i++) {
              this.newAttributeTimeCalc = {};
              this.newAttributeTimeCalc.fromDays = this.fortnightDtoReturn[i].fromDays;
              this.newAttributeTimeCalc.toDays = this.fortnightDtoReturn[i].toDays;
              this.newAttributeTimeCalc.commSlaps = this.fortnightDtoReturn[i].commSlaps;
              this.truckPerfTimeCalcDataList.push(this.newAttributeTimeCalc);
            }
          }
        } else {
          swal("No Data", "No Records found for this search", "warning");
        }
        this.dtTriggerTruckPerf.next();
        this.dtTriggerTruckPerfTimeCal.next();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Error While Getting Truck Performance Details", "warning");
      },
      () => console.log("done");
  }

  clearBtn() {
    this.searchByHireslipNo = true;
    this.searchByDestination = false;
    this.viewDate = false;
    this.viewSource = false;
    this.viewDestination = false;
    this.searchByTruckNo = false;
    this.searchBySupplierName = false;
    //this.selectSourceMain = true;
    this.selectSourceMain = false;
    this.selectSourceIndividual = false;

    $("#" + this.pageId + "searchBy").val('hireslipNumber');
    $("#" + this.pageId + "selectSource").val('mainSource');
    $("#" + this.pageId + "hireslipNumberId").val('');
    $("#" + this.pageId + "departFrom").val('');
    $("#" + this.pageId + "departTo").val('');
    $("#" + this.pageId + "sourceId").val('');
    $("#" + this.pageId + "destination").val('');
    $("#" + this.pageId + "mainBookingSourceId").val('');
    $("#" + this.pageId + "truckNumber").val('');
    $("#" + this.pageId + "supplierName").val('');

    this.validateHireslipNumber = null;
    this.validateFromDate = null;
    this.validateToDate = null;
    this.validateSource = null;
    this.validateDestination = null;
    this.validateMainSource = null;
    this.validateVehicleNo = null;
    this.validateSupplierName = null;

    this.modelMainBookingSource = null;
    this.modelSource = null;
    this.modelDestination = null;
    this.modelTruckNumber = null;
    this.modelSupplierName = null;

    this.truckPerfTimeCalcDataList = [];
    $("#" + this.pageId + "truckPerformanceTimeCalcTableid").DataTable().destroy();
    this.dtTriggerTruckPerfTimeCal.next();

    this.truckPerfDataList = [];
    $("#" + this.pageId + "truckPerformanceRptTableid").DataTable().destroy();
    this.dtTriggerTruckPerf.next();

  }


  customPrintTruckPerRpt() {
    if (this.truckPerfDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["Company Name", "Lrry No", "Gur WT", "Kan WT",
        "Destination", "Hireslip No", "Supplier Name", "Depart Date", "Depart Time", "Arr Date",
        "Arr Time", "Hours", "Enum-H", "Tracking Id", "Carrier", "Tracking Status", "Tracking Rmks"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [8, 5, 5, 5, 8, 8, 8, 5, 5, 5, 5, 5, 4, 5, 4, 5, 5];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintDataList = [];

      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.footerSummaryGurWt = this.footerKanWt = this.footerEnumH = 0;
      for (let i = 0; i < this.truckPerfDataList.length; i++) {

        this.cashMemoDtoForCustomPrintListColumnValues =
          [this.truckPerfDataList[i].vehiCompName,
          this.truckPerfDataList[i].vehicleNumber,
          this.truckPerfDataList[i].gurWeight,
          this.truckPerfDataList[i].kantaWeight,
          this.truckPerfDataList[i].toStation,
          this.truckPerfDataList[i].hireslipnumber,
          this.truckPerfDataList[i].brokerName,
          this.truckPerfDataList[i].departuredateStr,
          this.truckPerfDataList[i].departureTime,
          this.truckPerfDataList[i].arrivaldateStr,
          this.truckPerfDataList[i].arrivalTime,
          this.truckPerfDataList[i].hours,
          this.truckPerfDataList[i].driverrewardingHAmt,
          this.truckPerfDataList[i].trackingFTTripId,
          this.truckPerfDataList[i].trackingFTCarrier,
          this.truckPerfDataList[i].trackingFTConsentStatus,
          this.truckPerfDataList[i].ftRemarks
          ];
        //For Summary
        this.footerSummaryGurWt = +this.footerSummaryGurWt + +this.truckPerfDataList[i].gurWeight;
        this.footerKanWt = +this.footerKanWt + +this.truckPerfDataList[i].kantaWeight;
        this.footerEnumH = +this.footerEnumH + +this.truckPerfDataList[i].driverrewardingHAmt;

        this.cashMemoDtoForCustomPrintData = new CashMemoDto();


        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        // this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.truckOwnerDeclarationRptDataList.length, "", this.gurWtSummary, this.kantaWtSummary, "","","","","","","","",this.enamHSummary,"","","",""];
      }
      this.cashMemoDtoForCustomPrintDataSummaryList =
        ["Total : " + this.truckPerfDataList.length,
          "", this.footerSummaryGurWt,
        this.footerKanWt, "", "", "", "", "", "", "", "", this.footerEnumH, "", "", "", ""
        ];

      //heading logics For Date
      //     this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
      //   this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

      }


      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "Truck Performance Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

}
