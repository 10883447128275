import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from 'sweetalert';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-update-transporterId',
    templateUrl: './update-transporterId.component.html',
    styleUrls: ['./update-transporterId.component.css']
})

export class UpdateTransporterIdComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "ewbSearch";
    gstSearch = true;
    isLoggedIn = true;
    requestDto: RequestDto = new RequestDto();
    enteredEwayBillNo: any;
    showSpinnerForAction = false;
    pdfUrl: SafeResourceUrl | null = null;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    enteredTransporterId: any;
    enteredTransporterName: any;
    viewTransportId = false;
    viewTransportName = false;
    transporterName: any;
    constructor(
        private masterReadService: MasterReadService, private router: Router, private sanitizer: DomSanitizer,
        private ewaybillService: EwaybillService, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    validateForEWayBillNo() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined || this.enteredEwayBillNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter E-Way Bill Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.getEwayBillNoDetails();
        }
    }

    getEwayBillNoDetails() {
        this.requestDto = new RequestDto();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log(this.enteredEwayBillNo);
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    this.pdfUrl = '';
                    this.openPdf(this.enteredEwayBillNo, response.token1, 4);
                    this.viewTransportId = true;
                } else {
                    swal("Failed", "Failed to generate the token for View Eway Bill Details, please contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to generate the token for View Eway bill Details, please contact Admin!", "error");
            }, () => console.log('done');
    }

    openPdf(ewayBillNo: string, token: string, type: number) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": type };

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, token).subscribe({
            next: (data) => {
                if (!data || data.size === 0) {
                    swal("Warning", "E-Way Bill not found. Please check the number and try again.", "Not Found");
                    return;
                } else {
                    const blob = new Blob([data], { type: 'application/pdf' });
                    const objectUrl = URL.createObjectURL(blob);

                    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            error: (err) => {
                this.showSpinnerForAction = false;
                console.error('Error opening PDF:', err);
                swal("Error", "Failed to open the PDF. Please try again.", "error");
            }
        });
    }

    clearField() {
        $("#" + this.pageId + "ewayBillNo").val('');
        $("#" + this.pageId + "transporterId").val('');
        $("#" + this.pageId + "transporterName").val('');
        this.pdfUrl = '';
        this.viewTransportId = false;
    }
    validateTransPorterDetails(e) {
        if (e.keyCode == 13) {
            this.enteredTransporterId = $("#" + this.pageId + "transporterId").val();
            if (this.enteredTransporterId != null) {
                this.getTransporterDetails();
            }

        }
    }


    getTransporterDetails() {
        this.requestDto = new RequestDto();
        this.enteredTransporterId = $("#" + this.pageId + "transporterId").val();
        console.log(this.enteredTransporterId);
        this.requestDto.param1 = this.enteredTransporterId;
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();

        this.ewaybillService.getEwayBillTransporterDetails(this.requestDto).subscribe(
            (response) => {
                if (response != null && response.tradeName != null) {
                    console.log("response>>>", response);
                    this.viewTransportName = true;
                    this.transporterName = response.tradeName;
                } else {
                    swal("Failed", "No Transporter details found", "error");
                    this.viewTransportName = false;
                    this.transporterName = '';
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to generate the token for View Eway bill Details, please contact Admin!", "error");
            }
        );
    }


    validateUpdateTransporteerDetails() {

        this.enteredTransporterName = $("#" + this.pageId + "transporterName").val();
        this.enteredTransporterId = $("#" + this.pageId + "transporterId").val();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined ||
            this.enteredEwayBillNo == '' || this.enteredTransporterId == null ||
            this.enteredTransporterId == undefined || this.enteredTransporterId == '' ||
            this.enteredTransporterName == null || this.enteredTransporterName == undefined ||
            this.enteredTransporterName == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please fill all fields to proceed",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            swal({
                title: "Confirm Update",

                text: "Sure U Want to update transporter details for this EWB?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.updateTransporterDetails();
                }
            });

        }
    }

    updateTransporterDetails() {
        this.requestDto = new RequestDto();
        this.enteredTransporterName = $("#" + this.pageId + "transporterName").val();
        this.enteredTransporterId = $("#" + this.pageId + "transporterId").val();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log(this.enteredTransporterName);
        this.requestDto.param1 = this.enteredEwayBillNo;
        this.requestDto.param2 = this.enteredTransporterId;
        this.requestDto.param4 = this.enteredTransporterName;
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.updateEwayBillTransporterDetails(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Success", "Transporter details updated successfully", "success");
                    //this.clearField();
                } else {
                    swal("Failed", response.message, "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to transporter Details, please contact Admin!", "error");
            }, () => console.log('done');
    }

}
