<div class="row" *ngIf="isLoggedIn" id="{{pageId}}gstSearch">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row ">
                                        <h6 class="col-sm-12 col-md-12" class="card-title" style="padding-left: 15px;">
                                            GST Details</h6>&nbsp;
                                        <hr>
                                        <div class="col-md-12">
                                            <div class="row ">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>GST Number</label>
                                                            <input type="text" id="{{pageId}}searchGstNo" class="form-control" placeholder="Search by GST Number" aria-describedby="basic-addon11" autocomplete="off">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <button type="submit" class="btn btn-success m-r-10" (click)="validateForGstNo();" id="{{pageId}}searchBtn">Search</button>
                                                    <button type="submit" class="btn btn-dark" (click)="clearFields();" id="{{pageId}}clearBtn">Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>&nbsp;
                                    <!-- <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10"
                                            (click)="validateForGstNo();" id="{{pageId}}searchBtn">Search</button>
                                        <button type="submit" class="btn btn-dark" (click)="clearFields();"
                                            id="{{pageId}}clearBtn">Clear</button>
                                    </div> -->

                                    <!-- <div class="row "> -->
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">GST Number</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ gstNumber }}</h6>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Trade Name</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ tradeName }}</h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>&nbsp;

                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Legal Name</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ legalName }}</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">GST Status</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 [ngClass]="status" style="font-size: 16px;">{{ status }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>&nbsp;
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Address</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ address1 }}</h6>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ address2 }}</h6>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ pincode }}</h6>
                                                    <!-- <h6 style="font-size: 16px; font-weight: normal;" *ngIf="showStateCode">State Code :
                                                        {{ stateCode
                                                        }}</h6> -->
                                                </div>
                                            </div>
                                            <div class="col-md-6" *ngIf="showStateCode">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">State
                                                        Code</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">
                                                        {{ stateCode }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-md-6 vl">
                                    <div class="row ">
                                        <h6 class="col-sm-12 col-md-12" class="card-title" style="padding-left: 15px;">
                                            GST Details</h6>&nbsp;
                                        <hr>
                                        <div class="col-md-12">
                                            <div class="row ">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>GST Number</label>
                                                            <input type="text" id="{{pageId}}searchGstNoForConsignee" class="form-control" placeholder="Search by GST Number" aria-describedby="basic-addon11" autocomplete="off">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <button type="submit" class="btn btn-success m-r-10" (click)="validateForConsigneeGstNo();" id="{{pageId}}searchBtn">Search</button>
                                                    <button type="submit" class="btn btn-dark" (click)="clearFieldsForConsignee();" id="{{pageId}}clearBtn">Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <!-- <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10"
                                            (click)="validateForGstNo();" id="{{pageId}}searchBtn">Search</button>
                                        <button type="submit" class="btn btn-dark" (click)="clearFields();"
                                            id="{{pageId}}clearBtn">Clear</button>
                                    </div> -->

                                    <!-- <div class="row "> -->
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">GST Number</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ gstNumberForConsignee }}</h6>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Trade Name</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ tradeNameForConsignee }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>&nbsp;
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Legal Name</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ legalNameForConsignee }}</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">GST Status</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 [ngClass]="statusForConsignee" style="font-size: 16px;">{{ statusForConsignee }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>&nbsp;
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">Address</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ address1ForConsignee }}</h6>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ address2ForConsignee }}</h6>
                                                    <h6 style="font-size: 16px; font-weight: normal;">{{ pincodeForConsignee }}</h6>

                                                </div>
                                            </div>
                                            <div class="col-md-6" *ngIf="showStateCodeForConsignee">
                                                <div class="form-group">
                                                    <label style="font-size: 18px; font-weight: bold;">State
                                                        Code</label>
                                                    <div class="input-group">
                                                    </div>
                                                    <h6 style="font-size: 16px; font-weight: normal;">
                                                        {{ stateCodeForConsignee }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>