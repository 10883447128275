<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showMemoLessRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Memo Less Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" autocomplete="off" [(ngModel)]="fromDatesModal">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}partyName">
                                                            <label>Consignee Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="consigneeDropDownListner($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                                [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMemoLessReptBtn()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMemoLessBtn()">Clear</button>
                        </div>
                        <div class="box-body">
                            <hr style="background: none; padding-top: 5px">
                            <h6 class="imb_patch card-title text-white">Source Wise LessDetails
                            </h6>
                            <div [hidden]=" detailTable">
                                <table datatable id="{{pageId}}memoLessSourceWiseDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionMemoLessSrcWise" [dtTrigger]="dtTriggerMemoLessSrcWise">
                                    <thead>
                                        <tr>
                                            <th>Main Station</th>
                                            <th>Less Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let memoLessSrcWiseData of memoLessSrcWiseDataList ">
                                            <td>{{ memoLessSrcWiseData.mainStation }}</td>
                                            <td>{{ memoLessSrcWiseData.lessAmt }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <div class="box-body">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintMemoLess()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>

                            <table datatable id="{{pageId}}memoLessDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMemoLessReport" [dtTrigger]="dtTriggerMemoLessReport">
                                <thead>
                                    <tr>
                                        <th>Memo Number</th>
                                        <th>LR No.</th>
                                        <th>Party Name</th>
                                        <th>Memo Date</th>
                                        <th>Memo Amt</th>
                                        <th>Computer Less</th>
                                        <th>Memo Less</th>
                                        <th>Less Type</th>
                                        <th>Articles</th>
                                        <th>Actual Wgt</th>
                                        <th>Charge Wgt</th>
                                        <th>Source</th>
                                        <th>Collection Man</th>
                                        <th>Invoice Date</th>
                                        <th>Topay</th>
                                        <th>Fixed By</th>
                                        <th>Commodity Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let memoLessReportData of memoLessReportDataList ">
                                        <td>{{ memoLessReportData.memoNumber }}</td>
                                        <td>{{ memoLessReportData.lrNumber }}</td>
                                        <td>{{ memoLessReportData.consigneeName }}</td>
                                        <td>{{ memoLessReportData.memoDateStr }}</td>
                                        <td>{{ memoLessReportData.amount }}</td>
                                        <td>{{ memoLessReportData.discount }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="memoLessDescriptionPopUpBtnLink(memoLessDescrPopUpTemplate,memoLessReportData)">
                                            {{ memoLessReportData.less }}</td>
                                        <!--<td>
											<a style="color:red;cursor: pointer;"
												(click)="memoLessDescriptionPopUpBtnLink(memoLessDescrPopUpTemplate,memoLessReportData)">
												<u>{{ memoLessReportData.less }}</u></a>
										</td>-->
                                        <!--<td>{{ memoLessReportData.less }}</td>-->
                                        <td>{{ memoLessReportData.lessType }}</td>
                                        <td>{{ memoLessReportData.article }}</td>
                                        <td>{{ memoLessReportData.actualWeight }}</td>
                                        <td>{{ memoLessReportData.chargedWeight }}</td>
                                        <td>{{ memoLessReportData.source }}</td>
                                        <td>{{ memoLessReportData.collectionMan }}</td>
                                        <td>{{ memoLessReportData.date }}</td>
                                        <td>{{ memoLessReportData.topay }}</td>
                                        <td>{{ memoLessReportData.fixedBy }}</td>
                                        <td>{{ memoLessReportData.commodityName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--POP UP START HERE-->
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #memoLessDescrPopUpTemplate let-ok="close" let-d="dismiss">
                    <!--<div class="modal-header">
						<h5>Memo Less Description :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>-->
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewMemoLessDescriptionPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupMemoLessRpt">
                                                <app-memo-less-description-report></app-memo-less-description-report>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4"></div>
                                        <div class="col-sm-12 col-md-2">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeMemoLessDescriptionPopUp()">Cancel</button>
                                        </div>
                                        <div class="col-sm-12 col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>